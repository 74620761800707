<script lang="ts">
  import {createEventDispatcher, onMount} from "svelte";
  import { slide } from "svelte/transition";
  import {Badge, Button, Checkbox, Input, Label} from "flowbite-svelte";
  import {translate} from "lib";
  import { ButtonComponent } from "ui";

  const dispatcher = createEventDispatcher();

  interface Type {
    name: string;
    checked: boolean;
  }

  type Types = Array<Type>;

  const form = {
    newType: {value: "", error: ""}
  };

  let types: Array<string> = [];
  let selectedTypes: Array<string> = [];
  let selectableTypes: Types = [];
  let isEditVisible = false;

  $: isInsertDisabled = form.newType.value ? false : true;

  const getSelectedTypes = (): Array<string> => selectableTypes
    .filter(({checked}) => checked)
    .map(({name}) => name);

  const isTypeSelected = (): boolean => selectableTypes
    .some(({checked}) => checked);

  const onInsertType = (): void => {
    selectableTypes = [...selectableTypes, {
      name: form.newType.value,
      checked: true
    }];

    dispatcher("selectedTypes", getSelectedTypes());

    form.newType.value = "";
  };

  const onUpdate = (): void => {
    dispatcher("selectedTypes", getSelectedTypes());
  };

  const onToggleTypes = (): void => {
    isEditVisible = !isEditVisible;
  };

  onMount((): void => {
    selectableTypes = types.map((name) => ({name, checked: false}));

    selectableTypes.forEach((type): void => {
      if (selectedTypes.includes(type.name)) {
        type.checked = true;
      }
    });
  });

  export {selectedTypes, types};
</script>

{#if isEditVisible}
  <div class="p-4 flex flex-col gap-4 border rounded-md" in:slide out:slide>
    <div class="flex flex-col gap-2">
      {#each selectableTypes as {name, checked}}
        <Checkbox bind:checked on:change="{onUpdate}">{name}</Checkbox>
      {/each}
    </div>

    <div class="flex items-end gap-4">
      <div class="grow">
        <Label class="text-xs" for="type">{translate("NEW_TYPE")}:</Label>
        <Input
          id="type"
          placeholder="{translate("NEW_TYPE")}"
          bind:value="{form.newType.value}"/>
      </div>
      <ButtonComponent on:click="{onInsertType}" disabled="{isInsertDisabled}">
        {translate("ADD")}
      </ButtonComponent>
    </div>

    <div>
      <ButtonComponent isOutline on:click="{onToggleTypes}">
        {translate("CLOSE")}
      </ButtonComponent>
    </div>
  </div>
{:else}
  <div>
    {#if isTypeSelected()}
      <div class="flex flex-wrap gap-2 mb-2">
        {#each selectableTypes as {name, checked}}
          {#if checked}
            <Badge color="green" large rounded>{name}</Badge>
          {/if}
        {/each}
      </div>
    {/if}

    <ButtonComponent on:click="{onToggleTypes}">
      {isTypeSelected() ? translate("EDIT_TYPE") : translate("SET_TYPE")}
    </ButtonComponent>
  </div>
{/if}
