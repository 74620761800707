<script lang="ts">
  import { onMount } from "svelte";
  import {
    Badge,
    Button,
    Checkbox,
    Heading,
    Input,
    Label,
    P,
    Spinner,
  } from "flowbite-svelte";
  import { api, postFormDataWithJwt, postWithJwt, translate } from "lib";
  import { clientMealPlans, dialogData, showAlert, user } from "stores";
  import type { MealPlanTypes } from "./types";
  import { trainerMealPlans } from "../../../stores/trainerStores";
  import { ButtonComponent } from "ui";

  let data: any;

  let imageElem: HTMLImageElement;
  let imageInputElem: HTMLInputElement;

  let name: string;
  let image: File;
  let description: string;
  let mealPlanTypes: MealPlanTypes = [];
  let mealPlanType: string;
  let duration; // duration se ne passuje za edit, mora fix
  // generalno cela ova komponenta je malo zabagovana, mora se refaktorise
  let imageUrl: string;
  let isImageSelected = false;
  let isMealPlanTypesVisible = false;
  let isLoading = false;

  $: isMealPlanTypeDisabled = mealPlanType ? false : true;

  const onSetImage = (): void => {
    if (imageInputElem.files && imageInputElem.files[0]) {
      var reader = new FileReader();

      reader.onload = (event) => {
        imageElem.src = event.target.result as string;
      };

      reader.readAsDataURL(imageInputElem.files[0]);

      isImageSelected = true;
    }
  };

  const onInsertNewMealPlanType = (): void => {
    mealPlanTypes = [
      ...mealPlanTypes,
      {
        name: mealPlanType,
        checked: true,
      },
    ];

    mealPlanType = "";
  };

  const onToggleMealPlanTypes = (): void => {
    isMealPlanTypesVisible = !isMealPlanTypesVisible;
  };

  const onSubmit = async (): Promise<void> => {
    try {
      isLoading = true;
      const formData = new FormData();
      let response;

      if (data.client) {
        formData.append("clientId", data.client.id);
        formData.append(
          "mealPlan",
          JSON.stringify({
            name,
            foodType: mealPlanTypes
              .filter((planType) => planType.checked)
              .map((planType) => planType.name),
            description,
            duration,
            expireAt: null, // ovde nzm sta treba ide
          })
        );

        response = await postFormDataWithJwt(`${api}/meal_plan`, formData);

        $clientMealPlans = [response, ...$clientMealPlans];
      } else {
        if (data.mealPlan) {
          // edit
          formData.append(
            "mealPlan",
            JSON.stringify({
              name,
              foodType: mealPlanTypes
                .filter((planType) => planType.checked)
                .map((planType) => planType.name),
              description,
              duration,
              expireAt: null, // ovde nzm sta treba ide
            })
          );
          formData.append("ownerType", "USER");

          response = await postFormDataWithJwt(
            `${api}/meal_plan/${data.mealPlan.id}`,
            formData,
            "PUT"
          );

          const mealPlan = $trainerMealPlans.find(
            ({ id }) => id === data.mealPlan.id
          );
          $trainerMealPlans.splice(
            $trainerMealPlans.indexOf(mealPlan),
            1,
            response
          );
          $trainerMealPlans = $trainerMealPlans;

          const clientMealPlan = $clientMealPlans.find(
            ({ id }) => id === data.mealPlan.id
          );
          if (clientMealPlan) {
            $clientMealPlans.splice(
              $clientMealPlans.indexOf(clientMealPlan),
              1,
              response
            );
            $clientMealPlans = $clientMealPlans;
          }
        } else {
          // create
          formData.append(
            "mealPlan",
            JSON.stringify({
              name,
              foodType: mealPlanTypes
                .filter((planType) => planType.checked)
                .map((planType) => planType.name),
              description,
              duration,
              expireAt: null, // ovde nzm sta treba ide
            })
          );
          formData.append("ownerType", "USER");

          response = await postFormDataWithJwt(
            `${api}/meal_plan`,
            formData,
            "POST"
          );

          $trainerMealPlans = [response, ...$trainerMealPlans];
        }
      }

      $dialogData.data = {};
      $dialogData.type = "";
      $showAlert.color = "black";
      $showAlert.message = `Created a new meal plan.`;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating a meal plan.`;
    }
  };

  onMount((): void => {
    if (data.mealPlan) {
      name = data.mealPlan.name;
      description = data.mealPlan.description;
      imageUrl = data.mealPlan.thumbnailUrl;
    }
    // if (data.name) {
    //   name = data.name;
    // }

    // if (data.description) {
    //   description = data.description;
    // }

    // if (data.imageUrl) {
    //   imageUrl = data.imageUrl;
    // }

    // if (data.duration) { duration = data.duration; }
  });

  export { data };
</script>

<div class="py-4 flex flex-col gap-4 box-border">
  <Heading align="center" customSize="text-lg font-normal" tag="h3">
    {data.mealPlan ? translate("EDIT") : translate("CREATE")}
    {translate("MEAL_PLAN").toLowerCase()}
  </Heading>

  <div class="p-4 grow overflow-y-scroll flex flex-col gap-4">
    <div>
      <Label for="name" class="mb-2 ml-2">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder={translate("NAME")}
        required
        bind:value={name}
      />
    </div>

    <div class="flex flex-col items-center justify-center">
      <Label>
        <img
          class="cursor-pointer"
          src={imageUrl ? imageUrl : "logo.png"}
          alt="Meal plan"
          bind:this={imageElem}
        />

        <input
          class="hidden"
          type="file"
          accept=".png"
          bind:value={image}
          bind:this={imageInputElem}
          on:change={onSetImage}
        />
      </Label>

      <P class="leading-4" align="center" color="text-gray-400" size="xs">
        {#if !data.executeFunction && !isImageSelected}
          {translate("DEFAULT_IMAGE_LOADED_AUTOMATICALLY")}<br />
        {/if}
        {translate("CLICK_ON_IMAGE_TO_CHANGE")}
      </P>
    </div>

    <div>
      <P class="mb-4">{translate("MEAL_PLAN_TYPE")}</P>

      {#if isMealPlanTypesVisible}
        <div class="flex flex-col gap-4">
          {#each mealPlanTypes as { name, checked }}
            <Checkbox bind:checked>{name}</Checkbox>
          {/each}
          <div>
            <Label for="mealPlanType" class="mb-2 ml-2">
              {translate("NEW_TYPE")}:
            </Label>
            <Input
              id="mealPlanType"
              placeholder={translate("NEW_TYPE")}
              bind:value={mealPlanType}
            />
          </div>
          <div class="flex gap-4">
            <ButtonComponent
              on:click={onInsertNewMealPlanType}
              disabled={isMealPlanTypeDisabled}
            >
              {translate("ADD")}
            </ButtonComponent>
            <ButtonComponent on:click={onToggleMealPlanTypes}>
              {translate("CLOSE")}
            </ButtonComponent>
          </div>
        </div>
      {:else}
        {#if mealPlanTypes.length}
          <div class="flex gap-4 mb-4">
            {#each mealPlanTypes as { name, checked }}
              {#if checked}
                <Badge large color="green">{name}</Badge>
              {/if}
            {/each}
          </div>
        {/if}
        <ButtonComponent on:click={onToggleMealPlanTypes}>
          {mealPlanTypes.length
            ? translate("EDIT_TYPE")
            : translate("SET_TYPE")}
        </ButtonComponent>
      {/if}
    </div>

    <div>
      <Label for="description" class="mb-2 ml-2">
        {translate("DESCRIPTION")}:
      </Label>
      <Input
        id="description"
        placeholder={translate("DESCRIPTION")}
        bind:value={description}
      />
    </div>

    <div>
      <Label for="duration" class="mb-2 ml-2">
        {translate("DURATION_IN_DAYS")}:
      </Label>
      <Input
        id="duration"
        placeholder={translate("DURATION_IN_DAYS")}
        bind:value={duration}
      />
    </div>

    <P class="leading-4 text-justify" color="text-gray-400" size="xs">
      <strong class="font-semibold text-black dark:text-slate-100">
        *{translate("NOTE")}:
      </strong>
      {translate("ADD_CREATE_MEAL_PLANS_NOTE")}
    </P>
  </div>

  <div class="h-10 flex justify-center">
    {#if isLoading}
      <Spinner size="10" color="green" />
    {:else}
      <ButtonComponent on:click={onSubmit} type="submit"
        >{data.mealPlan ? translate("EDIT") : translate("CREATE")}</ButtonComponent
      >
    {/if}
  </div>
</div>
