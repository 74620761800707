<script lang="ts">
  import {createEventDispatcher, onMount} from "svelte";
  import {Badge, Button, Checkbox, Input, Label, List, Li, } from "flowbite-svelte";
  import {api, getWithJwt, translate} from "lib";
  import {foodTypesStore} from "stores";
  import type {MealPlanTypes} from "../Dialogs/DietDialogs/types";
  import { ButtonComponent } from "ui";

  const dispatcher = createEventDispatcher();

  let selected: Array<string> = [];
  let ingredients: MealPlanTypes = [];
  let ingredient: string;
  let isEditIngredientsVisible = false;

  $: isInsertDisabled = ingredient ? false : true;

  const onInsertIngredient = (): void => {
    ingredients = [...ingredients, {
      name: ingredient,
      checked: true
    }];

    dispatcher("mealPlanTypes", ingredients.filter(({checked}) => checked).map((i) => i.name));

    ingredient = "";
  };

  const onUpdate = (): void => {
    dispatcher("mealPlanTypes", ingredients.filter(({checked}) => checked).map((i) => i.name));
  };

  const onToggleMealPlanTypes = (): void => {
    isEditIngredientsVisible = !isEditIngredientsVisible;
  };

  onMount(async (): Promise<void> => {
    if (!$foodTypesStore.length) {
      const response: Array<string> = await getWithJwt(`${api}/ingredient/food_types`);

      if (response) {
        $foodTypesStore = response;
      }
    }

    ingredients = $foodTypesStore.map((foodType) => ({
      name: foodType,
      checked: false
    }));

    ingredients.forEach((mealPlanType) => {
      if (selected.includes(mealPlanType.name)) {
        mealPlanType.checked = true;
      }
    });
  });

  export {selected};
</script>

{#if isEditIngredientsVisible}
  <div class="p-2 flex-col gap-2 border">
    <div class="flex-col gap-2">
      {#each ingredients as {name, checked}}
        <Checkbox bind:checked on:change={onUpdate}>{name}</Checkbox>
      {/each}
    </div>
    <div>
      <Label class="text-xs" for="mealPlanType">{translate("NEW_TYPE")}:</Label>
      <Input
        id="mealPlanType"
        placeholder="{translate("NEW_TYPE")}"
        bind:value="{ingredient}"/>
    </div>
    <div class="flex-row gap-2">
      <ButtonComponent on:click={onInsertIngredient} disabled={isInsertDisabled}>
        {translate("ADD")}
      </ButtonComponent>
      <ButtonComponent isOutline on:click={onToggleMealPlanTypes}>
        {translate("CLOSE")}
      </ButtonComponent>
    </div>
  </div>
{:else}
  {#if ingredients.some((ingredient) => ingredient.checked)}
    <div class="flex flex-wrap gap-2 mb-4">
      {#each ingredients as {name, checked}}
        {#if checked}
          <Badge large rounded color="green">{name}</Badge>
        {/if}
      {/each}
    </div>
  {/if}
  <ButtonComponent on:click={onToggleMealPlanTypes}>
    {ingredients.some((ing) => ing.checked) ? translate("EDIT_TYPE") : translate("SET_TYPE")}
  </ButtonComponent>
{/if}
