<script lang="ts">
  import { onMount } from "svelte";
  import {
    Button,
    // ButtonGroup,
    Checkbox,
    Heading,
    Label,
    Search,
    Spinner,
  } from "flowbite-svelte";
  import { api, postWithJwt, translate, getWithJwt } from "lib";
  import {
    clientMealPlans,
    dialogData,
    showAlert,
    trainerClientGroups,
    trainerMealPlans,
    trainerMealPlansCount,
    user,
  } from "stores";
  import {ButtonComponent, ButtonGroup} from "ui";
  import LoadMoreButton from "../../../components/LoadMoreButton.svelte";

  let data: any;
  let timeout: NodeJS.Timeout;
  let page = 0;
  let search: string;
  let templateType: "MY" | "LIBRARY" | "GROUPS" = "MY";
  let isLoading = false;
  let scrollElem: HTMLDivElement;
  let selectedIds: Array<number> = [];
  let disabled = true;

  const sortMeals = (): void => {
    $trainerMealPlans.forEach((plan): void => {
      plan.meals = plan.meals.sort(
        (a: any, b: any): number => a.position - b.position
      );
    });
  };

  const createUrl = (): string => {
    let skip = `&skip=${page * 15}`;
    let name = "";
    let template = "";

    if (search) {
      name = `&name=${search}`;
    }

    if (templateType === "MY") {
      template = "&default=1&template=0&group=0";
    } else if (templateType === "LIBRARY") {
      template = "&default=0&template=1&group=0";
    } else {
      template = "&default=0&template=0&group=1";
    }

    return `${api}/meal_plan?take=15${skip}${name}${template}`;
  };

  const fetch = async (): Promise<void> => {
    if ($user) {
      page = 0;

      try {
        const response = await getWithJwt(createUrl());
        $trainerMealPlans = response.data;
        $trainerMealPlansCount = response.count;

        sortMeals();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSearch = async (): Promise<void> => {
    clearTimeout(timeout);
    timeout = setTimeout(async (): Promise<void> => {
      fetch();
    }, 1000);
  };

  const onMyMealPlans = async (): Promise<void> => {
    templateType = "MY";
    await fetch();
  };

  const onLibrary = async (): Promise<void> => {
    templateType = "LIBRARY";
    await fetch();
  };

  const onGroups = async (): Promise<void> => {
    templateType = "GROUPS";
    await fetch();
  };

  const onChange = (id: number): void => {
    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    } else {
      selectedIds.push(id);
    }

    disabled = selectedIds.length === 0;
  };

  const onLoadMore = async (): Promise<void> => {
    if ($user) {
      page += 1;

      try {
        const response = await getWithJwt(createUrl());
        $trainerMealPlans = [...$trainerMealPlans, ...response.data];
        sortMeals();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const importToGroup = async (): Promise<void> => {
    try {
      const response = await postWithJwt(
        `${api}/client_group/meal_plan/assign_many`,
        {
          clientGroupId: data.group.id,
          programIds: selectedIds,
        }
      );

      const group = $trainerClientGroups.find(
        (group): boolean => group.id === data.group.id
      );

      group.clientGroupMealPlans = [...group.clientGroupMealPlans, ...response];

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_MEAL_PLANS");

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_ASSIGNING_MEAL_PLAN");
      isLoading = false;
    }
  };

  const assignToClient = async (): Promise<void> => {
    try {
      const response = await postWithJwt(`${api}/meal_plan/assign_many`, {
        clientIds: [data.client.id],
        mealPlanIds: selectedIds,
        assignToAll: false,
      });

      $clientMealPlans = [...response, ...$clientMealPlans];

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESSFULLY_ASSIGNED_MEAL_PLANS");

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_ASSIGNING_MEAL_PLAN");
      isLoading = false;
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    if (data.group) {
      await importToGroup();
    } else if (data.client) {
      await assignToClient();
    }
  };

  onMount(async (): Promise<void> => {
    await fetch();
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h3">
    {#if data.client}
      {translate("ASSIGN_MEAL_PLAN_TO_CLIENT")}: {data.client.name}
    {:else if data.group}
      {translate("ASSIGN_MEAL_PLAN_TO_GROUP")}: {data.group.name}
    {:else}
      {translate("ASSIGN_MEAL_PLAN")}
    {/if}
  </Heading>

  <Search
    size="md"
    placeholder={translate("SEARCH_MEAL_PLANS")}
    bind:value={search}
    on:input={onSearch}
  />

  <div class="flex justify-center">
    <ButtonGroup buttons={[
      {title: translate("MY_MEAL_PLANS"), onClick: onMyMealPlans},
      {title: translate("LIBRARY"),       onClick: onLibrary},
      {title: translate("GROUPS"),        onClick: onGroups}
    ]}/>
  </div>

  <div
    class="h-80 pr-4 flex flex-col gap-2 overflow-y-scroll"
    bind:this={scrollElem}
  >
    {#each $trainerMealPlans as trainerMealPlan}
      <Label class="p-2 flex items-center border rounded-md">
        <div class="h-16 w-16 mr-4 overflow-hidden flex items-center">
          <img
            class="rounded-full"
            src={trainerMealPlan.thumbnailUrl}
            alt="Meal plan avatar"
          />
        </div>
        <div class="grow">{trainerMealPlan.name}</div>
        <Checkbox
          checked={selectedIds.includes(trainerMealPlan.id)}
          on:change={() => onChange(trainerMealPlan.id)}
        />
      </Label>
    {/each}

    {#if $trainerMealPlans.length < $trainerMealPlansCount}
      <LoadMoreButton fetchMoreData={onLoadMore} />
    {/if}
  </div>

  <div class="h-10 flex justify-center">
    {#if isLoading}
      <Spinner size="10" color="green" />
    {:else}
      <ButtonComponent {disabled} on:click={onSubmit}>{translate("ASSIGN")}</ButtonComponent>
    {/if}
  </div>
</div>
