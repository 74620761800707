import { get } from "svelte/store";
import {
  chatSessions,
  notSeenSessionIds,
  unreadMessages,
} from "../stores/chatSessionsStore";
import { chatMessages } from "../stores/chatMessagesStore";
import {
  notifications,
  unreadNotifications,
} from "../stores/notificationStore";
import { user } from "../stores/userStore";
import { isClient } from "./roles";
import { loadedWorkouts } from "../stores/loadedWorkoutsStore";
import { trainerChatWith } from "../stores/trainerStores";
import { api, postWithJwt } from "lib";

const setupAllSocketEvents = (socket) => {
  setupChatMessageEvent(socket);
  setupReadMessageEvent(socket);
  setupNotificationEvent(socket);
};

const setupChatMessageEvent = (socket) => {
  socket.on("message", async (message) => {
    const currentUser = get(user);

    const oldMessages = get(chatMessages);
    if (
      isClient(currentUser) ||
      oldMessages[0]?.sessionId === message.session.id
    ) {
      if (!oldMessages.find((msg) => msg.ref === message.message.ref)) {
        chatMessages.set([...oldMessages, message.message]);
      } else {
        const index = oldMessages.findIndex(
          (msg) => msg.ref === message.message.ref
        );
        oldMessages[index] = message.message;
        chatMessages.set(oldMessages);
      }
    }
    if (isClient(currentUser)) {
      const unreadMessagesRef = get(unreadMessages);
      unreadMessages.set(unreadMessagesRef + 1);
      // Skroll na novu poruku kao klijent
      if (location.href.includes("chat"))
        setTimeout(() => window.scrollTo(0, document.body.scrollHeight));
      // Skroll na novu poruku kao klijent
    } else {
      let chatSessionsRef = get(chatSessions);
      const foundSession = chatSessionsRef.find(
        (session) => session.sessionId === message.session.id
      );
      const otherUser = message.session.participants.find(
        (user) => currentUser.id !== user.user.id
      );
      if (foundSession) {
        foundSession.lastMsg = message.message.payload;
        foundSession.updatedAt = message.message.createdAt;
        chatSessionsRef = chatSessionsRef.filter(
          (item) => item.id !== foundSession.id
        );
        chatSessionsRef.unshift(foundSession);
        chatSessions.set(chatSessionsRef);
      } else {
        const newSession = message.session;

        newSession.clientId = otherUser.user.id;
        newSession.clientName = otherUser.user.name;
        newSession.lastMsg = message.message.payload;
        newSession.sessionId = message.session.id;
        newSession.messages = [];
        chatSessions.set([newSession, ...chatSessionsRef]);
      }

      const notSeenSessionIdsRef = get(notSeenSessionIds);

      if (
        !notSeenSessionIdsRef.includes(message.session.id) &&
        message.message.senderId !== currentUser.id
      ) {
        notSeenSessionIds.set([...notSeenSessionIdsRef, message.session.id]);
        const unreadMessagesRef = get(unreadMessages);
        unreadMessages.set(unreadMessagesRef + 1);
      }

      // Skroll na novu poruku kao trener
      const trainerChatWithRef = get(trainerChatWith);
      if (
        trainerChatWithRef &&
        trainerChatWithRef.clientId === otherUser.user.id
      )
        setTimeout(() => window.scrollTo(0, document.body.scrollHeight));
      // Skroll na novu poruku kao trener

      // if (
      //   location.href.includes("chat") &&
      //   !location.href.includes("chat-list")
      // ) {
      //   const url = `${api}/chat/session/${message.session.id}/set_seen`;
      //   try {
      //     await postWithJwt(url, { seen: true });

      //     if (isClient(currentUser)) {
      //       unreadMessages.set(0);
      //     } else {
      //       if (
      //         get(notSeenSessionIds).find(
      //           (notSeenClientId) => notSeenClientId === +message.session.id
      //         )
      //       ) {
      //         unreadMessages.set(get(unreadMessages) - 1);
      //       }

      //       notSeenSessionIds.update((store) => {
      //         store = store.filter(
      //           (notSeenSessionId) => notSeenSessionId !== +message.session.id
      //         );
      //         return store;
      //       });
      //     }
      //   } catch (error) {
      //     console.error(error);
      //   }
      // }
    }
  });
};

const setupReadMessageEvent = (socket) => {
  socket.on("read_message", (data) => {
    if (get(user).id === data.userId) {
      if (
        get(notSeenSessionIds).find(
          (notSeenClientId) => notSeenClientId === +data.sessionId
        )
      ) {
        unreadMessages.set(get(unreadMessages) - 1);
      }
      notSeenSessionIds.update((ids) => {
        ids = ids.filter((id) => id !== data.sessionId);
        return ids;
      });
    }
  });
};

const setupNotificationEvent = (socket) => {
  socket.on("notification", (notification) => {
    const oldNotifications = get(notifications);
    unreadNotifications.set(get(unreadNotifications) + 1);
    notifications.set([notification, ...oldNotifications]);
    loadedWorkouts.set({});
  });
};

export { setupAllSocketEvents };
