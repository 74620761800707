<script lang="ts">
  import { onMount } from "svelte";
  import {
    Button,
    Fileupload,
    Heading,
    Helper,
    Input,
    Label,
    Spinner,
  } from "flowbite-svelte";
  import { api, postFormDataWithJwt, translate } from "lib";
  import { dialogData, showAlert, trainerMealPlans } from "stores";
  import { ButtonComponent } from "ui";

  let data: any;

  let form = {
    name: {
      value: "",
      error: "",
    },
    pdf: {
      value: "",
      error: "",
    },
  };

  let disabled = true;
  let isLoading = false;
  let files: FileList;
  const dialogType = data.meal ? translate("EDIT") : translate("CREATE");

  const onInput = (field: string): void => {
    // nzm sto svelte jebe, loguje predhodni value, moguce da je flowbite file input
    // component bug, ovo je kao "fix"
    setTimeout(() => {
      const { value } = form[field];

      if (field === "name") {
        if (value.length === 0) {
          form[field].error = translate("FIELD_REQUIRED");
        } else if (value.length < 2) {
          form[field].error = translate("FIELD_MINIMUM_2");
        } else if (value.length > 320) {
          form[field].error = translate("FIELD_MAXIMUM_320");
        } else {
          form[field].error = "";
        }
      }

      if (field === "pdf") {
        if (files.length === 0) {
          form[field].error = translate("FIELD_REQUIRED");
        } else {
          form[field].error = "";
        }
      }

      disabled = Object.values(form).some((field) => field.error !== "");
    }, 100);
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    const formData = new FormData();
    let response: any;

    try {
      if (data.meal) {
        const url = `${api}/meal_plan/${data.meal.mealPlanId}/meal/${data.meal.id}`;

        if (files) {
          formData.append("pdf", files[0]);
        }

        formData.append(
          "meal",
          JSON.stringify({
            name: form.name.value,
            entityType: 3,
          })
        );

        response = await postFormDataWithJwt(url, formData, "PUT");

        data.executeFunction(response);
      } else {
        const url = `${api}/meal_plan/${data.mealPlanId}/meal`;

        formData.append("pdf", files[0]);
        formData.append(
          "meal",
          JSON.stringify({
            name: form.name.value,
            entityType: 3,
          })
        );

        response = await postFormDataWithJwt(url, formData, "POST");

        data.executeFunction(response);
      }

      $dialogData.data = {};
      $dialogData.type = "";
      $showAlert.color = "black";
      $showAlert.message = `Created a new PDF meal.`;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating PDF meal.`;
    }
  };

  onMount((): void => {
    if (data.meal) {
      const { name } = data.meal;
      form.name.value = name;
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType} PDF {translate("MEAL").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}ㅤ{/if}
      </Helper>
    </div>

    <div>
      <Label for="pdf">PDF:</Label>
      <Fileupload
        id="pdf"
        placeholder="PDF"
        bind:value={form.pdf.value}
        bind:files
        on:change={() => onInput("pdf")}
      />
      <Helper color="red">
        {#if form.pdf.error}
          {form.pdf.error}
        {:else}ㅤ{/if}
      </Helper>
    </div>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent type="submit" {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
