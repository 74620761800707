<script lang="ts">
  import { onMount } from "svelte";
  import { Label, Radio, Spinner } from "flowbite-svelte";
  import { api, getWithJwt, postFormDataWithJwt, serverlessRoutes, translate } from "lib";
  import DatePicker from "../../../components/UI/DatePicker.svelte";
  import { tags, trainerClients } from "../../../stores/trainerStores";
  import {
    currentClient,
    dialogData,
    showAlert,
    trainerClientsCount,
    trainerClientsStore
  } from "stores";
  import Input from "../../../components/UI/Input.svelte";
  import { ButtonComponent, InputField } from "ui";

  let data: any;
  let disabled = true;
  let isLoading = false;
  let isPasswordVisible = false;
  let clientTags: any[] = [];

  const dialogType = data.client ? translate("EDIT") : translate("CREATE");

  const genders = ["MALE", "FEMALE"]; //based
  // const dailyGoals = ["BY_CALORIES", "BY_GRAMS_OF_MACRONUTRIENTS"];

  const areaCodes = [
    { value: "+1", name: "+1" },
    { value: "+30", name: "+30" },
    { value: "+31", name: "+31" },
    { value: "+32", name: "+32" },
    { value: "+33", name: "+33" },
    { value: "+34", name: "+34" },
    { value: "+36", name: "+36" },
    { value: "+39", name: "+39" },
    { value: "+43", name: "+43" },
    { value: "+44", name: "+44" },
    { value: "+45", name: "+45" },
    { value: "+46", name: "+46" },
    { value: "+47", name: "+47" },
    { value: "+48", name: "+48" },
    { value: "+49", name: "+49" },
    { value: "+351", name: "+351" },
    { value: "+352", name: "+352" },
    { value: "+353", name: "+353" },
    { value: "+354", name: "+354" },
    { value: "+355", name: "+355" },
    { value: "+356", name: "+356" },
    { value: "+358", name: "+358" },
    { value: "+359", name: "+359" },
    { value: "+370", name: "+370" },
    { value: "+371", name: "+371" },
    { value: "+381", name: "+381" },
    { value: "+382", name: "+382" },
    { value: "+385", name: "+385" },
    { value: "+386", name: "+386" },
    { value: "+387", name: "+387" },
    { value: "+389", name: "+389" },
  ];

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const phoneNumberRegex = /^\+\d+$/;

  const form = {
    // Core
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    expireAt: { value: "", error: "" },
    // gender: { value: "MALE", error: "" },
    dateOfBirth: { value: "", error: "" },
    height: { value: "", error: "" },
    areaCode: { value: "", error: "" },
    phoneNumber: { value: "", error: "" },
    // Daily macro goals
    // dailyGoal: { value: "BY_CALORIES", error: "" },
    // dailyCaloricIntake: { value: 500, error: "" },
    // carbs: { value: 500, error: "" },
    // protein: { value: 500, error: "" },
    // fats: { value: 500, error: "" },
    // // Tags
    // tags: { value: [] as any[], error: "" },
    // newTag: { value: "", error: "" },
    // newTagColor: { value: "", error: "" },
    // // Client interactions
    // commentPermission: { value: false, error: "" },
    // modifyDietPermission: { value: false, error: "" },
    // modifyTrainingPermission: { value: false, error: "" },
    // chatPermission: { value: false, error: "" },

    // message: { value: "", error: "" },
  };

  let emailLookupTimeout: NodeJS.Timeout;

  const onInput = async (field: keyof typeof form) => {
    if (field === "name") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 40) {
        form[field].error = translate("FIELD_MAXIMUM_40");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "email") {
      clearTimeout(emailLookupTimeout);

      const { value } = form[field];
      const isValid = emailRegex.test(value);

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (!isValid) {
        form[field].error = translate("EMAIL_INVALID");
      } else {
        // ako je edit, i ako je client.email === value, error = "";
        // ako je edit, i ako client.email != value, error = lookup;
        // ako je create, error = lookup
        if (data.client) {
          if (data.client.email !== form[field].value) {
            emailLookupTimeout = setTimeout(async (): Promise<void> => {
              const response = await getWithJwt(
                `${serverlessRoutes.EMAIL_LOOKUP}?email=${form[field].value}`
              );

              if (response.data !== "USER_NOT_FOUND") {
                form[field].error = translate("EMAIL_TAKEN");
              } else {
                form[field].error = "";
              }

              disabled = Object.values(form).some((field): boolean => field.error !== "");
            }, 500);
          } else {
            form[field].error = "";
            disabled = Object.values(form).some((field): boolean => field.error !== "");
          }
        } else {
          emailLookupTimeout = setTimeout(async (): Promise<void> => {
            const response = await getWithJwt(
              `${serverlessRoutes.EMAIL_LOOKUP}?email=${form[field].value}`
            );

            if (response.data !== "USER_NOT_FOUND") {
              form[field].error = translate("EMAIL_TAKEN");
            } else {
              form[field].error = "";
            }

            disabled = Object.values(form).some((field): boolean => field.error !== "");
          }, 500);
        }
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "password") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 4) {
        form[field].error = translate("FIELD_MINIMUM_4");
      } else if (value.length > 20) {
        form[field].error = translate("FIELD_MAXIMUM_20");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "phoneNumber") {
      const { value } = form[field];

      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (!phoneNumberRegex.test(value)) {
        form[field].error = translate("PHONE_NUMBER_INVALID");
      } else if (value.length > 15) {
        form[field].error = translate("FIELD_MAXIMUM_15");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    if (field === "height") {
      const {value} = form[field];
      if (value < 100 || value > 999) {
        form[field].error = translate("FIELD_THREE_DIGITS");
      } else {
        form[field].error = "";
      }

      disabled = Object.values(form).some((field): boolean => field.error !== "");
    }

    // if (field === "calories") {
    //   const { value } = form[field];

    //   if (value < 500) {
    //     form[field].error = translate("FIELD_MINIMUM_AMOUNT_500");
    //   } else if (value > 10000) {
    //     form[field].error = translate("FIELD_MAXIMUM_AMOUNT_10000");
    //   } else {
    //     form[field].error = "";
    //   }
    // }

    // if (field === "message") {
    //   const { value } = form[field];

    //   if (value.length === 0) {
    //     form[field].error = translate("FIELD_REQUIRED");
    //   } else if (value.length < 2) {
    //     form[field].error = translate("FIELD_MINIMUM_2");
    //   } else if (value.length > 320) {
    //     form[field].error = translate("FIELD_MAXIMUM_320");
    //   } else {
    //     form[field].error = "";
    //   }
    // }

  };

  // const onTagChange = (id: number): void => {
  //   if (form.tags.value.includes(id)) {
  //     form.tags.value = form.tags.value.filter((_id): boolean => _id !== id);
  //   } else {
  //     form.tags.value = [...form.tags.value, id];
  //   }
  // };

  const createClient = async (): Promise<void> => {
    const clientFormData = new FormData();
    // const macrosFormData = new FormData();
    // const customReminderFormData = new FormData();

    try {
      const parsedObj = {};
      Object.keys(form).forEach((key) => {
        if (form[key].value !== "") {
          parsedObj[key] = form[key].value;
        }
      });
      clientFormData.append("user", JSON.stringify(parsedObj));
      // clientFormData.append("tags", JSON.stringify(form.tags.value));

      // if (form.dailyGoal.value === "BY_CALORIES") {
      //   clientFormData.append(
      //     "clientOptions",
      //     JSON.stringify({
      //       commentPermission: form.commentPermission.value,
      //       modifyDietPermission: form.modifyDietPermission.value,
      //       modifyTrainingPermission: form.modifyTrainingPermission.value,
      //       chatPermission: form.chatPermission.value,
      //       dailyCaloricIntake: form.dailyCaloricIntake.value,
      //     })
      //   );
      // } else {
      //   clientFormData.append(
      //     "clientOptions",
      //     JSON.stringify({
      //       commentPermission: form.commentPermission.value,
      //       modifyDietPermission: form.modifyDietPermission.value,
      //       modifyTrainingPermission: form.modifyTrainingPermission.value,
      //       chatPermission: form.chatPermission.value,
      //       protein: form.protein.value,
      //       carbs: form.carbs.value,
      //       fats: form.fats.value,
      //     })
      //   );
      // }

      const editingClient = data.client ? data.client.id : "";
      const method = data.client ? "PUT" : "POST";
      const clientResponse = await postFormDataWithJwt(
        `${api}/trainer/client/${editingClient}`,
        clientFormData,
        method
      );

      // const macrosResponse = await postWithJwt(`${api}/macros`, {
      //   clientId: clientResponse.id,
      //   macros: {
      //     calories: form.dailyCaloricIntake.value,
      //     carbs: form.carbs.value,
      //     protein: form.protein.value,
      //     fats: form.fats.value,
      //   },
      // });

      // if (form.message.value) {
      //   customReminderFormData.append(
      //     "customReminder",
      //     JSON.stringify({
      //       note: "",
      //       date: "2023-12-30T23:00:00.000Z",
      //       message: form.message.value,
      //       action: 0,
      //     })
      //   );
      //   customReminderFormData.append(
      //     "clientId",
      //     JSON.stringify(clientResponse.id)
      //   );
      //   customReminderFormData.append("thumbnail", JSON.stringify(undefined));

      //   const customReminderResponse = await postFormDataWithJwt(
      //     `${api}/custom_reminder`,
      //     customReminderFormData
      //   );
      // }

      if (data.client) {
        if ($currentClient.id === data.client.id) {
          $currentClient = { ...$currentClient, ...clientResponse };
        }

        delete clientResponse.avatarUrl;
        trainerClientsStore.change(data.client.id, clientResponse);

        // const index = $trainerClients.findIndex(
        //   (client) => client.id === data.client.id
        // );
        // delete clientResponse.avatarUrl;
        // $trainerClients[index] = {
        //   ...$trainerClients[index],
        //   ...clientResponse,
        // };
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")}
          ${translate("CLIENT").toLowerCase()}
        `;
      } else {
        trainerClientsStore.add([clientResponse], "start", true);

        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")}
          ${translate("CLIENT").toLowerCase()}
        `;
      }

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("ERROR_CREATING_CLIENT");
    }
  };

  const resetPassword = async () => {
    if (!data?.client) return;
    try {
      const clientFormData = new FormData();
      const pwObj = { password: "treniraj" };
      clientFormData.append("user", JSON.stringify(pwObj));
      const clientResponse = await postFormDataWithJwt(
        `${api}/trainer/client/${data.client.id}`,
        clientFormData,
        "PUT"
      );
      $showAlert.message = translate("SUCCESSFULLY_RESET_PASSWORD");
      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;
    await createClient();
  };

  onMount(async () => {
    clientTags = $tags.map(({ id, color, note }) => ({
      id,
      color: `bg-[${color}]`,
      note,
    }));

    if (data.client) {
      form.name.value = data.client.name;
      await onInput("name");
      form.email.value = data.client.email;
      await onInput("email");
      form.password = { value: "", error: "" };
      form.phoneNumber = { value: data.client.phoneNumber, error: "" };
    } else {
      form.password.value = "treniraj";
    }
  });

  export { data };
</script>

<div class="p-4">
  <h3 class="text-bold text-xl text-center">
    {dialogType}
    {translate("CLIENT_A").toLowerCase()}
  </h3>

  <form
    autocomplete="off"
    class="flex flex-col gap-4"
    on:submit|preventDefault={onSubmit}
  >
    <InputField
      label={translate("NAME")}
      error={form.name.error}
      bind:value={form.name.value}
      on:input={() => onInput("name")}
    />

    <InputField
      label={translate("EMAIL")}
      error={form.email.error}
      bind:value={form.email.value}
      on:input={() => onInput("email")}
    />

    <InputField
      type="password"
      label={translate("PASSWORD")}
      error={form.password.error}
      bind:value={form.password.value}
      on:input={() => onInput("password")}
    />

    <InputField
      label={translate("PHONE_NUMBER")}
      placeholder="+381 1234 567 890"
      error={form.phoneNumber.error}
      bind:value={form.phoneNumber.value}
      on:input={() => onInput("phoneNumber")}
    />

    <InputField
      type="date"
      label={translate("EXPIRATION_DATE")}
      error={form.expireAt.error}
      bind:value={form.expireAt.value}
      on:input={() => onInput("expireAt")}
    />

    <!-- <Input
      name={"password"}
      label={"PASSWORD"}
      type={"password"}
      placeholder={translate("PASSWORD")}
      bind:value={form.password.value}
      {onInput}
    />
    {#if form.password.error}
      <span class="text-red-500 text-xs">{form.password.error}</span>
    {/if} -->

    <!-- <Input
      name="phoneNumber"
      label="PHONE_NUMBER"
      placeholder={"+38161111111"}
      bind:value={form.phoneNumber.value}
      {onInput}
    />
    {#if form.phoneNumber.error}
      <span class="text-red-500 text-xs">{form.phoneNumber.error}</span>
    {/if}
    <div class="mb-2" /> -->

    <!-- <div>
      <DatePicker label="EXPIRATION_DATE" title="EXPIRATION_DATE" bind:date={form.expireAt.value} />
    </div> -->

    <!-- <div>
      <Label>{translate("GENDER")}:</Label>
      <div class="flex justify-evenly">
        {#each genders as gender}
          <Radio value={gender} bind:group={form.gender.value}>
            {translate(gender)}
          </Radio>
        {/each}
      </div>
    </div> -->

    <InputField
      type="date"
      label={translate("DATE_OF_BIRTH")}
      error={form.dateOfBirth.error}
      bind:value={form.dateOfBirth.value}
      on:input={() => onInput("dateOfBirth")}
    />

    <InputField
      type="number"
      label={translate("HEIGHT")}
      placeholder="180"
      suffix="cm"
      error={form.height.error}
      bind:value={form.height.value}
      on:input={() => onInput("height")}
    />
    <!--<div class="flex gap-4">
       <DatePicker label="DATE_OF_BIRTH" title="DATE_OF_BIRTH" bind:date={form.dateOfBirth.value} />
      <div>
        <Input
          label="HEIGHT"
          placeholder="180"
          bind:value={form.height.value}
          {onInput}
        />
        {#if form.height.error}
          <span class="text-red-500 text-xs">{form.height.error}</span>
        {/if}
      </div> 
    </div>-->

    <!-- <Accordion>
      <AccordionItem>
        <div slot="header">{translate("DAILY_MACRO_GOALS")}</div>
        {#each dailyGoals as dailyGoal}
          <Radio value={dailyGoal} bind:group={form.dailyGoal.value}>
            {translate(dailyGoal)}
          </Radio>
        {/each}
        <br />
        {#if form.dailyGoal.value === "BY_CALORIES"}
          <div in:scale>
            <Label for="calories">{translate("CALORIES")}:</Label>
            <NumberInput
              id="calories"
              placeholder={translate("CALORIES")}
              bind:value={form.dailyCaloricIntake.value}
              on:input={() => onInput("dailyCaloricIntake")}
            />
            <Helper color="red">
              {#if form.dailyCaloricIntake.error}
                {form.dailyCaloricIntake.error}
              {:else}
                ㅤ
              {/if}
            </Helper>
            {#if !form.dailyCaloricIntake.error}
              <DualSlider />
            {/if}
          </div>
        {:else}
          <div class="flex gap-2" in:scale>
            <div>
              <Label for="carbs">{translate("CARBS")}:</Label>
              <Input
                id="carbs"
                placeholder={translate("CARBS")}
                bind:value={form.carbs.value}
                on:input={() => onInput("carbs")}
              />
              <Helper color="red">
                {#if form.carbs.error}
                  {form.carbs.error}
                {:else}
                  ㅤ
                {/if}
              </Helper>
            </div>
            <div>
              <Label for="protein">{translate("PROTEIN")}:</Label>
              <Input
                id="protein"
                placeholder={translate("PROTEIN")}
                bind:value={form.protein.value}
                on:input={() => onInput("protein")}
              />
              <Helper color="red">
                {#if form.protein.error}
                  {form.protein.error}
                {:else}
                  ㅤ
                {/if}
              </Helper>
            </div>
            <div>
              <Label for="fats">{translate("FATS")}:</Label>
              <Input
                id="fats"
                placeholder={translate("FATS")}
                bind:value={form.fats.value}
                on:input={() => onInput("fats")}
              />
              <Helper color="red">
                {#if form.fats.error}
                  {form.fats.error}
                {:else}
                  ㅤ
                {/if}
              </Helper>
            </div>
          </div>
        {/if}
      </AccordionItem>

      <AccordionItem>
        <div slot="header">{translate("TAGS")}</div>
        {#each clientTags as clientTag}
          <Label class="flex items-center cursor-pointer">
            <Svg
              name="bookmark-simple"
              size={24}
              customColor={clientTag.color}
            />
            <div class="grow">{clientTag.note}</div>
            <Checkbox
              checked={form.tags.value.includes(clientTag.id)}
              on:change={() => onTagChange(clientTag.id)}
            />
          </Label>
        {/each}
        <Hr />
        <Label for="newtag">Nova oznaka:</Label>
        <Input id="newtag" placeholder="Nova oznaka" />
        <br />
        <Label for="color">
          Boja:
          <Svg name="bookmark-simple" size={24} />
          <input
            class="hidden"
            id="color"
            type="color"
            placeholder="Boja"
            bind:value={form.newTagColor.value}
            on:input={() => {}}
          />
        </Label>
      </AccordionItem>

      <AccordionItem>
        <div slot="header">{translate("CLIENT_INTERACTIONS")}</div>
        <Checkbox bind:checked={form.commentPermission.value}
          >{translate("COMMENTING")}</Checkbox
        >
        <Checkbox bind:checked={form.modifyDietPermission.value}
          >{translate("CREATING_GROCERIES")}</Checkbox
        >
        <Checkbox bind:checked={form.modifyTrainingPermission.value}
          >{translate("CREATING_EXERCISES")}</Checkbox
        >
        <Checkbox bind:checked={form.chatPermission.value}
          >{translate("CHATTING")}</Checkbox
        >
      </AccordionItem>

      <AccordionItem>
        <div slot="header">{translate("AUTOMATED_MESSAGE")}</div>
        <DatePicker title="DATE_OF_DISPATCH" date={new Date()} />

        <div>
          <Label for="message">{translate("MESSAGE")}:</Label>
          <Textarea
            id="message"
            placeholder={translate("MESSAGE")}
            rows="4"
            bind:value={form.message.value}
            on:input={() => onInput("message")}
          />
          <Helper color="red">
            {#if form.message.error}
              {form.message.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>

        <Label>{translate("DISPATCH_METHOD")}:</Label>
        <Checkbox>Email</Checkbox>
        <Checkbox>SMS</Checkbox>
        <Checkbox>{translate("IN_APP")}</Checkbox>
      </AccordionItem>
    </Accordion> -->

    <div class="h-10 flex justify-around">
      {#if data.client}
        <ButtonComponent customColor="bg-red-700" on:click={resetPassword}
          >{translate("RESET_PASSWORD")}</ButtonComponent
        >
      {/if}
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent on:click={onSubmit} {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
