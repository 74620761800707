<script lang="ts">
  import {
    ButtonGroup,
    Checkbox,
    InputAddon,
    NumberInput,
  } from "flowbite-svelte";

  import {createEventDispatcher, afterUpdate} from "svelte";

  import {
    calculateMacros,
    calculateRecipeMacros,
    deleteWithJwt,
    dialogTypes,
    isClient,
    postWithJwt,
    serverlessRoutes,
    translate
  } from "lib";

  import {
    dialogData,
    loadedMealsStore,
    selectedIds,
    showAlert,
    trainerIngredientsStore,
    trainerRecipes,
    user
  } from "stores";

  import {More, Svg} from "ui";
  import InputMini from "../UI/InputMini.svelte";

  import Macros from "./Macros.svelte";

  import type {Ingredient, MenuItem} from "interfaces";

  const dispatch = createEventDispatcher();

  export let ingredient: Ingredient;
  export let selectMode: boolean = false;
  export let isAddMode = false;
  export let isMoreVisible = false;
  export let isRecipeTemplate = false;
  export let isModifiable = false;
  export let isModifiable2 = false;
  export let isForDelete = false;
  let menuItems: MenuItem[] = [];

  const deleteIngredient = async (): Promise<void> => {
    const {id} = ingredient;

    try {
      await deleteWithJwt(`${serverlessRoutes.INGREDIENT}/${id}`);

      trainerIngredientsStore.remove([id]);

      dispatch("delete", id);

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_DELETED")}
        ${translate("INGREDIENT").toLowerCase()}
      `;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteIngredientDialog = (): void => {
    $dialogData.data = {
      executeFunction: deleteIngredient,
      title: ingredient.name,
    };
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const copyIngredient = async (): Promise<void> => {
    try {
      const partialIngredientMap = {
        [ingredient.id]: {}
      };
      const response = await postWithJwt(
        `${serverlessRoutes.INGREDIENT}/copy`,
        {partialIngredientMap}
      );

      if (response.error === null) {
        $showAlert.message = `${translate("SUCCESSFULLY_COPIED")} ${translate(
          "INGREDIENT"
        ).toLowerCase()}`;

        if (ingredient.recipeId) {
          const recipe = $trainerRecipes.find((recipe) => recipe.id === ingredient.recipeId);

          if (recipe) { // U default recipe
            recipe.ingredients = [...recipe.ingredients, ...response.data];
            calculateRecipeMacros(recipe);
            $trainerRecipes = $trainerRecipes;
          } else { // U recipe u meal
            dispatch("createCopy", {
              recipeId: ingredient.recipeId,
              ingredients: response.data
            });
          }
        } else if (ingredient.mealId) { // U meal
          const meal = $loadedMealsStore.find((meal: any): boolean => meal.id === ingredient.mealId);
          meal.ingredients = [...meal.ingredients, ...response.data];
          $loadedMealsStore = $loadedMealsStore;
          calculateMacros();
        } else { // U default ingredients
          trainerIngredientsStore.add(response.data, true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const copyTemplateIngredient = async (): Promise<void> => {
    try {
      const partialIngredientMap = {
        [ingredient.id]: {isTemplate: 0}
      };

      const response = await postWithJwt(
        `${serverlessRoutes.INGREDIENT}/copy`,
        {partialIngredientMap}
      );

      // console.log({response});

      // if (response.error === null) {
      //   $showAlert.message = `${translate("SUCCESSFULLY_COPIED")} ${translate(
      //     "INGREDIENT"
      //   ).toLowerCase()}`;

      //   if (ingredient.recipeId) {
      //     const recipe = $trainerRecipes.find((recipe) => recipe.id === ingredient.recipeId);

      //     if (recipe) { // U default recipe
      //       recipe.ingredients = [...recipe.ingredients, ...response.data];
      //       $trainerRecipes = $trainerRecipes;
      //     } else { // U recipe u meal
      //       dispatch("createCopy", {
      //         recipeId: ingredient.recipeId,
      //         ingredients: response.data
      //       });
      //     }
      //   } else if (ingredient.mealId) {
      //     const meal = $loadedMealsStore.find((meal: any): boolean => meal.id === ingredient.mealId);
      //     meal.ingredients = [...meal.ingredients, ...response.data];
      //     console.log(meal.ingredients);
      //     $loadedMealsStore = $loadedMealsStore;
      //   } else { // U default ingredients
          trainerIngredientsStore.add(response.data, true);
      //   }
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleSelect = (checked: boolean): void => {
    if (checked) {
      $selectedIds = [...$selectedIds, ingredient.id];
    } else {
      $selectedIds = $selectedIds.filter((id) => id !== ingredient.id);
    }
  };

  const onMinus = (): void => {
    if (ingredient.amount <= 1) {
      return;
    }

    ingredient.amount -= 1;
    dispatch("ingredientAmount");
  };

  const onPlus = (): void => {
    ingredient.amount += 1;
    dispatch("ingredientAmount");
  };

  const onTrash = (): void => {
    dispatch("ingredientTrash", ingredient.id);
  };

  const onUndo = (): void => {
    dispatch("ingredientTrash", ingredient.id);
  };

  const onOpenIngredientDialog = (): void => {
    if (isAddMode || selectMode || isModifiable || isModifiable2) {
      return;
    }

    $dialogData.data = ingredient;
    $dialogData.type = dialogTypes.INGREDIENT_INFO;
  };

  const onCreateEditIngredient = (): void => {
    $dialogData.data = {
      ingredient,
      recipeId: ingredient.recipeId,
      mealId: ingredient.mealId
    };

    $dialogData.type = dialogTypes.CREATE_EDIT_INGREDIENT;
  };

  const finishedAtClientMenuItems: MenuItem[] = [
    // {
    //   icon: "delete",
    //   title: "DELETE",
    //   executeFunction: deleteIngredientDialog,
    // },
  ];

  const finishedAtTrainerMenuItems: MenuItem[] = [
    {
      icon: "edit",
      title: "EDIT",
      executeFunction: onCreateEditIngredient,
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction: deleteIngredientDialog,
    },
  ];

  const trainerMenuItems: MenuItem[] = [
    {
      icon: "edit",
      title: "EDIT",
      executeFunction: onCreateEditIngredient,
    },
    {
      icon: "copy",
      title: "CREATE_COPY",
      executeFunction: copyIngredient,
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction: deleteIngredientDialog,
    },
  ];

  const templateMenuItems: MenuItem[] = [
    {
      icon: "copy",
      title: "CREATE_COPY",
      executeFunction: copyTemplateIngredient,
    },
    {
      icon: "chart-bar",
      title: "DETAILS",
      executeFunction(): void {
        $dialogData.data = { type: "ingredient" };
        $dialogData.type = dialogTypes.TEMPLATE;
      },
    },
  ];

  afterUpdate((): void => {
    if (isClient($user)) {
      if (ingredient.finishedAt) {
        menuItems = finishedAtClientMenuItems;
      }
    } else {
      if (ingredient.finishedAt) {
        menuItems = finishedAtTrainerMenuItems;
      } else if (ingredient.isTemplate) {
        menuItems = templateMenuItems;
      } else {
        menuItems = trainerMenuItems;
      }
    }
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="relative p-2 flex-col gap-2 border border-slate-200 dark:border-zinc-600 rounded-md text-slate-900 dark:text-slate-100"
  class:opacity-50={isForDelete}
>
  {#if ingredient.isTemplate}
    <div class="tag">
      <div class="tag__clip bg-primary-500 dark:bg-primary-700"></div>
      <div class="tag__icon">
        <Svg myClass="mr-2" name="star" size={12} customColor="bg-white" />
      </div>
    </div>
  {/if}
  <div class="flex align-center gap-2">
    <div
      class="flex-grow"
      class:ml-4={ingredient.isTemplate}
      on:click={onOpenIngredientDialog}
      on:keydown={onOpenIngredientDialog}
    >
      {ingredient.name}
      {#if ingredient.measurementUnit && !isModifiable}
        ({ingredient.amount} {translate(ingredient.measurementUnit)})
      {/if}
    </div>
    {#if selectMode}
      <div class="flex center-center">
        <Checkbox
          color="teal"
          checked={$selectedIds.includes(ingredient.id)}
          on:change={(event) => toggleSelect(event.target.checked)}
        />
      </div>
    {:else if isAddMode}
      <Svg
        name="plus"
        size={16}
        myClass="bg-green-600"
        on:click={() => dispatch("add", ingredient)}
      />
    {:else if isModifiable}
      <div class="flex items-center gap-2" style="height: 24px">
        <Svg name="minus" size={16} myClass="bg-green-600" on:click={onMinus} />
        <InputMini
          type="number"
          bind:value={ingredient.amount}
          on:input={() => dispatch("ingredientAmount")}
        />
        <Svg name="plus" size={16} myClass="bg-green-600" on:click={onPlus} />
        {#if isForDelete}
          <Svg name="undo" size={16} myClass="bg-red-600" on:click={onUndo} />
        {:else}
          <Svg
            name="delete"
            size={16}
            myClass="bg-red-600"
            on:click={onTrash}
          />
        {/if}
      </div>
    {:else if isModifiable2}
      <div class="flex items-center gap-2" style="height: 24px">
        <Svg name="minus" size={16} myClass="bg-green-600" on:click={onMinus} />
        <ButtonGroup class="p-0 w-20" size="sm">
          <NumberInput
            class="p-0"
            id="ingredientAmount"
            placeholder={translate("AMOUNT")}
            bind:value={ingredient.amount}
            on:input={() => ""}
          />
          <InputAddon class="p-0"
            >{translate(ingredient.measurementUnit)?.toLowerCase()}</InputAddon
          >
        </ButtonGroup>
        <!-- <Input color="green" type="number" bind:value="{ingredient.amount}" style="width: 64px; height: 16px; text-align: center" /> -->
        <Svg name="plus" size={16} myClass="bg-green-600" on:click={onPlus} />
      </div>
    {:else if ingredient.finishedAt || (!isClient($user) && !isRecipeTemplate)}
      <More {menuItems} />
    {/if}
  </div>
  {#if isModifiable || isModifiable2}
    <Macros
      carbs={(ingredient.originalCarbs / ingredient.originalAmount) *
        ingredient.amount}
      protein={(ingredient.originalProtein / ingredient.originalAmount) *
        ingredient.amount}
      fats={(ingredient.originalFats / ingredient.originalAmount) *
        ingredient.amount}
      calories={(ingredient.originalCalories / ingredient.originalAmount) *
        ingredient.amount}
      isCaloriesVisible
      isBarVisible
    />
  {:else}
    <Macros
      carbs={ingredient.carbs}
      protein={ingredient.protein}
      fats={ingredient.fats}
      calories={ingredient.calories}
      isCaloriesVisible
    />
  {/if}
</div>

<style>
  .tag {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 32px;
    width: 32px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }
  .tag__icon {
    position: absolute;
    top: 4px;
    left: 4px;
  }
  .tag__clip {
    height: 32px;
    width: 32px;
    clip-path: polygon(0px 0px, 32px 0px, 0px 32px);
    /* background-color: #6875f5; */
  }
</style>
