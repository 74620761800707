<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import Router, { replace } from "svelte-spa-router";
  import Menu from "./components/Menu/Menu.svelte";
  import { dialogTypes, serverlessRoutes, version } from "./lib/constants";
  import { getWithJwt } from "./lib/requests";
  import { user } from "./stores/userStore";
  import { theme } from "./stores/themeStore";
  import { language } from "./stores/languageStore";
  import { routes } from "./lib/routes";
  import Dialog from "./components/Dialogs/Dialog.svelte";
  import { dialogData } from "./stores/dialogDataStore";
  import { socket } from "./stores/socketStore";
  import Overlay from "./components/UI/Overlay.svelte";
  import io from "socket.io-client";
  import { setupAllSocketEvents } from "./lib/setupSocketEvents";
  import Alert from "./components/UI/Alert.svelte";
  import { showAlert } from "./stores/showAlertStore";
  import { Capacitor } from "@capacitor/core";
  import { location } from "svelte-spa-router";
  import { StatusBar, Style } from "@capacitor/status-bar";
  import { Keyboard } from "@capacitor/keyboard";
  import initNativePushNotifications from "./lib/initPushNotifications";
  import * as Sentry from "@sentry/browser";
  import { App } from "@capacitor/app";
  import { fetchUser } from "./lib/fetchUser";
  import { setupAvatars } from "./lib/setupAvatars";
  import { fetchNotifications } from "./lib/fetchNotifications";
  import { fetchChatSessions } from "./lib/fetchSessions";
  import {
    chatSessions,
    chatSessionsCount,
    showOnlyUnreadChat,
  } from "./stores/chatSessionsStore";
  import { fetchTags } from "./lib/fetchTags";
  import { isClient } from "./lib/roles";

  let interval;

  // const initSwipes = () => {
  //   let isLocked = false;
  //   let touchstartX = 0;
  //   let touchendX = 0;

  //   function checkDirection() {
  //     if (touchendX < touchstartX) alert("swiped left!");
  //     if (touchendX > touchstartX) alert("swiped right!");
  //   }

  //   document.addEventListener("touchstart", (e) => {
  //     isLocked = true;
  //     setTimeout(() => (isLocked = false), 150);
  //     touchstartX = e.changedTouches[0].screenX;
  //   });

  //   document.addEventListener("touchend", (e) => {
  //     if (isLocked) return;
  //     touchendX = e.changedTouches[0].screenX;
  //     checkDirection();
  //   });
  // };

  const socketConnection = () => {
    const token = localStorage.getItem("authJwt");
    const prodApi = "wss://train-me-api.online";
    // const socketV2 = new WebSocket("ws://localhost:8090/ws");
    // // Connection opened
    // socketV2.addEventListener("open", (event) => {
    //   const auth = { type: "auth", payload: token };
    //   socketV2.send(JSON.stringify(auth));
    // });

    // setTimeout(() => {
    //   const msg = {
    //     type: "Message",
    //     payload: "Ludiloooo",
    //   };
    //   socketV2.send(JSON.stringify(msg));
    // }, 1000);

    const socketUser = {
      id: $user.id,
      name: $user.name,
      avatar: "",
      role: "Client",
    };
    const socket = io(prodApi, {
      query: { token, user: JSON.stringify(socketUser) },
    });
    setupAllSocketEvents(socket);
    socket.on("change_trainer", (event) => {
      fetchUser();
      replace("/training/program");
    });
    $socket = socket;
  };

  const setTheme = async () => {
    $theme = localStorage.getItem("theme");
    if ($theme === "dark") {
      document.documentElement.classList.add("dark");
      if (Capacitor.isNativePlatform())
        await StatusBar.setStyle({ style: Style.Dark });
    } else {
      document.documentElement.classList.remove("dark");
      if (Capacitor.isNativePlatform())
        await StatusBar.setStyle({ style: Style.Light });
    }
  };

  const setLanguage = () => {
    const myLanguage = localStorage.getItem("language");
    $language = myLanguage;
  };

  let unsubscribeLocation;

  onMount(async () => {
    const jwt = localStorage.getItem("authJwt");

    if (jwt) {
      await fetchUser();
    }

    if (isClient($user)) {
      const dontShowSetDateOfBirth = localStorage.getItem("dontShowSetDateOfBirth")
      const response = await getWithJwt(serverlessRoutes.USER_DATA);

      if (!response.data.user?.dateOfBirth) {
        if (
          !dontShowSetDateOfBirth ||
          (
            dontShowSetDateOfBirth &&
            JSON.parse(dontShowSetDateOfBirth) === false
          )
        ) {
          $dialogData.data = {};
          $dialogData.type = dialogTypes.SET_DATE_OF_BIRTH;
        }
      }
    }

    // $dialogData.isCloseable = false;
    // $dialogData.type = dialogTypes.FORCE_UPDATE;

    unsubscribeLocation = location.subscribe((): void => {
      window.scrollTo({ top: 0 });
    });

    if (Capacitor.isNativePlatform()) {
      App.addListener("appStateChange", async ({ isActive }) => {
        if (isActive) {
          await fetchUser();
        }
      });
    }
    App.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
    // initSwipes();
    if ($location === "/" && $user) {
      if (isClient($user)) replace("/training/program");
      else replace("/clients/list");
    }
  });

  const askForPhonenumber = () => {
    if (
      $user &&
      isClient($user) &&
      $user.trainer.id === 13 &&
      !$user.phoneNumber &&
      localStorage.getItem("showPhonenumberDialog") !== "false"
    )
      $dialogData.type = dialogTypes.ASK_FOR_PHONENUMBER;
  };

  const updateVersionInfo = async () => {
    const url = serverlessRoutes.PATCH_VERSION;
    try {
      await putWithJwt(url, { version });
    } catch (err) {}
  };

  const unsubscribe = user.subscribe(async (res) => {
    if (res && res.id) {
      Sentry.setUser({ email: res.email });
      await setTheme();
      setLanguage();
      if (Capacitor.isNativePlatform()) {
        initNativePushNotifications();
      }
      askForPhonenumber();
      setupAvatars();
      fetchNotifications().then(() =>
        fetchChatSessions().then(() => socketConnection())
      );
      updateVersionInfo();
      fetchTags();
    }
  });

  setTimeout(() => {
    showOnlyUnreadChat.subscribe((res) => {
      if (res !== -1) {
        chatSessions.set([]);
        chatSessionsCount.set(0);
        fetchChatSessions();
      }
    });
  }, 3000);

  onDestroy(() => {
    unsubscribeLocation();
    unsubscribe();
    $socket.disconnect();
    clearInterval(interval);
  });

  const conditionsFailed = (event) => {
    // console.error('conditionsFailed event', event.detail)
    replace("/auth");
  };
</script>

<div
  class={`${Capacitor.getPlatform() === "android" ? "android-margin" : ""} relative flex-col center-center bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100 ${Capacitor.isNativePlatform() ? "extra-height" : ""}`}
>
  <div
    class="content-container w-full min-h-screen max-w-screen-sm"
    id="contentContainer"
  >
    <Router {routes} on:conditionsFailed={conditionsFailed} />
  </div>
  {#if $dialogData.type}
    <Overlay />
    <Dialog
      isScrollable={$dialogData.type === dialogTypes.COMPARE_PHOTOS
        ? false
        : true}
    />
    <!--  Umesto <Dialog/> ide ovo dole, onda se dialog otvara tako sto se zove
          openDialog("Component", data: typeof "Component" data);
          i nema if/else hell u Dialog.svelte -->
    <!-- <svelte:component this="{$dialogData.component}"/> -->
  {/if}
  <Menu />
  {#if $showAlert.message !== ""}
    <Alert />
  {/if}
</div>

<style>
  .android-margin {
    margin-top: env(safe-area-inset-top);
  }
  .extra-height {
    height: calc(100vh - 48px);
  }
</style>
