<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { Badge, Checkbox, Input, Label } from "flowbite-svelte";
  import { api, getWithJwt, translate } from "lib";
  import { recipeTypesStore } from "stores";
  import type { MealPlanTypes } from "../Dialogs/DietDialogs/types";
  import { ButtonComponent } from "ui";

  const dispatcher = createEventDispatcher();

  let selected: Array<string> = [];
  let ingredients: MealPlanTypes = [];
  let ingredient: string;
  let isEditIngredientsVisible = false;

  $: isInsertDisabled = ingredient ? false : true;

  const onInsertIngredient = (): void => {
    ingredients = [
      ...ingredients,
      {
        name: ingredient,
        checked: true,
      },
    ];

    dispatcher(
      "mealPlanTypes",
      ingredients.filter(({ checked }) => checked).map((i) => i.name)
    );

    ingredient = "";
  };

  const onUpdate = (): void => {
    dispatcher(
      "mealPlanTypes",
      ingredients.filter(({ checked }) => checked).map((i) => i.name)
    );
  };

  const onToggleMealPlanTypes = (): void => {
    isEditIngredientsVisible = !isEditIngredientsVisible;
  };

  onMount(async (): Promise<void> => {
    if (!$recipeTypesStore.length) {
      const response: Array<string> = await getWithJwt(
        `${api}/recipe/food_types`
      );

      if (response) {
        $recipeTypesStore = response;
      }
    }

    ingredients = $recipeTypesStore.map((foodType) => ({
      name: foodType,
      checked: false,
    }));

    ingredients.forEach((mealPlanType) => {
      if (selected.includes(mealPlanType.name)) {
        mealPlanType.checked = true;
      }
    });
  });

  export { selected };
</script>

{#if isEditIngredientsVisible}
  <div class="p-4 flex flex-col gap-4 border rounded-md border-zinc-600">
    {#each ingredients as { name, checked }}
      <Checkbox bind:checked on:change={onUpdate}>{name}</Checkbox>
    {/each}
    <div>
      <Label for="mealPlanType" class="mb-2 ml-2">
        {translate("NEW_TYPE")}:
      </Label>
      <div class="flex gap-4">
        <Input
          id="mealPlanType"
          placeholder={translate("NEW_TYPE")}
          bind:value={ingredient}
        />
        <ButtonComponent on:click={onInsertIngredient} disabled={isInsertDisabled}>
          {translate("ADD")}
        </ButtonComponent>
      </div>
    </div>
    <div class="flex justify-center">
      <ButtonComponent on:click={onToggleMealPlanTypes}>
        {translate("CLOSE")}
      </ButtonComponent>
    </div>
  </div>
{:else}
  {#if ingredients.some((ingredient) => ingredient.checked)}
    <div class="flex flex-wrap gap-2 mb-4">
      {#each ingredients as { name, checked }}
        {#if checked}
          <Badge large rounded color="green">{name}</Badge>
        {/if}
      {/each}
    </div>
  {/if}
  <ButtonComponent on:click={onToggleMealPlanTypes}>
    {ingredients.some((ing) => ing.checked)
      ? translate("EDIT_TYPE")
      : translate("SET_TYPE")}
  </ButtonComponent>
{/if}
