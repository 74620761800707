<script lang="ts">
  import { isLoadingMore } from "../stores/isLoadingMoreStore";
  import MiniSpinner from "./UI/MiniSpinner.svelte";
  import Svg from "./UI/Svg.svelte";

  export let fetchMoreData: Function;
  export let isReverse: boolean = false;
</script>

<div class="w-full flex items-center justify-center">
  {#if $isLoadingMore}
    <MiniSpinner />
  {:else}
    <div
      role="button"
      tabindex="0"
      class="flex items-center justify-center h-9 w-9 bg-white dark:bg-zinc-800 rounded-xl border border-slate-500 dark:border-zinc-300"
      on:click={() => fetchMoreData()}
      on:keypress={() => fetchMoreData()}
    >
      <Svg
        name={isReverse ? "up-caret" : "down-caret"}
        size={22}
        customColor="bg-slate-500 dark:bg-zinc-300"
      />
    </div>
  {/if}
</div>
