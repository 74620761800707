<script lang="ts">
  import { onMount } from "svelte";
  import {
    Button,
    Heading,
    Helper,
    Input,
    Label,
    NumberInput,
    Select,
    Spinner,
    Textarea,
  } from "flowbite-svelte";
  import { api, postFormDataWithJwt, postWithJwt, translate } from "lib";
  import { dialogData, showAlert, trainerMealPlans } from "stores";
  import DatePicker from "../../../components/UI/DatePicker.svelte";
  import { ButtonComponent, InputField } from "ui";

  let data: any;

  let form = {
    measurement:  {value: "", error: ""},
    current:      {value: 0,  error: ""},
    startDate:    {value: "", error: ""},
    goal:         {value: 0,  error: ""},
    endDate:      {value: "", error: ""},
    description:  {value: "", error: ""},
  };

  let disabled = true;
  let isLoading = false;

  const onInput = (field: keyof typeof form): void => {
    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const createGoal = async (): Promise<void> => {
    try {
      await postWithJwt(`${api}/client/goals`, {
        "clientId": 194413,
        "measurementId": 418211,
        "goal": {
          "description": "lorem",
          "currentValue": 150,
          "goalValue": 156,
          "createdAt": "2023-10-18",
          "expireAt": "2023-10-19T22:00:00.000Z",
          "measurementName": "Biceps",
          "startingValue": 150
        },
        "createdAt": "2023-10-18",
        "expireAt": "2023-10-19T22:00:00.000Z"
      });
    } catch (error) {
    }
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    const formData = new FormData();
    let response: any;

    try {
      $dialogData.data = {};
      $dialogData.type = "";
      $showAlert.color = "black";
      $showAlert.message = `Created a new.`;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating.`;
    }
  };

  onMount((): void => {
  });

  export {data};
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {translate("SET_GOAL")}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault="{onSubmit}">

    <div>
      <Label for="measurement">{translate("MEASUREMENT")}:</Label>
      <Select
        id="measurement"
        placeholder="{translate("MEASUREMENT")}"
        items="{[
          {value: "Butina", name: "Butina"},
          {value: "Biceps", name: "Biceps"},
          {value: "Tezina", name: "Tezina"},
          {value: "Visina", name: "Visina"},
        ]}"
        bind:value="{form.measurement.value}"
        on:input="{() => onInput("measurement")}"/>
      <Helper color="red">
        {#if form.measurement.error}
          {form.measurement.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div class="flex gap-4">
      <div>
        <Label for="current">{translate("CURRENT")}:</Label>

        <NumberInput
          id="current"
          placeholder="{translate("CURRENT")}"
          bind:value="{form.current.value}"
          on:input="{() => onInput("current")}"/>
        <Helper color="red">
          {#if form.current.error}
            {form.current.error}
          {:else}
            ㅤ
          {/if}
        </Helper>
      </div>

      <!-- <DatePicker
        title="NOTIFICATION_DATE"
        bind:date="{form.startDate.value}"/> -->
      <InputField
        label={translate("NOTIFICATION_DATE")}
        type="date"
        error={form.startDate.error}
        bind:value={form.startDate.value}
        on:input={() => onInput("startDate")}
      />
    </div>

    <div class="flex gap-4">
      <div>
        <Label for="goal">{translate("GOAL")}:</Label>

        <NumberInput
          id="goal"
          placeholder="{translate("GOAL")}"
          bind:value="{form.goal.value}"
          on:input="{() => onInput("goal")}"/>
        <Helper color="red">
          {#if form.goal.error}
            {form.goal.error}
          {:else}
            ㅤ
          {/if}
        </Helper>
      </div>

      <!-- <DatePicker
        title="NOTIFICATION_DATE"
        bind:date="{form.endDate.value}"/> -->
      <InputField
        label={translate("NOTIFICATION_DATE")}
        type="date"
        error={form.endDate.error}
        bind:value={form.endDate.value}
        on:input={() => onInput("endDate")}
      />
    </div>

    <div>
      <Label for="description">{translate("DESCRIPTION")}:</Label>
      <Textarea
        id="description"
        placeholder={translate("DESCRIPTION")}
        rows="4"
        bind:value={form.description.value}
        on:input={() => onInput("description")}/>
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green"/>
      {:else}
        <ButtonComponent type="submit" {disabled}>{translate("CREATE")}</ButtonComponent>
      {/if}
    </div>

  </form>
</div>
