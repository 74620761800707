import {get} from "svelte/store";
import {user} from "stores";
import {getWithJwt} from "lib";
import type {Updater} from "svelte/store";
import type {ItemsStore} from "interfaces";

const loadCache = <Item, Store extends ItemsStore<Item>>(
  update: (this: void, updater: Updater<Store>) => void,
  cacheName: string
) => (): void => {
  const cache = localStorage.getItem(cacheName);

  if (cache) {
    const {items, count} = JSON.parse(cache);

    update((store) => {
      store.items = items;
      store.count = count;
      store.hasMore = store.items.length < store.count;

      return store;
    });
  }
};

const fetchData = <Item, Store extends ItemsStore<Item>>(
  update: (this: void, updater: Updater<Store>) => void,
  createUrl: () => string,
  cacheName: string
) => async (): Promise<void> => {
  if (!get(user)) {
    return;
  }

  update((store) => {
    store.isFetching = true;
    store.skip = 0;
    return store;
  });

  try {
    const response = await getWithJwt(createUrl());
    const {items, count} = response.data;

    update((store) => {
      store.items = items;
      store.count = count;
      store.hasMore = store.items.length < store.count;

      return store;
    });

    localStorage.setItem(cacheName, JSON.stringify({items, count}));
  } catch (error) {
    console.error(error);
  } finally {
    update((store) => {
      store.isFetching = false;
      return store;
    });
  }
};

const fetchMoreData = <Item, Store extends ItemsStore<Item>>(
  update: (this: void, updater: Updater<Store>) => void,
  createUrl: () => string,
  skip: number
) => async (): Promise<void> => {
  if (!get(user)) {
    return;
  }

  update((store) => {
    store.isFetchingMore = true;
    store.skip += skip;
    return store;
  });

  try {
    const response = await getWithJwt(createUrl());

    update((store) => {
      store.items.push(...response.data.items);
      store.isFetchingMore = false;
      return store;
    });
  } catch (error) {
    console.error(error);
  }
};

const replace = <Item, Store extends ItemsStore<Item>>(
  update: (this: void, updater: Updater<Store>) => void
) => (id: number, item: Item): void => {
  update((store) => {
    const {items} = store;
    const index = items.findIndex((item): boolean => item.id === id);

    if (index > -1) {
      store.items = items.with(index, item);
    }

    return store;
  });
};

const remove = <Item, Store extends ItemsStore<Item>>(
  update: (this: void, updater: Updater<Store>) => void
) => (ids: Array<number>): void => {
  update((store) => {
    store.items = store.items.filter(
      (item): boolean => !ids.includes(item.id)
    );

    store.count -= ids.length;
    store.skip -= ids.length;
    store.hasMore = store.items.length < store.count;

    return store;
  });
};

const add = <Item, Store extends ItemsStore<Item>>(
  update: (this: void, updater: Updater<Store>) => void
) => (items: Array<Item>, incrementCount = false): void => {
  update((store) => {
    if (incrementCount) {
      store.items.unshift(...items);

      store.count += items.length;
      store.skip += items.length;
      store.hasMore = store.items.length < store.count;
    } else {
      store.items.push(...items);
    }

    return store;
  });
};

const search = <Item, Store extends ItemsStore<Item>>(
  update: (this: void, updater: Updater<Store>) => void,
  fetchData: () => Promise<void>
) => (): void => {
  update((store) => {
    clearTimeout(store.searchTimeout);
    store.searchTimeout = setTimeout(fetchData, 1000);
    return store;
  });
};

const storeUtil = <Item, Store extends ItemsStore<Item>>(
  cacheName: string,
  update: (this: void, updater: Updater<Store>) => void,
  createUrl: () => string
) => {
  const fd = fetchData<Item, Store>(update, createUrl, cacheName);

  return {
    add: add<Item, Store>(update),
    replace: replace<Item, Store>(update),
    remove: remove<Item, Store>(update),
    fetchData: fd,
    fetchMoreData: fetchMoreData<Item, Store>(update, createUrl, 8),
    search: search<Item, Store>(update, fd),
    loadCache: loadCache<Item, Store>(update, cacheName)
  };
};

export {storeUtil};
