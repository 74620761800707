<script lang="ts">
  import { push } from "svelte-spa-router";
  import { parseDate } from "../lib/parseDate";
  import { currentClient, dialogData, selectedIds, showAlert } from "stores";
  import { dialogTypes, patchWithJwt, serverlessRoutes, translate } from "lib";
  import More from "./UI/More.svelte";
  import type { MenuItem } from "../interfaces/MenuItem";
  import { createEventDispatcher, onMount } from "svelte";
  import Tag from "./Tag.svelte";
  import {
    clientTagsMap,
    remindersMap,
    trainerClients,
  } from "../stores/trainerStores";

  import { CheckboxComponent, Svg } from "ui";

  export let client: any;
  export let isSelectMode = false;
  export let isInsideGroup = false;
  export let isInsideTrainers = false;

  let expireDate: Date;
  const today = new Date();
  const dispatch = createEventDispatcher();

  onMount(() => {
    expireDate = new Date(client.expireAt);
  });

  const undeleteClient = async () => {
    const res = await updateClient({
      id: client.id,
      deletedAt: null,
    });
    if (res.data.success) {
      $showAlert.message = translate("CLIENT_UNDELETED_SUCCESSFULLY");
      $trainerClients = $trainerClients.filter((c) => c.id !== client.id);
    } else {
      $showAlert.color = "red";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  let activeClientItems: MenuItem[] = [
    {
      title: "ASSIGN_MEAL_PLAN",
      icon: "utensils",
      executeFunction(): void {
        $dialogData.data = { client };
        $dialogData.type = dialogTypes.ASSIGN_MEAL_PLAN;
      },
    },
    {
      title: "ASSIGN_TRAINING_PROGRAM",
      icon: "dumbbell",
      executeFunction(): void {
        $dialogData.data = { client };
        $dialogData.type = dialogTypes.ASSIGN_WORKOUT_PLAN;
      },
    },
    {
      title: "TRANSFER_TO_ANOTHER_TRAINIER",
      icon: "switch",
      executeFunction(): void {
        $dialogData.data = { client };
        $dialogData.type = dialogTypes.TRANSFER_TO_ANOTHER_TRAINER;
      },
    },
    {
      title: "CREATE_NEW_PAYMENT",
      icon: "money",
      executeFunction(): void {
        $dialogData.data = {
          client,
          executeFunction(res: any): void {
            client.hash = res.hash;
            client.expireAt = res.expireAt;
          },
        };
        $dialogData.type = dialogTypes.CREATE_EDIT_PAYMENT;
      },
    },
    {
      title: "CREATE_NEW_REMINDER",
      icon: "calendar",
      executeFunction(): void {
        $dialogData.data = { clientId: client.id };
        $dialogData.type = dialogTypes.CREATE_NEW_REMINDER;
      },
    },
    {
      title: "CREATE_AUTO_MESSAGE",
      icon: "message",
      executeFunction(): void {
        $dialogData.data = {};
        $dialogData.type = dialogTypes.CREATE_AUTO_MESSAGE;
      },
    },
    {
      title: "SET_TAG",
      icon: "tag",
      executeFunction(): void {
        $currentClient = client;
        $dialogData.data = {};
        $dialogData.type = dialogTypes.SET_TAG;
      },
    },
    {
      title: "SET_GOAL",
      icon: "flag",
      executeFunction(): void {
        $dialogData.data = { client };
        $dialogData.type = dialogTypes.CREATE_EDIT_GOAL;
      },
    },
    {
      title: "EDIT",
      icon: "edit",
      executeFunction(): void {
        $dialogData.data = { client };
        $dialogData.type = dialogTypes.CREATE_EDIT_CLIENT;
      },
    },
    {
      title: "NOTIFICATIONS",
      icon: "bell",
      executeFunction(): void {
        // router push notifications?
      },
    },
    {
      title: "ARCHIVE",
      icon: "archive",
      executeFunction(): void {
        $dialogData.data = { client, executeFunction: archiveClient };
        $dialogData.type = dialogTypes.SEND_TO_ARCHIVE;
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          executeFunction: deleteClient,
          title: translate("CLIENT_A"),
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  let archivedClientItems: MenuItem[] = [
    {
      title: "RETURN_FROM_ARCHIVE",
      icon: "unarchive",
      executeFunction(): void {
        unarchive();
      },
    },
    {
      title: "DELETE",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          executeFunction: deleteClient,
          title: translate("CLIENT_A"),
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  let deletedClientItems: MenuItem[] = [
    {
      title: "RESTORE",
      icon: "restore",
      executeFunction: undeleteClient,
    },
  ];

  let menuItemsClient: MenuItem[];

  if (client.deletedAt) {
    menuItemsClient = deletedClientItems;
  } else if (client.archivedAt) {
    menuItemsClient = archivedClientItems;
  } else {
    menuItemsClient = activeClientItems;
  }

  let menuItemsGroup: MenuItem[] = [
    {
      title: "CHAT_WITH_CLIENT",
      icon: "message-outline",
      executeFunction(): void {
        push(`/chat/private/${client.id}`);
      },
    },
    {
      title: "DELETE_FROM_GROUP",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: "klijenta",
          async executeFunction(): Promise<void> {
            // const response = await putWithJwt(`${api}/client_group/20`, {
            // });
          },
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  let menuItemsTrainer: MenuItem[] = [
    {
      title: "DELETE_ACCOUNT",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: "trenera",
          async executeFunction(): Promise<void> {
            // const response = await putWithJwt(`${api}/client_group/20`, {
            // });
          },
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
    {
      title: "DELETE_FROM_GROUP",
      icon: "delete",
      executeFunction(): void {
        $dialogData.data = {
          title: "trenera",
          async executeFunction(): Promise<void> {
            // const response = await putWithJwt(`${api}/client_group/20`, {
            // });
          },
        };
        $dialogData.type = dialogTypes.CONFIRM_DELETE;
      },
    },
  ];

  const menuItems = isInsideGroup
    ? menuItemsGroup
    : isInsideTrainers
      ? menuItemsTrainer
      : menuItemsClient;

  const onNavigate = (): void => {
    if (isSelectMode) {
    } else {
      push(`/profile/${client.id}`);
    }
  };

  const onChange = () => {
    if ($selectedIds.includes(client.id)) {
      $selectedIds = $selectedIds.filter((id) => id !== client.id);
    } else {
      $selectedIds = [...$selectedIds, client.id];
    }

    dispatch("change");
  };

  const updateClient = async (user) => {
    const url = serverlessRoutes.FETCH_USER;
    try {
      const res = await patchWithJwt(url, { user });
      return res;
    } catch (error) {
      console.error(error);
      return { data: { success: false } };
    }
  };

  const archiveClient = async () => {
    const res = await updateClient({
      id: client.id,
      archivedAt: new Date().toISOString().slice(0, 10),
    });
    if (res.data.success) {
      $showAlert.message = translate("CLIENT_ARCHIVED_SUCCESSFULLY");
      $trainerClients = $trainerClients.filter((c) => c.id !== client.id);
    } else {
      $showAlert.color = "red";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  const unarchive = async () => {
    const res = await updateClient({
      id: client.id,
      archivedAt: null,
    });
    if (res.data.success) {
      $showAlert.message = translate("CLIENT_UNARCHIVED_SUCCESSFULLY");
      $trainerClients = $trainerClients.filter((c) => c.id !== client.id);
    } else {
      $showAlert.color = "red";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };

  const deleteClient = async () => {
    const res = await updateClient({
      id: client.id,
      deletedAt: new Date().toISOString().slice(0, 10),
    });
    if (res.data.success) {
      $showAlert.message = translate("CLIENT_DELETED_SUCCESSFULLY");
      $trainerClients = $trainerClients.filter((c) => c.id !== client.id);
    } else {
      $showAlert.color = "red";
      $showAlert.message = translate("SOMETHING_WENT_WRONG");
    }
  };
</script>

<div
  class={`
    flex
    items-center
    justify-between
    border
    rounded-md
    border-slate-200
    dark:border-zinc-600
    ${client.deletedAt ? "bg-red-100 dark:bg-zinc-900" : (client.expireAt && expireDate < today) || client.archivedAt ? "bg-slate-100 dark:bg-zinc-700" : ""}
  `}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="flex" on:click={onNavigate}>
    <img
      class="m-4 h-14 w-14 rounded-full"
      src={client.imageUrl ? client.imageUrl : "/default-avatar.png"}
      alt="User Avatar"
    />

    <div class="flex flex-col justify-center">
      <div class="text-sm">{client.name}</div>
      <div class="text-xs">{client.email}</div>
      <div class="text-xxs text-gray-400">
        {translate("REGISTERED")}: {parseDate(client.createdAt)}
      </div>
    </div>
  </div>
  <div class="m-4">
    {#if isSelectMode}
      <CheckboxComponent
        value={$selectedIds.includes(client.id)}
        on:change={onChange}
      />
    {:else}
      <div class="flex flex-row">
        {#if $clientTagsMap[client.id] && $clientTagsMap[client.id].length > 0}
          {#each $clientTagsMap[client.id].slice(0, 4) as tag}
            <span class="tag">
              <Tag color={tag.color} />
            </span>
          {/each}
        {/if}
        {#if $remindersMap[client.id] && $remindersMap[client.id].length > 0}
          <span class="tag">
            <Svg name="reminder" size={23} />
          </span>
        {/if}
      </div>
      <div class="flex flex-row justify-end">
        {#if client.deletedAt}
          <span
            style="margin-top: -17px; margin-right: -6px;"
            class="absolute text-xxs flex flex-row items-center"
          >
            {translate("ACCOUNT_DELETED")}
            <Svg myClass="ml-1" name="warning" size={16} />
          </span>
        {:else if (client.expireAt && expireDate < today) || client.archivedAt}
          <span
            style="margin-top: -17px; margin-right: -6px;"
            class="absolute text-xxs flex flex-row items-center"
          >
            {client.archivedAt
              ? translate("ACCOUNT_ARCHIVED")
              : translate("ACCOUNT_EXPIRED")}
            <Svg myClass="ml-1" name="warning" size={16} />
          </span>
        {/if}
        <div class="w-fit">
          <More {menuItems} />
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .tag {
    position: relative;
    top: -24px;
    right: -12px;
  }
</style>
