import { wrap } from "svelte-spa-router/wrap";
import { Roles } from "./constants";

const userGuard = () => {
  if (localStorage.getItem("authJwt")) return true;
  return false;
};

const trainerGuard = () => {
  const role = localStorage.getItem("role");
  return +role === Roles.Trainer;
};

const clientGuard = () => {
  const role = localStorage.getItem("role");
  return +role === Roles.Client;
};

const routes = {
  "/": wrap({
    asyncComponent: () =>
      import("../screens/Training/ClientTrainingScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/auth": wrap({
    asyncComponent: () => import("../screens/AuthScreen.svelte"),
  }),
  "/autologin/:hash": wrap({
    asyncComponent: () => import("../components/Autologin.svelte"),
  }),
  "/clients/list": wrap({
    asyncComponent: () => import("../screens/Clients/ClientListScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/groups": wrap({
    asyncComponent: () =>
      import("../screens/Clients/ClientGroupsScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/groups/:id": wrap({
    asyncComponent: () => import("../screens/Clients/ClientGroupScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/payments": wrap({
    asyncComponent: () =>
      import("../screens/Clients/ClientPaymentsScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/clients/trainers": wrap({
    asyncComponent: () => import("../screens/Clients/TrainersScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/progress/habits": wrap({
    asyncComponent: () => import("../screens/Progress/HabitsScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/progress/goals": wrap({
    asyncComponent: () => import("../screens/Progress/GoalsScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/progress/measurements": wrap({
    asyncComponent: () =>
      import("../screens/Progress/MeasurementsScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/progress/gallery": wrap({
    asyncComponent: () => import("../screens/Progress/GalleryScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/diet/meal-plan": wrap({
    asyncComponent: () => import("../screens/Diet/ClientDietScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/diet/journal": wrap({
    asyncComponent: () => import("../screens/Diet/DietJournalScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/diet/fasting": wrap({
    asyncComponent: () => import("../screens/Diet/FastingScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/diet/meal-plans": wrap({
    asyncComponent: () => import("../screens/Diet/MealPlansScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/diet/meal-plans/:id": wrap({
    asyncComponent: () => import("../screens/Diet/MealPlanScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/diet/recipes": wrap({
    asyncComponent: () => import("../screens/Diet/RecipesScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/diet/ingredients": wrap({
    asyncComponent: () => import("../screens/Diet/IngredientsScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/program": wrap({
    asyncComponent: () =>
      import("../screens/Training/ClientTrainingScreen.svelte"),
    conditions: [(detail) => clientGuard()],
  }),
  "/training/programs": wrap({
    asyncComponent: () => import("../screens/Training/ProgramsScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/programs/:id": wrap({
    asyncComponent: () => import("../screens/Training/ProgramScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/workouts": wrap({
    asyncComponent: () => import("../screens/Training/WorkoutsScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/exercises": wrap({
    asyncComponent: () => import("../screens/Training/ExercisesScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/training/journal": wrap({
    asyncComponent: () =>
      import("../screens/Training/TrainingJournalScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/chat": wrap({
    asyncComponent: () => import("../screens/Chat/ChatScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/chat/shared/:sessionId": wrap({
    asyncComponent: () =>
      import("../screens/Chat/ChatSharedContentScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/chat-list": wrap({
    asyncComponent: () => import("../screens/Chat/ChatListScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/profile/:id": wrap({
    asyncComponent: () => import("../screens/Clients/ProfileScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/profile/:clientId/training/programs/:id": wrap({
    asyncComponent: () => import("../screens/Training/ProgramScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/profile/:clientId/diet/meal-plans/:id": wrap({
    asyncComponent: () => import("../screens/Diet/MealPlanScreen.svelte"),
    conditions: [(detail) => userGuard(), (detail) => trainerGuard()],
  }),
  "/notifications": wrap({
    asyncComponent: () =>
      import("../screens/Notifications/NotificationsScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/notifications/expiry": wrap({
    asyncComponent: () =>
      import("../screens/Notifications/ExpiryScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/notifications/reminders": wrap({
    asyncComponent: () =>
      import("../screens/Notifications/RemindersScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/settings": wrap({
    asyncComponent: () => import("../screens/Settings/SettingsScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/settings/profile": wrap({
    asyncComponent: () =>
      import("../screens/Settings/SettingsProfileScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/settings/advanced-settings": wrap({
    asyncComponent: () =>
      import("../screens/Settings/AdvancedSettingsScreen/AdvancedSettingsScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  "/settings/bug-suggestion": wrap({
    asyncComponent: () =>
      import("../screens/Settings/BugSuggestionScreen.svelte"),
    conditions: [(detail) => userGuard()],
  }),
  // // Using named parameters, with last being optional
  // '/author/:first/:last?': Author,

  // // Wildcard parameter
  // '/book/*': Book,

  // // Catch-all
  // // This is optional, but if present it must be the last
  // '*': NotFound,
};

export { routes };
