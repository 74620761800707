<script lang="ts">
  import { onMount } from "svelte";
  import { translate } from "lib";
  import MacroBarComponent from "./MacroBar.svelte";

  interface MacroPercentages {
    carbs: string;
    protein: string;
    fats: string;
  }

  let carbs: number;
  let protein: number;
  let fats: number;
  let calories: number;
  let isBarVisible = false;
  let isCaloriesVisible = false;

  let percentages: MacroPercentages = {
    carbs: "0",
    protein: "0",
    fats: "0",
  };

  const calculatePercentages = (): MacroPercentages => {
    if (calories === 0) {
      return {
        carbs: "0",
        protein: "0",
        fats: "0",
      };
    }

    return {
      carbs: (((carbs * 4) / calories) * 100).toFixed(),
      protein: (((protein * 4) / calories) * 100).toFixed(),
      fats: (((fats * 9) / calories) * 100).toFixed(),
    };
  };

  onMount((): void => {
    percentages = calculatePercentages();
  });

  export { carbs, protein, fats, calories, isBarVisible, isCaloriesVisible };
</script>

<div>
  <div
    class="
      flex
      items-center
      justify-evenly
      text-center
      text-xs
      text-slate-900
      dark:text-slate-100
    ">
    <div>
      <div>{translate("CARBS")}</div>
      <div class="font-bold text-green-400">
        {carbs ? carbs.toFixed(1) : 0} ({percentages.carbs !== "NaN" ? percentages.carbs : 0}%)
      </div>
    </div>
    <div>
      <div>{translate("PROTEIN")}</div>
      <div class="font-bold text-sky-400">
        {protein ? protein.toFixed(1) : 0} ({percentages.protein !== "NaN" ? percentages.protein : 0}%)
      </div>
    </div>
    <div>
      <div>{translate("FATS")}</div>
      <div class="font-bold text-red-400">
        {fats ? fats.toFixed(1) : 0} ({percentages.fats !== "NaN" ? percentages.fats : 0}%)
      </div>
    </div>
    {#if isCaloriesVisible}
      <div>
        <div>{translate("CALORIES")}</div>
        <div class="font-bold text-amber-400">
          {calories ? calories.toFixed(1) : 0}
        </div>
      </div>
    {/if}
  </div>
  {#if isBarVisible}
    <MacroBarComponent
      carbs="{parseInt(percentages.carbs)}"
      protein="{parseInt(percentages.protein)}"
      fats="{parseInt(percentages.fats)}"/>
  {/if}
</div>
