<script lang="ts">
  import {translate} from "lib";
  import {createEventDispatcher, onMount} from "svelte";

  let carbs = 33;
  let protein = 34;
  let fats = 33;

  let fromSlider: HTMLInputElement;
  let toSlider: HTMLInputElement;

  const dispatch = createEventDispatcher();

  const controlFromSlider = (): void => {
    const [from, to] = getParsed(fromSlider, toSlider);

    fillSlider(fromSlider, toSlider, "#C6C6C6", "#25daa5", toSlider);

    if (from > to) {
      carbs = to;
      protein = 0;
      fromSlider.value = to.toString();
    } else {
      carbs = from;
      protein = to - from;
    }

    dispatch("values", {carbs, protein, fats});
  };

  const controlToSlider = (): void => {
    const [from, to] = getParsed(fromSlider, toSlider);

    fillSlider(fromSlider, toSlider, '#C6C6C6', '#25daa5', toSlider);
    setToggleAccessible(toSlider);

    if (from <= to) {
      fats = 100 - to;
      protein = to - from;
      toSlider.value = to.toString();
    } else {
      fats = 100 - from;
      protein = 0;
      toSlider.value = from.toString();
    }

    dispatch("values", {carbs, protein, fats});
  };

  function getParsed(currentFrom, currentTo) {
    const from = parseInt(currentFrom.value, 10);
    const to = parseInt(currentTo.value, 10);
    return [from, to];
  }

  function fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
    const rangeDistance = to.max - to.min;
    const fromPosition = from.value - to.min;
    const toPosition = to.value - to.min;

    controlSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition) / (rangeDistance) * 100}%,
      ${rangeColor} ${((fromPosition) / (rangeDistance)) * 100}%,
      ${rangeColor} ${(toPosition) / (rangeDistance) * 100}%,
      ${sliderColor} ${(toPosition) / (rangeDistance) * 100}%,
      ${sliderColor} 100%)`;
  }

  function setToggleAccessible(currentTarget) {
    const toSlider = document.querySelector('#toSlider') as HTMLInputElement;
    if (Number(currentTarget.value) <= 0 ) {
      toSlider.style.zIndex = "2";
    } else {
      toSlider.style.zIndex = "0";
    }
  }

  onMount((): void => {
    fillSlider(fromSlider, toSlider, "#C6C6C6", "#319795", toSlider);
    setToggleAccessible(toSlider);
  });
</script>

<style>
  input[type=range]::-webkit-slider-thumb {
    appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;
  }

  input[type=range]::-moz-range-thumb {
    appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #C6C6C6;
    cursor: pointer;
  }

  input[type=range]::-webkit-slider-thumb:hover {
    background: #f7f7f7;
  }

  input[type=range]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  }

  input[type="range"] {
    appearance: none;
    position: absolute;
    top: 50%;
    height: 4px;
    width: 100%;
    background-color: #C6C6C6;
    pointer-events: none;
    transform: translateY(-50%);
  }

  #fromSlider {
    height: 0;
    z-index: 1;
  }

  .slider {height: 24px;}
</style>

<div class="p-2 flex flex-col gap-2 border-b">
  <div class="slider relative">
    <input id="fromSlider" type="range" value="33" min="0" max="100" bind:this={fromSlider} on:input={controlFromSlider}/>
    <input id="toSlider" type="range" value="66" min="0" max="100" bind:this={toSlider} on:input={controlToSlider}/>
  </div>
  <div class="flex justify-evenly text-center">
    <div>
      <div class="text-xs">{translate("CARBS")}</div>
      <div class="font-bold text-xs text-green-400">{carbs}%</div>
    </div>
    <div>
      <div class="text-xs">{translate("PROTEIN")}</div>
      <div class="font-bold text-xs text-sky-400">{protein}%</div>
    </div>
    <div>
      <div class="text-xs">{translate("FATS")}</div>
      <div class="font-bold text-xs text-red-400">{fats}%</div>
    </div>
  </div>
</div>
