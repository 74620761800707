<script lang="ts">
  import {onDestroy, onMount} from "svelte";
  import {ButtonComponent, InfiniteScroll, Search} from "ui"
  import {selectedIds, trainerIngredientsStore} from "stores";
  import {translate} from "lib";
  import Ingredient from "../../Diet/Ingredient.svelte";
  import IngredientsFilter from "../../../screens/Diet/IngredientsFilter.svelte";

  let data: any;
  let step = 1;
  let isLoading = false;
  let scrollElement: HTMLDivElement;
  let selektedIngredients: Array<any> = [];

  const unsubscribeSelectedIds = selectedIds.subscribe((store) => {
    selektedIngredients = $trainerIngredientsStore.items.filter(
      (item): boolean => store.includes(item.id)
    );
  });

  $: selectedIngredients = $trainerIngredientsStore.items.filter(
    (ingredient: any): boolean => $selectedIds.includes(ingredient.id)
  );

  const mountScrollElement = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  const onStepDown = (): void => {
    step -= 1;
  };

  const onStepUp = (): void => {
    step += 1;
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;
    console.log(selektedIngredients);
    await data.executeFunction(selektedIngredients);
    isLoading = false;
  };

  onDestroy(unsubscribeSelectedIds);

  onMount(async (): Promise<void> => {
    $selectedIds = [];
    await trainerIngredientsStore.fetchData();
  });

  export {data};
</script>

<div class="p-4 flex-col gap-4">
  <h3 class="text-xxl font-semibold text-center">
    {translate("IMPORT_INGREDIENTS")}
  </h3>

  {#if step === 1}
    <div>1. {translate("SELECT_INGREDIENTS")}</div>

    <Search
      placeholder={translate("SEARCH_INGREDIENTS")}
      bind:value={$trainerIngredientsStore.filter.search}
      on:input={trainerIngredientsStore.search}/>

    <IngredientsFilter/>

    <div class="flex-col gap-4 h-64 overflow-y-scroll" use:mountScrollElement>
      {#each $trainerIngredientsStore.items as ingredient}
        <Ingredient {ingredient} selectMode/>
      {/each}

      <InfiniteScroll
        {scrollElement}
        hasMore={$trainerIngredientsStore.hasMore}
        isFetchingMore={$trainerIngredientsStore.isFetchingMore}
        on:loadMore={trainerIngredientsStore.fetchMoreData}/>
    </div>

    <div class="flex justify-center">
      <ButtonComponent disabled={$selectedIds.length === 0} on:click={onStepUp}>
        {translate("NEXT_STEP")}
      </ButtonComponent>
    </div>
  {:else}
    <div>2. {translate("SET_AMOUNT")}</div>

    <div class="flex-col gap-4">
      {#each selektedIngredients as ingredient}
        <Ingredient {ingredient} isModifiable2 />
      {/each}
    </div>

    <div class="flex justify-center gap-4">
      <ButtonComponent on:click={onStepDown} >
        {translate("PREVIOUS_STEP")}
      </ButtonComponent>
      <ButtonComponent {isLoading} on:click={onSubmit}>
        {translate("ADD")}
      </ButtonComponent>
    </div>
  {/if}
</div>
