<script lang="ts">
  import { onMount } from "svelte";
  import { ButtonComponent, Input, Search, TextareaField } from "ui";
  import {
    trainerRecipes,
  } from "../../../stores/trainerStores";
  import { api, serverlessRoutes, translate, getWithJwt, postFormDataWithJwt, isClient } from "lib";
  import { currentClient, dialogData, showAlert, trainerIngredientsStore, user } from "stores";
  // import FilterComponent from "../../Filter.svelte";
  import MacrosComponent from "../../Diet/Macros.svelte";
  import SetEditRecipeTypeComponent from "../../Diet/SetEditRecipeType.svelte";
  import Ingredient from "../../Diet/Ingredient.svelte";
  import InfiniteScroll from "../../../components/UI/InfiniteScroll.svelte";
  // import { clientJournalMeals } from "../../../stores/clientStores";
  import IngredientsFilter from "../../../screens/Diet/IngredientsFilter.svelte";

  // let searchIngredient = "";
  let ingredientsElement: HTMLDivElement;
  // let searchValue = "";

  const form = {
    name: {
      value: "",
      error: "",
    },
    // ingredient: {
    //   value: {} as any,
    //   error: ""
    // },
    // amount: {
    //   value: 0,
    //   error: ""
    // },
    foodType: {
      value: [],
      error: "",
    },
    directions: {
      value: "",
      error: "",
    },
  };

  const addIngredientForm = {
    ingredient: {
      value: {} as any,
      error: "",
    },
    amount: {
      value: 0,
      error: "",
    },
  };

  let addIngredientFormDisabled = true;

  const recipeMacroStats = {
    protein: 0,
    carbs: 0,
    fats: 0,
    calories: 0,
  };

  let data;
  let newIngredients: Array<any> = [];
  let disabled = true;

  let imageElem: HTMLImageElement;
  let imageInputElem: HTMLInputElement;

  let image: File;
  let isImageSelected = false;

  let isAddIngredientsVisible = false;
  let toDelete: Array<any> = [];

  const btnTitle = data.recipe ? translate("EDIT") : translate("CREATE");

  const isFormInvalid = (): void => {
    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const isAddIngredientFormInvalid = (): void => {
    addIngredientFormDisabled = Object.values(addIngredientForm).some(
      (field) => field.error !== ""
    );
  };

  const onMealPlanTypes = (event: CustomEvent): void => {
    form.foodType.value = event.detail;
  };

  const onSetImage = (event: Event): void => {
    const target = event.target as HTMLInputElement;

    if (target.files) {
      const [file] = target.files;

      image = file;
      imageElem.src = URL.createObjectURL(file);
      isImageSelected = true;
    }
  };

  const onAddIngredient = (): void => {
    newIngredients = [
      ...newIngredients,
      {
        ...addIngredientForm.ingredient.value,
        amount: addIngredientForm.amount.value || 0,
      },
    ];
    setRecipeMacroStats();
  };

  const onToggleIngredients = (): void => {
    isAddIngredientsVisible = !isAddIngredientsVisible;
  };

  const onChangeIngredient = (): void => {
    addIngredientForm.amount.value = addIngredientForm.ingredient.value.amount;
    onInput2("amount");
  };

  const setRecipeMacroStats = (): void => {
    recipeMacroStats.carbs = newIngredients.reduce((acc, ingredient) => {
      if (toDelete.includes(ingredient.id)) {
        return acc;
      } else {
        const amount = (ingredient.originalCarbs / ingredient.originalAmount) *
          ingredient.amount;

        if (!isNaN(amount)) {
          return acc += amount;
        } else { return acc; }
      }
    }, 0);

    recipeMacroStats.protein = newIngredients.reduce((acc, ingredient) => {
      if (toDelete.includes(ingredient.id)) {
        return acc;
      } else {
        const amount = (ingredient.originalProtein / ingredient.originalAmount) *
          ingredient.amount;

        if (!isNaN(amount)) {
          return acc += amount;
        } else { return acc; }
      }
    }, 0);

    recipeMacroStats.fats = newIngredients.reduce((acc, ingredient) => {
      if (toDelete.includes(ingredient.id)) {
        return acc;
      } else {
        const amount = (ingredient.originalFats / ingredient.originalAmount) *
          ingredient.amount;

        if (!isNaN(amount)) {
          return acc += amount;
        } else { return acc; }
      }
    }, 0);

    recipeMacroStats.calories = newIngredients.reduce((acc, ingredient) => {
      if (toDelete.includes(ingredient.id)) {
        return acc;
      } else {
        const amount = (ingredient.originalCalories / ingredient.originalAmount) *
          ingredient.amount;

        if (!isNaN(amount)) {
          return acc += amount;
        } else { return acc; }
      }
    }, 0);
  };

  const trash = (event: CustomEvent): void => {
    if (toDelete.includes(event.detail)) {
      toDelete = toDelete.filter((id) => id !== event.detail);
    } else {
      toDelete = [...toDelete, event.detail];
    }

    setRecipeMacroStats();
  };

  const onInput = (formFieldName: string): void => {
    if (formFieldName === "name") {
      if (form[formFieldName].value.length === 0) {
        form[formFieldName].error = "FIELD_REQUIRED";
      } else if (form[formFieldName].value.length < 2) {
        form[formFieldName].error = "FIELD_MINIMUM_2";
      } else if (form[formFieldName].value.length > 320) {
        form[formFieldName].error = "FIELD_MAXIMUM_320";
      } else {
        form[formFieldName].error = "";
      }
    }

    if (formFieldName === "directions") {
      if (form[formFieldName].value.length > 5000) {
        form[formFieldName].error = "FIELD_MAXIMUM_5000";
      } else {
        form[formFieldName].error = "";
      }
    }

    isFormInvalid();
  };

  const onInput2 = (formFieldName: string) => {
    if (formFieldName === "amount") {
      if (addIngredientForm[formFieldName].value < 0) {
        addIngredientForm[formFieldName].error = "Najmanje 0";
      } else if (addIngredientForm[formFieldName].value > 1000) {
        addIngredientForm[formFieldName].error = "Najvise 1000";
      } else {
        addIngredientForm[formFieldName].error = "";
      }
    }

    isAddIngredientFormInvalid();
  };

  const editRecipe = async (): Promise<void> => {
    const formData = new FormData();

    formData.append("recipe", JSON.stringify({
      name: form.name.value,
      foodType: form.foodType.value,
      description: form.directions.value,
    }));

    formData.append("ownerType", "USER");

    if (image) {
      formData.append("thumbnail", image);
    }

    formData.append("toDelete", JSON.stringify(
      toDelete.filter((id) => {
        const ingredient = data.recipe.ingredients.find(
          (ingredient: any) => ingredient.id === id
        );
        return ingredient ? true : false;
      })
    ));

    formData.append("toAdd", JSON.stringify(
      newIngredients
        .map(({ id, amount }) => ({ id, amount }))
        .filter((ingredient) => {
          const ex = data.recipe.ingredients.find(
            (i) => i.id === ingredient.id
          );
          return ex ? false : true;
        })
    ));

    formData.append("toEdit", JSON.stringify(
      newIngredients
        .map(({ id, amount }) => ({ id, amount }))
        .filter((ingredient) => {
          const ex = data.recipe.ingredients.find(
            (i) => i.id === ingredient.id
          );
          return ex && !toDelete.includes(ex.id) ? true : false;
        })
    ));

    const response = await postFormDataWithJwt(
      `${api}/recipe/${data.recipe.id}`,
      formData,
      "PUT"
    );

    // ovo da se obrise kad se izmenja bekend
    response.imageUrl = response.thumbnailUrl;

    if (isClient($user)) {
      data.executeFunction(response);
    } else {
      if ($currentClient.id) {
        data.executeFunction(response);
      } else {
        const recipe = $trainerRecipes.find((r) => r.id === data.recipe.id);
        const recipeIndex = $trainerRecipes.indexOf(recipe);
        $trainerRecipes = $trainerRecipes.with(recipeIndex, response);
      }
    }

    $showAlert.color = "black";
    $showAlert.message = `
      ${translate("SUCCESSFULLY_EDITED")}
      ${translate("RECIPE").toLowerCase()}
    `;

    $dialogData.type = "";
    $dialogData.data = {};
  };

  const createRecipe = async (): Promise<void> => {
    const formData = new FormData();

    formData.append("recipe", JSON.stringify({
      name: form.name.value,
      foodType: form.foodType.value,
      description: form.directions.value,
    }));

    formData.append("ownerType", "USER");

    formData.append("toAdd", JSON.stringify(
      newIngredients
        .map(({ id, amount }) => ({ id, amount }))
        .filter(({ id }) => !toDelete.includes(id))
    ));

    formData.append("thumbnail", image);

    try {
      const response = await postFormDataWithJwt(
        `${api}/recipe`,
        formData,
        "POST"
      );

      $trainerRecipes = [...$trainerRecipes, response];
    } catch (error) {
      console.error(error);
    } finally {
      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("SUCCESSFULLY_CREATED")}
        ${translate("RECIPE").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    }
  };

  const onSubmit = async (): Promise<void> => {
    if (data.recipe) {
      await editRecipe();
    } else {
      await createRecipe()
    }
  };

  const fetchRecipeIngredients = async (id: any) => {
    const url = `${serverlessRoutes.RECIPE}/${id}/ingredients`;
    try {
      const response = await getWithJwt(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  };

  onMount(async (): Promise<void> => {
    if (data.recipe) {
      const { id, name, imageUrl, foodType, description, ingredients } = data.recipe;

      form.name.value = name;
      onInput("name");
      form.foodType.value = foodType;
      form.directions.value = description;
      onInput("directions");

      setTimeout(() => {
        imageElem.src = imageUrl;
        isImageSelected = true;
      }, 1);

      if (ingredients) {
        newIngredients = ingredients;
      } else {
        data.recipe.ingredients = await fetchRecipeIngredients(id);
        newIngredients = data.recipe.ingredients;
      }

      setRecipeMacroStats();
    }

    await trainerIngredientsStore.fetchData();
  });

  // let page = 0;
  // let isLoading = false;

  // const createUrl = (): string => {
  //   let template = "";
  //   let macroRatio = "";
  //   let foodType = "";
  //   let name = "";

  //   if ($filtersStore2.template === 1) {
  //     template = "&default=1&template=1";
  //   } else {
  //     template = "&default=1&template=0";
  //   }

  //   if ($filtersStore2.macroRatio) {
  //     const { carbs, protein, fats } = $filtersStore2.macroRatio;
  //     macroRatio = `&macroRatio=%5B${carbs},${protein},${fats}%5D`;
  //   }

  //   if ($filtersStore2.tags.length) {
  //     foodType = `&foodType=%5B${$filtersStore2.tags
  //       .map((filter): string => `%22${filter}%22`)
  //       .join(",")}%5D`;
  //   }

  //   if (searchValue) {
  //     name = `&name=${searchValue}`;
  //   }

  //   return `${serverlessRoutes.INGREDIENT}/list?take=8&skip=${
  //     page * 8
  //   }${template}${macroRatio}${foodType}${name}`;
  // };

  // const onFetchData = async (): Promise<void> => {
  //   if (!$user) {
  //     return;
  //   }

  //   isLoading = true;
  //   page = 0;

  //   try {
  //     const response = await getWithJwt(createUrl());
  //     $trainerIngredients = response.data.data;
  //     $trainerIngredientsCount = response.data.count;
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     isLoading = false;
  //   }
  // };

  // const onLoadMore = async (): Promise<void> => {
  //   if (!$user) {
  //     return;
  //   }

  //   isLoading = true;
  //   page += 1;

  //   try {
  //     const response = await getWithJwt(createUrl());
  //     $trainerIngredients = [...$trainerIngredients, ...response.data.data];
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     isLoading = false;
  //   }
  // };

  // let timeout: NodeJS.Timeout;

  // const onSearch = (): void => {
  //   clearTimeout(timeout);
  //   timeout = setTimeout(async (): Promise<void> => {
  //     onFetchData();
  //   }, 1000);
  // };

  // const fetchIngredients = async (): Promise<void> => {
  //   if (!$user) {
  //     return;
  //   }
  //   // isLoading = true;
  //   try {
  //     const response = await getWithJwt(createUrl());
  //     $trainerIngredients = response.data;
  //     $trainerIngredientsCount = response.data.count;
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   // isLoading = false;
  // };

  const onIngredientsMount = (node: HTMLDivElement): void => {
    ingredientsElement = node;
  };

  export { data };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div class="p-4">
  <h3 class="text-xxl font-semibold text-center">
    {translate(data.recipe ? "EDIT" : "CREATE")}
    {translate("RECIPE")}
  </h3>

  <form class="flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <Input
      label="NAME"
      name="name"
      placeholder={translate("NAME")}
      bind:value={form.name.value}
      {onInput}
    />
    {#if form.name.error}
      <span class="text-red-500 text-xs">{translate(form.name.error)}</span>
    {/if}

    <div class="flex flex-col items-center justify-center mt-2">

      <img
        class="cursor-pointer"
        src={data.recipe ? data.recipe.thumbnailUrl : "logo.png"}
        alt="Recipe"
        bind:this={imageElem}
        on:click={() => imageInputElem.click()}
      />

      <input
        class="hidden"
        type="file"
        accept=".png"
        bind:this={imageInputElem}
        on:change={onSetImage}
      />

      <p class="text-xs text-slate-300 text-center mt-2">
        {#if !data.executeFunction && !isImageSelected}
          {translate("DEFAULT_IMAGE_LOADED_AUTOMATICALLY")}<br />
        {/if}
        {translate("CLICK_ON_IMAGE_TO_CHANGE")}
      </p>
    </div>

    <div>
      <p>{translate("RECIPE_MACRONUTRIENT_STATS")}:</p>
      <div class="p-2 border border-neutral-200 rounded-md shadow-md mt-2">
        <MacrosComponent
          carbs={recipeMacroStats.carbs}
          protein={recipeMacroStats.protein}
          fats={recipeMacroStats.fats}
          calories={recipeMacroStats.calories}
          isCaloriesVisible
        />
      </div>
    </div>

    <div>
      <p>{translate("INGREDIENTS")}:</p>

      <div class="flex-col gap-4 my-2">
        {#each newIngredients as ingredient (ingredient.id)}
          <Ingredient
            {ingredient}
            isModifiable
            isForDelete={toDelete.includes(ingredient.id)}
            on:ingredientAmount={setRecipeMacroStats}
            on:ingredientTrash={trash}
          />
        {/each}
      </div>

      {#if isAddIngredientsVisible}
        <div class="p-4 flex-col gap-4 border dark:border-zinc-600 rounded-md">
          <Search
            placeholder={translate("SEARCH_INGREDIENTS")}
            bind:value={$trainerIngredientsStore.filter.search}
            on:input={trainerIngredientsStore.search}
          />

          <IngredientsFilter/>

          <div>
            <div
              class="flex flex-col gap-2 h-48 overflow-y-scroll"
              use:onIngredientsMount
            >
              {#each $trainerIngredientsStore.items as ingredient}
                <Ingredient
                  {ingredient}
                  isAddMode
                  on:add={(event) => {
                    if (!newIngredients.find((i) => i.id === event.detail.id)) {
                      newIngredients = [...newIngredients, event.detail];
                      setRecipeMacroStats();
                    }
                  }}
                />
              {/each}
              <InfiniteScroll
                isFetchingMore={$trainerIngredientsStore.isFetchingMore}
                hasMore={$trainerIngredientsStore.hasMore}
                scrollElement={ingredientsElement}
                on:loadMore={trainerIngredientsStore.fetchMoreData}
              />
            </div>
          </div>

          <!-- {#if addIngredientForm.ingredient.value.id}
            <MacrosComponent
              carbs={(addIngredientForm.ingredient.value.originalCarbs /
                addIngredientForm.ingredient.value.originalAmount) *
                addIngredientForm.amount.value}
              protein={(addIngredientForm.ingredient.value.originalProtein /
                addIngredientForm.ingredient.value.originalAmount) *
                addIngredientForm.amount.value}
              fats={(addIngredientForm.ingredient.value.originalFats /
                addIngredientForm.ingredient.value.originalAmount) *
                addIngredientForm.amount.value}
              calories={(addIngredientForm.ingredient.value.originalCalories /
                addIngredientForm.ingredient.value.originalAmount) *
                addIngredientForm.amount.value}
              isCaloriesVisible
            />
            <p>{translate("ADD_EDIT_RECIPE_NOTE")}</p>
            <div>
              <p>
                {translate("AMOUNT")}:
              </p>
              <NumberInput
                id="ingredientAmount"
                placeholder={translate("AMOUNT")}
                bind:value={addIngredientForm.amount.value}
                on:input={() => onInput2("amount")}
              />
              <InputAddon
                >{translate(
                  addIngredientForm.ingredient.value.measurementUnit
                ).toLowerCase()}</InputAddon
              >
              {#if form.amount.error}
                <span class="text-red-500 text-xs">{form.amount.error}</span>
              {/if}
            </div>
          {/if} -->

          <div class="flex justify-center">
            <ButtonComponent on:click={onToggleIngredients}>
              {translate("CLOSE")}
            </ButtonComponent>
          </div>
        </div>
      {:else}
        <ButtonComponent on:click={onToggleIngredients}>
          {translate("ADD_INGREDIENT")}
        </ButtonComponent>
      {/if}
    </div>

    <div>
      <p class="mb-2">{translate("RECIPE_TYPE")}:</p>
      <SetEditRecipeTypeComponent
        selected={data.recipe ? data.recipe.foodType : []}
        on:mealPlanTypes={onMealPlanTypes}
      />
    </div>

    <TextareaField
      label={translate("DIRECTIONS")}
      error={translate(form.directions.error)}
      bind:value={form.directions.value}
      on:input={() => onInput("directions")}
    />

    <p class="text-xs">
      <b>*{translate("NOTE")}:</b>
      {translate("CREATE_EDIT_INGREDIENT_NOTE")}
    </p>

    <div class="flex justify-center">
      <ButtonComponent on:click={onSubmit} {disabled}>{btnTitle}</ButtonComponent>
    </div>
  </form>
</div>
