<script lang="ts">
  import { createEventDispatcher, onMount, onDestroy } from "svelte";
    import { MiniSpinner } from "ui";

  const dispatch = createEventDispatcher();
  let hasMore: boolean;
  let scrollElement: HTMLElement | undefined = undefined;
  let isLoadMore = false;
  export let isFetchingMore: boolean | undefined = undefined;
  let element: HTMLElement | Window;

  const onScroll = (event: Event): void => {
    if (!hasMore) {
      return;
    }

    const {target}: any = event;
    const scrollTarget = target.documentElement ? target.documentElement : target;
    const {clientHeight, scrollHeight, scrollTop} = scrollTarget;
    const offset = scrollHeight - clientHeight - scrollTop;

    if (offset <= 256) {
      if (!isLoadMore && hasMore) {
        dispatch("loadMore");
      }

      isLoadMore = true;
    } else {
      isLoadMore = false;
    }
  };

  onMount((): void => {
    element = scrollElement || window;
    element.addEventListener("scroll", onScroll);
    element.addEventListener("resize", onScroll);
  });

  onDestroy((): void => {
    element.removeEventListener("scroll", onScroll);
    element.removeEventListener("resize", onScroll);
  });

  export { hasMore, scrollElement };
</script>

{#if isFetchingMore !== undefined && isFetchingMore === true}
  <!-- {#if $isLoadingMore} -->
    <div class="flex justify-center">
      <MiniSpinner />
    </div>
  <!-- {/if} -->
{/if}
