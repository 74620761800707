<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { filters } from "../stores/filterStore";
  import { translate } from "lib";
  import Svg from "./UI/Svg.svelte";

  let title: string;

  const dispatch = createEventDispatcher();

  const removeFilter = (): void => {
    dispatch("removeFilter");
  };

  export { title };
</script>

<div
  class="px-2 py-1 flex bg-slate-100 dark:bg-zinc-700 border rounded-2xl border-slate-300 dark:border-zinc-500 text-xs"
  on:click={removeFilter}
  on:keydown={removeFilter}
>
  <!-- {#if title === "ALL"}
    {translate("ALL")}
  {:else if title === "MY"}
    {translate("MY")}
  {:else if title === "BAR"}
    {translate("BAR")}
  {:else} -->
  {title}
  <!-- {/if} -->
  <Svg name="close" size={12} myClass="ml-1" />
</div>
