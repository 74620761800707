import {get, writable} from "svelte/store";
import {IngredientTemplate, serverlessRoutes} from "lib";
import {storeUtil} from "../lib/createItemsStore";
import type {Ingredient, ItemsStore} from "interfaces";

interface TrainerIngredientsStore extends ItemsStore<Ingredient> {
  filter: {
    search: string;
    template: IngredientTemplate | undefined;
    macroRatio: {
      carbs: number;
      protein: number;
      fats: number;
    } | undefined;
    tags: Array<string>;
  };
}

const trainerIngredientsStoreCreate = () => {
  const {set, subscribe, update} = writable<TrainerIngredientsStore>({
    items: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined,
    filter: {
      search: "",
      template: IngredientTemplate.ALL,
      macroRatio: undefined,
      tags: []
    }
  });

  const createUrl = (): string => {
    const {
      skip,
      filter: {search, template, macroRatio, tags}
    } = get({subscribe});

    const params = new URLSearchParams();

    params.append("take", "8");
    params.append("skip", `${skip}`);

    if (template === IngredientTemplate.ALL) {
      params.append("default", "1");
      params.append("template", "1");
    } else {
      params.append("default", "1");
      params.append("template", "0");
    }

    if (macroRatio) {
      params.append("macroRatio", Object.values(macroRatio).join());
    }

    if (tags.length) {
      params.append("foodType", tags.map((tag): string => tag).join());
    }

    if (search) {
      params.append("name", search);
    }

    return `${serverlessRoutes.INGREDIENT}/list?${params.toString()}`;
  }

  const {
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    search,
    loadCache
  } = storeUtil<Ingredient, TrainerIngredientsStore>(
    "trainerIngredientsCache", update, createUrl
  );

  return {
    set,
    subscribe,
    update,
    add,
    replace,
    remove,
    fetchData,
    fetchMoreData,
    loadCache,
    search
  };
};

const trainerIngredientsStore = trainerIngredientsStoreCreate();

export {trainerIngredientsStore};
