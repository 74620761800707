<script lang="ts">
  import { onMount } from "svelte";
  import {
    Input,
    Label,
    Textarea,
    Helper,
    Heading,
    Checkbox,
    Spinner,
  } from "flowbite-svelte";
  import { api, postFormDataWithJwt, translate } from "lib";
  import { dialogData, showAlert, theme, user } from "stores";
  import { clientJournalMeals } from "../../../stores/clientStores";
  import { isClient } from "../../../lib/roles";
  import { ButtonComponent } from "ui";

  let data: any;

  let form = {
    name: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    showMacros: {
      value: false,
      error: "",
    },
  };

  let disabled = true;
  let isLoading = false;
  const dialogType = data.meal ? translate("EDIT") : translate("CREATE");

  function validateField(field: string): void {
    const { value } = form[field];

    if (field === "name") {
      if (value.length === 0) {
        form[field].error = translate("FIELD_REQUIRED");
      } else if (value.length < 2) {
        form[field].error = translate("FIELD_MINIMUM_2");
      } else if (value.length > 320) {
        form[field].error = translate("FIELD_MAXIMUM_320");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  }

  const onSubmit = async (): Promise<void> => {
    const { meal } = data;
    const { name, description, showMacros } = form;
    isLoading = true;

    try {
      let response;
      const formData = new FormData();

      if (meal) {
        const url = `${api}/meal_plan/${meal.mealPlanId}/meal/${meal.id}`;

        formData.append(
          "meal",
          JSON.stringify({
            id: meal.id,
            name: name.value,
            description: description.value,
            entityType: 0,
            showMacros: showMacros.value,
          })
        );

        response = await postFormDataWithJwt(url, formData, "PUT");

        if (!isClient($user)) {
        } else {
          const _meal = $clientJournalMeals.find((m) => m.id === response.id);
          $clientJournalMeals.splice(
            $clientJournalMeals.indexOf(_meal),
            1,
            response
          );
        }

        data.executeFunction(response);
      } else {
        const url = `${api}/meal_plan/${data.mealPlanId}/meal`;

        formData.append("mealPlanId", data.mealPlanId);
        formData.append(
          "meal",
          JSON.stringify({
            name: name.value,
            description: description.value,
            entityType: 0,
            mealPlanId: data.mealPlanId,
            showMacros: showMacros.value,
          })
        );

        response = await postFormDataWithJwt(url, formData, "POST");

        data.executeFunction(response);
      }

      $dialogData.data = {};
      $dialogData.type = "";
      $showAlert.color = "black";
      $showAlert.message = `Created a new recipe meal.`;
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";
      $showAlert.message = `There was an error creating recipe meal.`;
    }
  };

  onMount((): void => {
    if (data.meal) {
      const { name, description, showMacros } = data.meal;

      form.name.value = name;
      validateField("name");
      form.description.value = description || "";
      validateField("description");
      form.showMacros.value = showMacros;
    }
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType}
    {translate("MEAL").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        on:input={() => validateField("name")}
      />
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}ㅤ{/if}
      </Helper>
    </div>

    <div>
      <Label for="description">{translate("DESCRIPTION")}:</Label>
      <Textarea
        id="description"
        placeholder={translate("DESCRIPTION")}
        rows="4"
        bind:value={form.description.value}
        on:input={() => validateField("description")}
      />
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}ㅤ{/if}
      </Helper>
    </div>

    <!-- <Toggle bind:checked="{form.showMacros.value}">
      Display Macronutrient Values
    </Toggle> -->

    <Checkbox
      bind:checked={form.showMacros.value}
      color={$theme === "dark" ? "seconday" : "primary"}
    >
      {translate("DISPLAY_MACRONUTRIENT_VALUES")}
    </Checkbox>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent {disabled} on:click={onSubmit}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
