import { getWithJwt, postWithJwt, serverlessRoutes } from "lib";
import { get, writable } from "svelte/store";
import { clientTagsMap, remindersMap, tags } from "./trainerStores";
import { user } from "./userStore";
import type { DataStore } from "interfaces";

const tagIds = new Map<string, number>();

tags.subscribe((res) => {
  res.forEach((tag: any): void => {
    tagIds.set(tag.note, tag.id);
  });
});

interface TrainerClientsStore extends DataStore<any> {
  searchTimeout: NodeJS.Timeout | undefined;
  filter: {
    search: string;
    template: number | undefined;
    sort: string | undefined;
    dateRange: [string, string];
    tags: Array<any>;
  };
}

const trainerClientsStoreCreate = () => {
  const {set, subscribe, update} = writable<TrainerClientsStore>({
    data: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    skip: 0,
    searchTimeout: undefined,
    filter: {
      search: "",
      template: 0,
      sort: undefined,
      dateRange: ["", ""],
      tags: []
    }
  });


  const fetchReminders = async (clientIds: number[]) => {
    try {
      return postWithJwt(`${serverlessRoutes.REMINDERS}/map`, {clientIds});
    } catch (error) {
      console.error(error);
    }
  };
  const fetchTags = async (clientIds: number[]) => {
    try {
      return postWithJwt(`${serverlessRoutes.TAGS}/map`, {clientIds});
    } catch (error) {
      console.error(error);
    }
  };


  const _getHasMore = (store: TrainerClientsStore): boolean => {
    return store.data.length < store.count;
  };

  const _createUrl = (): string => {
    const {skip, filter} = get({subscribe});
    const params = new URLSearchParams();

    params.append("take", "15");
    params.append("skip", `${skip}`);

    if (filter.search) {
      params.append("name", filter.search);
    }

    if (filter.tags.length) {
      params.append(
        "tags",
        filter.tags.map((filter): string => `${tagIds.get(filter)}`).join()
      );
    }

    if (filter.template) {
      params.append("options", `${filter.template}`);
    }

    if (filter.sort !== undefined) {
      if (filter.sort === "NAME_ASC") {
        params.append("orderBy", "name");
        params.append("order", "ASC");
      } else if (filter.sort === "NAME_DESC") {
        params.append("orderBy", "name");
        params.append("order", "DESC");
      } else if (filter.sort === "DATE_ASC") {
        params.append("orderBy", "id");
        params.append("order", "ASC");
      } else if (filter.sort === "DATE_DESC") {
        params.append("orderBy", "id");
        params.append("order", "DESC");
      } else if (filter.sort === "LAST_LOGIN_ASC") {
        params.append("orderBy", "lastLogin");
        params.append("order", "ASC");
      } else if (filter.sort === "LAST_LOGIN_DESC") {
        params.append("orderBy", "lastLogin");
        params.append("order", "DESC");
      } /*else if (filter.sort === "NEVER_LOGIN_ASC") {
        sort = "&orderBy=neverloggedin&order=ASC";
      } else if (filter.sort === "NEVER_LOGIN_DESC") {
        sort = "&orderBy=neverloggedin&order=DESC";
      } */else if (filter.sort === "INACTIVE_CHAT_ASC") {
        params.append("orderBy", "chat_session.clientLastMessageDate");
        params.append("order", "ASC");
      } else if (filter.sort === "INACTIVE_CHAT_DESC") {
        params.append("orderBy", "chat_session.clientLastMessageDate");
        params.append("order", "DESC");
      }
    }

    if (filter.dateRange[0] && filter.dateRange[1]) {
      const [from, to] = filter.dateRange;

      params.append("from", from);
      params.append("to", to);
    }

    return `${serverlessRoutes.TRAINER_CLIENT}?${params.toString()}`;
  };

  const fetchData = async (): Promise<void> => {
    if (!get(user)) {
      return;
    }

    update((store) => {
      store.isFetching = true;
      store.skip = 0;
      return store;
    });

    try {
      const response = await getWithJwt(_createUrl());
      const {data, count} = response.data;

      update((store) => {
        store.data = data;
        store.count = count;
        store.hasMore = _getHasMore(store);

        return store;
      });

      localStorage.setItem(
        "trainerClientsCache",
        JSON.stringify(response.data)
      );

      fetchReminders(data.map(({id}: any) => id)).then((res) => {
        remindersMap.update((store) => {
          store = {...store, ...res.data};
          return store;
        });
      });
      fetchTags(data.map(({id}: any) => id)).then((res) => {
        clientTagsMap.update((store) => {
          store = {...store, ...res.data};
          return store;
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
      update((store) => {
        store.isFetching = false;
        return store;
      });
    }
  };

  const fetchMoreData = async (): Promise<void> => {
    if (!get(user)) {
      return;
    }

    update((store) => {
      store.isFetchingMore = true;
      store.skip += 15;
      return store;
    });

    try {
      const response = await getWithJwt(_createUrl());

      add(response.data.data);

      fetchReminders(response.data.data.map(({id}: any) => id)).then((res) => {
        remindersMap.update((store) => {
          store = {...store, ...res.data};
          return store;
        });
      });
      fetchTags(response.data.data.map(({id}: any) => id)).then((res) => {
        clientTagsMap.update((store) => {
          store = {...store, ...res.data};
          return store;
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
       update((store) => {
        store.isFetchingMore = false;
        return store;
      });
    }
  };

  const add = (
    clients: Array<any>,
    position: "start" | "end" = "end",
    increaseCount: boolean = false
  ): void => {
    update((store) => {
      if (position === "start") {
        store.data.unshift(...clients);
      } else if (position === "end") {
        store.data.push(...clients);
      }

      if (increaseCount) {
        store.skip += clients.length;
        store.count += clients.length;
        store.hasMore = _getHasMore(store);
      }

      return store;
    });
  };

  const remove = (ids: Array<number>): void => {
    update((store) => {
      store.data = store.data.filter(
        (exercise): boolean => !ids.includes(exercise.id)
      );

      store.skip -= ids.length;
      store.count -= ids.length;
      store.hasMore = _getHasMore(store);

      return store;
    });
  };

  const change = (id: number, properties: any): void => {
    update((store) => {
      const {data} = store;
      const foundClient = data.find((client): boolean => client.id === id);

      if (!foundClient) {
        return store;
      }

      const foundClientIndex = data.indexOf(foundClient);

      store.data[foundClientIndex] = {...data[foundClientIndex], ...properties};

      return store;
    });
  };

  const replace = (id: number, client: any): void => {
    update((store) => {
      const {data} = store;
      const foundClient = data.find((client): boolean => client.id === id);

      if (!foundClient) {
        return store;
      }

      store.data = data.with(data.indexOf(foundClient), client);

      return store;
    });
  };

  const search = (): void => {
    update((store) => {
      clearTimeout(store.searchTimeout);
      store.searchTimeout = setTimeout(fetchData, 1000);
      return store;
    });
  };

  return {
    set,
    subscribe,
    update,
    fetchData,
    fetchMoreData,
    search,
    add,
    remove,
    change,
    replace,
  };
};

const trainerClientsStore = trainerClientsStoreCreate();

export {trainerClientsStore};
