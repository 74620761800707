<script lang="ts">
  import {onMount} from "svelte";
  import {Button, Checkbox, Heading, Label, Search} from "flowbite-svelte";
  import {api, postWithJwt, translate, getWithJwt} from "lib";
  import { ButtonComponent } from "ui";

  let data: any;
  let search: string;
  let users: any[] = [];
  const name = data.program ? data.program.name : data.mealPlan.name;

  const onSubmit = async (): Promise<void> => {
    if (data.program) {
      const response = await postWithJwt(`${api}/program/assign_many`, {
        programIds: [data.program.id],
        clientIds: users.filter((user) => user.checked).map((user) => user.id)
      });
    } else {
      const response = await postWithJwt(`${api}/meal_plan/assign_many`, {
        mealPlanIds: [data.mealPlan.id],
        assignToAll: false,
        clientIds: [users.filter((user) => user.checked).map((user) => user.id)]
      });
    }
  };

  onMount(async (): Promise<void> => {
    const response = await getWithJwt(`${api}/trainer/client?take=15&skip=0`);
    users = response.data;
  });

  export {data};
</script>

<div class="h-full p-4 flex flex-col gap-4">

  <Heading align="center" tag="h6">
    {translate("ASSIGN_TO_CLIENTS")} {name}
  </Heading>

  <Search
    size="md"
    placeholder="{translate("SEARCH_CLIENT")}"
    bind:value="{search}"
    on:input="{() => {}}"/>

  <div class="h-80 pr-4 flex flex-col gap-4 overflow-y-scroll">
    {#each users as {avatarUrl, name, checked}}
      <Label class="flex items-center cursor-pointer">
        <img class="h-16 w-16 mr-4 rounded-full" src="{avatarUrl}" alt="Avatar"/>
        <div class="grow">{name}</div>
        <Checkbox bind:checked/>
      </Label>
    {/each}
  </div>

  <div class="flex justify-center">
    <ButtonComponent on:click="{onSubmit}">{translate("ASSIGN")}</ButtonComponent>
  </div>

</div>
