<script lang="ts">
  import { slide } from "svelte/transition";
  import { Badge, Checkbox, P } from "flowbite-svelte";
  import { Button, ButtonComponent } from "ui";
  import {
    currentClient,
    dialogData,
    loadedMealsStore,
    trainerIngredientsStore,
    trainerRecipes,
    trainerRecipesCount,
    user,
  } from "stores";
  import {
    Roles,
    api,
    dialogTypes,
    serverlessRoutes,
  } from "../../lib/constants";
  import {
    deleteWithJwt,
    getWithJwt,
    postFormDataWithJwt,
    postWithJwt,
    putWithJwt,
  } from "../../lib/requests";
  import { translate } from "../../lib/translate";
  import { showAlert } from "../../stores/showAlertStore";
  import { selectedIds } from "../../stores/idSelectorStore";
  import More from "../UI/More.svelte";
  import Svg from "../UI/Svg.svelte";
  import Ingredient from "./Ingredient.svelte";
  import Macros from "./Macros.svelte";
  import type { MenuItem } from "../../interfaces/MenuItem";
  import { afterUpdate, createEventDispatcher, onMount } from "svelte";
  import { isClient } from "../../lib/roles";
  import { dragHandle } from "svelte-dnd-action";
  import { currentMealPlan } from "../../stores/trainerStores";
  import { clientJournalMeals } from "../../stores/clientStores";
  import { calculateMacros, calculateRecipeMacros } from "lib";

  let recipe: any;
  let selectMode = false;
  let isSelected = false;
  let isExpanded = false;

  const dispatch = createEventDispatcher();

  const defaultLink =
    "https://train-me-api.online/files/images/defaults/moj-trening/default-recipe.png";
  const newDefaultLink = "/logo.png";

  const onCreateIngredient = (): void => {
    $dialogData.data = {
      recipeId: recipe.id,
      mealId: recipe.mealId
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_INGREDIENT;
  };

  const onImportIngredients = (): void => {
    $dialogData.data = {
      async executeFunction (ingredients: Array<any>): Promise<void> {
        const partialIngredientMap: {[Id: string]: any} = {};

        ingredients.forEach((ingredient): void => {
          const {
            id,
            amount,
            originalAmount,
            originalCarbs,
            originalProtein,
            originalFats,
            originalCalories
          } = ingredient;

          partialIngredientMap[id] = {
            amount,
            carbs: (originalCarbs / originalAmount) * amount,
            protein: (originalProtein / originalAmount) * amount,
            fats: (originalFats / originalAmount) * amount,
            calories: (originalCalories / originalAmount) * amount,
            recipeId: recipe.id,
            isTemplate: 0,
            isDefault: 0,
          };
        });

        const response = await postWithJwt(
          `${serverlessRoutes.INGREDIENT}/copy`,
          {partialIngredientMap}
        );

        const rec = $trainerRecipes.find(({ id }) => id === recipe.id);

        if (rec) {
          rec.ingredients = [...rec.ingredients, ...response.data];
          calculateRecipeMacros(rec);
          $trainerRecipes = $trainerRecipes;
        } else {
          const meal = $loadedMealsStore.find((meal) => {
            if (meal.recipes) {
              const r = meal.recipes.find(({id}) => recipe.id === id);
              if (r) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
          const r = meal.recipes.find(({id}) => recipe.id === id);

          r.ingredients = [...r.ingredients, ...response.data];
          $loadedMealsStore = $loadedMealsStore;

          calculateMacros();
        }

        $showAlert.color = "black";
        $showAlert.message = translate("SUCCESSFULLY_IMPORTED");

        $dialogData.type = "";
        $dialogData.data = {};
      },
    };
    $dialogData.type = dialogTypes.IMPORT_INGREDIENTS;
  };

  const toggleSelect = (): void => {
    if (isSelected) {
      $selectedIds = [...$selectedIds, recipe.id];
    } else {
      $selectedIds = $selectedIds.filter((element) => element !== recipe.id);
    }
  };

  const fetchRecipeIngredients = async () => {
    const url = `${serverlessRoutes.RECIPE}/${recipe.id}/ingredients`;
    try {
      const response = await getWithJwt(url);
      $trainerRecipes = $trainerRecipes.map((r) =>
        r.id === recipe.id ? { ...r, ingredients: response.data } : r
      );
    } catch (err) {
      console.error(err);
    }
  };

  $: {
    if (isExpanded && !recipe.ingredients) {
      fetchRecipeIngredients();
    }
  }

  const onFinishRecipe = () => {
    const url = `${api}/recipe/${recipe.id}/finish`;
    try {
      postWithJwt(url, {});
      $showAlert.message = "Obrok uspešno sačuvan u dnevnik.";
    } catch (err) {
      $showAlert.color = "red";
      $showAlert.message = "Obrok nije uspešno sačuvan u dnevnik.";
    }
  };

  const onEditRecipe = (): void => {
    $dialogData.data = {
      recipe,
      executeFunction (res: any) { recipe = res; }
    };
    $dialogData.type = dialogTypes.CREATE_EDIT_RECIPE;
  };

  const onCopyRecipe = async (): Promise<void> => {
    const response = await postWithJwt(`${serverlessRoutes.RECIPE}/copy`, {
      recipeIds: [recipe.id],
    });

    if (response.error === null) {
      $showAlert.message = `
        ${translate("SUCCESSFULLY_COPIED")}
        ${translate("RECIPE").toLowerCase()}
      `;

      $trainerRecipes = [response.data[0], ...$trainerRecipes];
      $trainerRecipesCount += 1;
    } else {
      $showAlert.color = "red";
      $showAlert.message = "Kopiranje nije uspelo...";
    }
  };

  const onCopyTemplateRecipe = async (): Promise<void> => {
    const response = await postWithJwt(`${serverlessRoutes.RECIPE}/copy`, {
      recipeIds: [recipe.id],
      propertiesToOverride: {
        trainerId: $user.id,
        isTemplate: 0,
      },
    });

    if (response.error === null) {
      $showAlert.message = `
        ${translate("SUCCESSFULLY_COPIED")}
        ${translate("RECIPE").toLowerCase()}
      `;

      $trainerRecipes = [response.data[0], ...$trainerRecipes];
      $trainerRecipesCount += 1;
    } else {
      $showAlert.color = "red";
      $showAlert.message = "Kopiranje nije uspelo...";
    }
  };

  const onDeleteRecipe = async (): Promise<void> => {
    $dialogData.data = {
      async executeFunction() {
        try {
          const url = `${api}/recipe/${recipe.id}`;
          const response = await deleteWithJwt(url);

          if (response.message === "Successfully deleted") {
            $showAlert.message = `
              ${translate("SUCCESSFULLY_DELETED")}
              ${translate("RECIPE").toLowerCase()}
            `;
            if (recipe.isDefault) {
              $trainerRecipes = $trainerRecipes.filter(
                ({ id }) => id !== recipe.id
              );
              $trainerRecipesCount -= 1;
            } else {
              dispatch("delete", recipe.id);
            }
          }
        } catch (err) {}
      },
      title: recipe.name,
    };
    $dialogData.type = dialogTypes.CONFIRM_DELETE;
  };

  const menuItemsClient: MenuItem[] = [
    {
      icon: "save",
      title: "SAVE_TO_JOURNAL",
      executeFunction: onFinishRecipe,
    },
  ];

  const menuItemsTrainer: MenuItem[] = [
    {
      icon: "edit",
      title: "EDIT",
      executeFunction: onEditRecipe,
    },
    {
      icon: "copy",
      title: "CREATE_COPY",
      executeFunction: onCopyRecipe,
    },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction: onDeleteRecipe,
    },
  ];

  const menuItemsFinished: MenuItem[] = [
    {
      icon: "edit",
      title: "EDIT",
      executeFunction: onEditRecipe,
    },
    // {
    //   icon: "copy",
    //   title: "CREATE_COPY",
    //   executeFunction: () => {},
    // },
    {
      icon: "delete",
      title: "DELETE",
      executeFunction: onDeleteRecipe,
    },
  ];

  const menuItemsTemplate: MenuItem[] = [
    {
      icon: "copy",
      title: "CREATE_COPY",
      executeFunction: onCopyTemplateRecipe,
    },
    {
      icon: "chart-bar",
      title: "DETAILS",
      executeFunction(): void {
        $dialogData.data = { type: "recipe" };
        $dialogData.type = dialogTypes.TEMPLATE;
      },
    },
  ];

  let menuItems: MenuItem[] = [];

  const onIngredientDelete = (event: CustomEvent): void => {
    const id = event.detail;
    recipe.ingredients = recipe.ingredients.filter(
      (ingredient) => ingredient.id !== id
    );
    calculateRecipeMacros(recipe);
    if (recipe.mealId) {
      calculateMacros();
    }
  };

  const onIngredientCopy = (event: CustomEvent<{
    recipeId: number;
    ingredients: Array<any>
  }>): void => {
    const {recipeId, ingredients} = event.detail;

    if (recipe.id === recipeId) {
      recipe.ingredients = [...recipe.ingredients, ...ingredients];
      calculateRecipeMacros(recipe);
      if (recipe.mealId) {
        calculateMacros();
      }
    }
  };

  const saveToDatabase = async (): Promise<void> => {
    try {
      const response = await postWithJwt(`${api}/recipe/${recipe.id}/copy`, {
        ownerType: "USER",
      });

      $showAlert.color = "black";
      $showAlert.message = `
        ${translate("RECIPE")}
        ${translate("SAVED_TO_DATABASE").toLowerCase()}
      `;
    } catch (error) {
      console.error(error);
    }
  };

  const saveToJournal = async (): Promise<void> => {};

  onMount(() => {
    recipe.imageUrl = recipe.thumbnailUrl;

    // dodajemo Sacuvaj u bazu ako je u meal plan
    if (recipe.mealId) {
      menuItemsTrainer.unshift({
        icon: "save",
        title: "SAVE_TO_DATABASE",
        executeFunction: saveToDatabase,
      });
    }

    // dodajemo Sacuvaj u dnevnik ako je u meal plan kod klijenta
    if ($currentClient.id) {
      menuItemsTrainer.unshift({
        icon: "save",
        title: "SAVE_TO_JOURNAL",
        executeFunction: saveToJournal,
      });
    }
  });

  const onExpand = (): void => {
    isExpanded = !isExpanded;
  };

  afterUpdate((): void => {
    if (recipe.finishedAt) {
      menuItems = menuItemsFinished;
    } else if (recipe.isTemplate) {
      menuItems = menuItemsTemplate;
    } else if (isClient($user)) {
      menuItems = menuItemsClient;
    } else if (!isClient($user)) {
      menuItems = menuItemsTrainer;
    }
  });

  export { recipe, selectMode };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="relative p-4 flex-col gap-4 border border-slate-200 dark:border-zinc-600 rounded-md shadow-md bg-white dark:bg-zinc-800"
>
  {#if recipe.isTemplate}
    <div class="tag">
      <div class="tag__clip bg-primary-500 dark:bg-primary-700"></div>
      <div class="tag__icon">
        <Svg myClass="mr-2" name="star" size={14} customColor="bg-white" />
      </div>
    </div>
  {/if}

  <!-- Wrapper -->
  <div class="flex gap-4" on:click={onExpand}>
    <!-- Slika i makro -->
    <div class="flex flex-col gap-4 grow">
      <div class="flex items-center">
        <div class="image shadow">
          <img
            src={recipe.imageUrl ? recipe.imageUrl : newDefaultLink}
            class="image shadow"
            alt="Recipe"
            on:error={(el) => {
              el.target.src = newDefaultLink;
            }}
          />
        </div>

        <div class="text-center text-xs grow">
          <div class="text-slate-900 dark:text-slate-100 font-medium">
            {recipe.name}
          </div>
          <div>
            {translate("CALORIES")}:
            <span class="text-amber-400 font-semibold">{recipe.calories}</span>
          </div>
        </div>
      </div>

      <Macros
        carbs={recipe.carbs}
        protein={recipe.protein}
        fats={recipe.fats}
        calories={recipe.calories}
        isBarVisible
      />
    </div>

    <!-- Dugmici -->
    <div class="flex flex-col justify-between">
      {#if selectMode}
        <div class="mr-3">
          <Checkbox
            color="teal"
            bind:checked={isSelected}
            on:change={toggleSelect}
          />
        </div>
      {:else}
        {#if !recipe.isDefault && !isClient($user)}
          <div use:dragHandle>
            <Svg name="drag" size={16} />
          </div>
        {/if}

        <More {menuItems} />

        <div class="chevron" class:isExpanded>
          <Svg name="down-caret" size={16} />
        </div>
      {/if}
    </div>
  </div>

  {#if isExpanded}
    <div
      class="flex flex-col gap-4 text-slate-900 dark:text-slate-100"
      in:slide
      out:slide
    >
      <div>
        <p class="font-semibold">{translate("FULL_NAME")}:</p>
        <p class="indent-4 text-xs">{recipe.name}</p>
      </div>

      <div>
        <p class="font-semibold">{translate("RECIPE_TYPE")}:</p>
        {#if recipe.foodType.length}
          <div class="flex flex-wrap gap-2">
            {#each recipe.foodType as name}
              <Badge large rounded color="green">{name}</Badge>
            {/each}
          </div>
        {:else}
          <p class="indent-4 text-xs">{translate("TYPE_NOT_ASSIGNED")}</p>
        {/if}
      </div>

      <div>
        <p class="font-semibold">{translate("DIRECTIONS")}:</p>
        <p class="indent-4 text-justify text-xs">
          {recipe.description || translate("NO_DIRECTIONS")}
        </p>
      </div>

      <div>
        <p class="font-semibold">{translate("INGREDIENTS")}:</p>
        {#if recipe.ingredients && recipe.ingredients.length > 0}
          <div class="flex-col gap-4">
            {#each recipe.ingredients as ingredient}
              <Ingredient
                {ingredient}
                isMoreVisible={ingredient.finishedAt}
                isRecipeTemplate={recipe.isTemplate}
                on:delete={onIngredientDelete}
                on:createCopy={onIngredientCopy}
              />
            {/each}
          </div>
        {/if}
      </div>

      {#if !isClient($user) && !recipe.isTemplate}
        <div class="flex-row justify-between">
          <ButtonComponent isOutline on:click={onCreateIngredient}>
            {translate("CREATE_INGREDIENT")}
          </ButtonComponent>
          <ButtonComponent isOutline on:click={onImportIngredients}>
            {translate("IMPORT_INGREDIENTS")}
          </ButtonComponent>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .name {
    width: 100%;
    text-align: center;
  }
  .image {
    /* border-radius: 0.375rem; */
    display: block;
    min-width: 128px;
    height: 64px;
    object-fit: cover;
  }
  .chevron {
    /* ista tranzicija kao default svelte:slide */
    transition: transform 400ms linear;
  }
  .isExpanded {
    transform: rotate(180deg);
  }
  .tag {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 36px;
    width: 36px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }
  .tag__icon {
    position: absolute;
    top: 4px;
    left: 4px;
  }
  .tag__clip {
    height: 36px;
    width: 36px;
    clip-path: polygon(0px 0px, 36px 0px, 0px 36px);
    /* background-color: #6875f5; */
  }
</style>
