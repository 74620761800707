<script lang="ts">
  import { onMount } from "svelte";
  import { Badge, Heading, P } from "flowbite-svelte";
  import { Chart } from "chart.js";
  import { translate } from "lib";
  import Macros from "../../Diet/Macros.svelte";

  let chartElem: HTMLCanvasElement;
  let data: any; // : Ingredient
  let percentages = { calories: "0", carbs: "0", protein: "0", fats: "0" };

  const calculatePercentages = () => {
    if (data.calories === 0) {
      return {
        carbs: "0",
        protein: "0",
        fats: "0",
        calories: "0",
      };
    }

    const percentages = {
      carbs: (((data.carbs * 4) / data.calories) * 100).toFixed(0),
      protein: (((data.protein * 4) / data.calories) * 100).toFixed(0),
      fats: (((data.fats * 9) / data.calories) * 100).toFixed(0),
      calories: "0",
    };

    return percentages;
  };

  onMount((): void => {
    percentages = calculatePercentages();

    const chart = new Chart(chartElem, {
      type: "pie",
      data: {
        labels: ["Carbs", "Proteins", "Fats"],
        datasets: [
          {
            label: "Percent",
            data: [percentages.carbs, percentages.protein, percentages.fats],
            backgroundColor: [
              "rgb(76, 175, 80)",
              "rgb(22, 167, 250)",
              "rgb(244, 67, 54)",
            ],
            hoverOffset: 16,
          },
        ],
      },
    });
  });

  export { data };
</script>

<div class="p-4 flex-col gap-4">
  <Heading align="center" tag="h6">{data.name}</Heading>

  <div>
    <P weight="semibold">
      {translate("PER")}
      {data.amount}
      {data.measurementUnit.toLowerCase()}:
    </P>
    <Macros
      carbs={data.carbs}
      protein={data.protein}
      fats={data.fats}
      calories={data.calories}
      isCaloriesVisible
    />
  </div>

  <div>
    <P weight="semibold">{translate("INGREDIENT_TYPE")}:</P>
    <div class="flex flex-wrap gap-2">
      {#each data.foodType as name}
        <Badge large rounded color="green">{name}</Badge>
      {/each}
    </div>
  </div>

  <div>
    <P weight="semibold">{translate("DESCRIPTION")}:</P>
    <P class="indent-4"
      >{data.description ? data.description : translate("NO_DIRECTIONS")}</P
    >
  </div>

  <div class="flex justify-center">
    <!-- Desktop fix w-96 (384px) -->
    <div class="w-96">
      <canvas bind:this={chartElem} />
    </div>
  </div>
</div>
