<script lang="ts">
  import { ButtonGroup, Heading, Input, InputAddon } from "flowbite-svelte";
  import { translate } from "../../../lib/translate";
  import { ButtonComponent } from "ui";

  export let data: any;

  let hours: number;
  let minutes: number;
</script>

<div
  class="m-5 text-center bg-white dark:bg-zinc-800 text-slate-900 dark:text-slate-100"
>
  <Heading tag="h3" customSize="text-xl text-bold"
    >{translate("HOW_LONG_DO_YOU_PLAN_TO_FAST_FOR")}?</Heading
  >
  <div class="flex flex-row items-center mt-5 mb-5">
    <div>
      <ButtonGroup size="sm">
        <Input
          bind:value={hours}
          id="input-addon-md"
          type="number"
          placeholder="16"
          size="sm"
        />
        <InputAddon>h</InputAddon>
      </ButtonGroup>
    </div>
    <span class="ml-2 mr-2">:</span>
    <div>
      <ButtonGroup size="sm">
        <Input
          id="input-addon-md"
          bind:value={minutes}
          type="number"
          placeholder="00"
          size="sm"
        />
        <InputAddon>m</InputAddon>
      </ButtonGroup>
    </div>
  </div>
  <ButtonComponent
    on:click={() =>
      data.executeFunction(+hours * 60 + (minutes ? +minutes : 0))}
    >{translate("START")}</ButtonComponent
  >
</div>
