<script lang="ts">
  let id = (Math.round(Math.random() * 1000000)).toString();
  let value: boolean;
  let label: string = "";

  export { value, label };
</script>

<div class="flex items-center">
  <input
    {id}
    type="checkbox"
    bind:checked={value}
    on:change
    class="w-4 h-4 text-primary-500 dark:text-primary-500 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-zinc-600 dark:border-gray-400"
  />
  {#if label !== ""}
    <label
      for="{id}"
      class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300"
    >
      {label}
    </label>
  {/if}
</div>
