<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { getWithJwt, patchWithJwt, serverlessRoutes, translate } from "lib";
  import { dialogData, selectedExerciseIds, showAlert, trainerExercisesStore } from "stores";
  import { ButtonComponent, InfiniteScroll, Search } from "ui";
  import ExerciseInWorkout from "../Training/ExerciseInWorkout.svelte";
  import Filter from "../Filter.svelte";

  export let data: any;

  let scrollElement: HTMLDivElement;
  let step = 1;
  let isRecommendedDisabled = false;
  let recommendedExercises: Array<any> = [];
  let selectedExercise: any;
  let isSubmitting = false;

  const idSubscription = selectedExerciseIds.subscribe((ids) => {
    const [id] = ids;
    const rec = recommendedExercises.find((e) => e.id === id);
    const tr = $trainerExercisesStore.data.find((e) => e.id === id);
    selectedExercise = rec || tr;
  });

  const onScrollElementLoad = (element: HTMLDivElement): void => {
    scrollElement = element;
  };

  const onConnect = async (): Promise<void> => {
    isSubmitting = true;

    try {
      const response = await patchWithJwt(
        `${serverlessRoutes.EXERCISE}/${data.exercise.id}`,
        {
          parentExerciseId: selectedExercise?.id
        }
      );

      const {exercise} = response.data;

      data.executeFunction({exercise});

      $showAlert.color = "black";
      $showAlert.message = translate("SUCCESS_CONNECTED_WITH_PARENT_EXERCISE");
    } catch (error) {
      $showAlert.color = "red-500";
      $showAlert.message = translate("ERROR_CONNECTED_WITH_PARENT_EXERCISE");
      console.error(error);
    } finally {
      $dialogData.type = "";
      $dialogData.data = {};
    }
  };

  onMount(async (): Promise<void> => {
    if (!$trainerExercisesStore.data.length) {
      await trainerExercisesStore.fetchData();
    }

    const response = await getWithJwt(`${serverlessRoutes.EXERCISE}/list?take=10&skip=0&default=1&template=1&name=${data.exercise.searchName}`);

    if (response.data.data.length) {
      recommendedExercises = response.data.data;
    } else {
      step = 2;
      isRecommendedDisabled = true;
    }
  });

  onDestroy((): void => {
    idSubscription();
    $selectedExerciseIds = [];
  });
</script>

<div class="relative p-4 flex flex-col gap-4">
  <div class="font-bold leading-none text-xl text-center">
    {translate("CONNECT_WITH_PARENT_EXERCISE")}
  </div>

  {#if $selectedExerciseIds.length}

    <div class="text-center">{translate("YOUVE_SELECTED_EXERCISE")}:</div>
    <ExerciseInWorkout isSelectMode exercise={selectedExercise}/>

  {:else}

    {#if step === 1}

      <div class="flex flex-col gap-4">
        <div class="text-center">{translate("OUR_RECOMMENDATIONS")}:</div>

        <div class="max-h-64 flex flex-col gap-2 overflow-y-scroll">
          {#each recommendedExercises as recommendedExercise}
            <ExerciseInWorkout isSelectMode exercise={recommendedExercise}/>
          {/each}
        </div>

        <div class="text-center text-xs">
          {translate("OUR_RECOMMENDATION_NOTE")}
        </div>
      </div>

    {:else if step === 2}

      <div class="flex flex-col gap-4">
        <Search
          placeholder={translate("SEARCH_EXERCISES")}
          bind:value={$trainerExercisesStore.searchValue}
          on:input={trainerExercisesStore.search}
        />

        <Filter
          type="EXERCISES"
          isModifyVisible={false}
          isCountVisible
          on:fetchData={trainerExercisesStore.fetchData}
        />

        <div
          class="flex flex-col gap-2 max-h-64 overflow-y-scroll"
          use:onScrollElementLoad
        >
          {#each $trainerExercisesStore.data as exercise}
            <ExerciseInWorkout isSelectMode {exercise} />
          {/each}

          <InfiniteScroll
            {scrollElement}
            isFetchingMore={$trainerExercisesStore.isFetchingMore}
            hasMore={$trainerExercisesStore.hasMore}
            on:loadMore={trainerExercisesStore.fetchMoreData}
          />
        </div>
      </div>

    {/if}

  {/if}

  <div class="flex justify-center gap-4">
    {#if $selectedExerciseIds.length}
      <ButtonComponent isLoading={isSubmitting} on:click={onConnect}>
        {translate("CONNECT")}
      </ButtonComponent>
    {:else}
      {#if step === 1}
        <ButtonComponent isOutline on:click={() => step ++}>
          {translate("SEARCH")}
        </ButtonComponent>
      {:else if step === 2}
        <ButtonComponent isOutline disabled={isRecommendedDisabled} on:click={() => step --}>
          {translate("OUR_RECOMMENDATIONS")}
        </ButtonComponent>
      {/if}
    {/if}
  </div>
</div>
