<script lang="ts">
  let carbs: number;
  let protein: number;
  let fats: number;

  export { carbs, protein, fats };
</script>

<div class="flex">
  <div class="h-1 bg-green-400" style:flex-basis="{carbs.toFixed(1)}%" />
  <div class="h-1 bg-sky-400" style:flex-basis="{protein.toFixed(1)}%" />
  <div class="h-1 bg-red-400" style:flex-basis="{fats.toFixed(1)}%" />
</div>
