<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { Radio } from "flowbite-svelte";
  import { api } from "../lib/constants";
  import { getWithJwt } from "../lib/requests";
  import { translate } from "../lib/translate";
  import { exercisesCount, filtersStore2, trainerClientsCount } from "stores";
  import FilterTag from "./FilterTag.svelte";
  import DualSlider from "./UI/DualSlider.svelte";
  import { slide } from "svelte/transition";
  import { trainerRecipesCount } from "stores";
  import Svg from "./UI/Svg.svelte";
  import {ButtonComponent} from "ui";
  import { trainerWorkoutsCount } from "../stores/trainerWorkoutsCount";
  import {CheckboxComponent} from "ui";
  import { tags as tagStore } from "../stores/trainerStores";
  import { trainerExercisesStore } from "../stores/trainerExercisesStore";

  type Filters =
    | "INGREDIENTS"
    | "RECIPES"
    | "EXERCISES"
    | "WORKOUTS"
    | "CLIENTS"
    | "NOTIFICATIONS";
  type Functions = { [K in Filters]: () => Promise<void> };

  export let type: Filters;
  export let isCountVisible = false;
  export let isMacroBarVisible = false;
  export let isModifyVisible = false;

  const dispatch = createEventDispatcher();

  let isExpanded = false;
  let tags: Array<string> = [];

  let valuesTimeout: NodeJS.Timeout;
  let templateGroup: string | undefined = "ALL";
  let sortGroup: string | undefined;
  let clientSortGroup: string | undefined;

  const fetchIngredientTags = async () => {
    try {
      tags = await getWithJwt(`${api}/ingredient/food_types`);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRecipeTags = async () => {
    try {
      tags = await getWithJwt(`${api}/recipe/food_types`);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNotifications = async () => {
    tags = ["Prikaži samo neotvorene", "Prikaži samo komentare"];
  };

  const fetchExerciseTags = async () => {
    try {
      tags = [
        "Trbuh",
        "Biceps",
        "Listovi",
        "Grudi",
        "Podlaktica",
        "Zadnjica",
        "Zadnja loža",
        "Donja leđa",
        "Prednja loža",
        "Ramena",
        "Triceps",
        "Gornja leđa",
      ];
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWorkoutTags = async () => {
    try {
      tags = [
        "Trbuh",
        "Biceps",
        "Listovi",
        "Grudi",
        "Podlaktica",
        "Zadnjica",
        "Zadnja loža",
        "Donja leđa",
        "Prednja loža",
        "Ramena",
        "Triceps",
        "Gornja leđa",
      ];
    } catch (error) {
      console.error(error);
    }
  };

  tagStore.subscribe((res) => {
    tags = $tagStore.map((tag: any) => tag.note);
  });

  const fetchClientTags = async () => {
    // try {
    //   // const response = await getWithJwt(`${api}/client_tag`);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  let callFunction: Functions = {
    INGREDIENTS: fetchIngredientTags,
    RECIPES: fetchRecipeTags,
    EXERCISES: fetchExerciseTags,
    WORKOUTS: fetchWorkoutTags,
    CLIENTS: fetchClientTags,
    NOTIFICATIONS: fetchNotifications,
  };

  interface MacroValuesEvent {
    carbs: number;
    protein: number;
    fats: number;
  }

  const templateTags = ["ALL", "MY"];
  const clientTemplateTags = [
    "SHOW_ARCHIVED",
    "SHOW_DELETED",
    "SHOW_ACTIVE",
    "SHOW_ALL",
  ];
  const sortTags = ["NAME_ASC", "NAME_DESC", "DATE_ASC", "DATE_DESC"];
  const clientSortTags = [
    "NAME_ASC",
    "NAME_DESC",
    "DATE_ASC",
    "DATE_DESC",
    "LAST_LOGIN_ASC",
    "LAST_LOGIN_DESC",
    "NEVER_LOGIN_ASC",
    "NEVER_LOGIN_DESC",
    "INACTIVE_CHAT_ASC",
    "INACTIVE_CHAT_DESC",
  ];

  const onRemoveTemplateTag = (): void => {
    $filtersStore2.template = undefined;
    templateGroup = undefined;

    dispatch("fetchData");
  };

  const onRemoveSortTag = (): void => {
    $filtersStore2.sort = undefined;
    sortGroup = undefined;

    dispatch("fetchData");
  };

  const onRemoveMacroTag = (): void => {
    $filtersStore2.macroRatio = undefined;
    dispatch("fetchData");
  };

  const onRemoveTag = (toRemove: string): void => {
    $filtersStore2.tags = $filtersStore2.tags.filter((tag) => tag !== toRemove);

    dispatch("fetchData");
  };

  const onSortTagChange = (): void => {
    $filtersStore2.sort = sortGroup;

    dispatch("fetchData");
  };

  const onTemplateTagChange = (): void => {
    if (templateGroup === "ALL") {
      $filtersStore2.template = 1;
    } else {
      $filtersStore2.template = 0;
    }

    dispatch("fetchData");
  };

  const onClientTemplateTagChange = (): void => {
    if (clientSortGroup === "SHOW_ARCHIVED") {
      $filtersStore2.template = 1;
    } else if (clientSortGroup === "SHOW_ACTIVE") {
      $filtersStore2.template = 0;
    } else if (clientSortGroup === "SHOW_ALL") {
      $filtersStore2.template = 4 as any;
    } else {
      $filtersStore2.template = 2 as any;
    }

    dispatch("fetchData");
  };

  const onMacroBarChange = (event: CustomEvent<MacroValuesEvent>): void => {
    $filtersStore2.macroRatio = {
      carbs: event.detail.carbs,
      protein: event.detail.protein,
      fats: event.detail.fats,
    };

    if (valuesTimeout) {
      clearTimeout(valuesTimeout);
    }

    valuesTimeout = setTimeout((): void => {
      dispatch("fetchData");
    }, 1000);
  };

  const onTagChange = (checkedTag: string): void => {
    if ($filtersStore2.tags.includes(checkedTag)) {
      $filtersStore2.tags = $filtersStore2.tags.filter(
        (tag) => tag !== checkedTag
      );
    } else {
      $filtersStore2.tags = [checkedTag, ...$filtersStore2.tags];
    }

    dispatch("fetchData");
  };

  const onModify = (): void => {
    dispatch("editMode");
  };

  onDestroy((): void => {
    if (type !== "CLIENTS") $filtersStore2.template = 1;
    $filtersStore2.sort = undefined;
    $filtersStore2.macroRatio = undefined;
    $filtersStore2.tags = [];
  });

  onMount((): void => {
    // if (type === "CLIENTS") $filtersStore2.template = 0;
    callFunction[type](); // ovo fecuje type-ove
  });
</script>

<div class="flex flex-col gap-4">
  <div class="flex items-start gap-4">
    <div class="flex flex-wrap grow gap-2">
      {#if $filtersStore2.template !== undefined}
        <FilterTag
          on:removeFilter={onRemoveTemplateTag}
          title={translate($filtersStore2.template === 1 ? "ALL" : "MY")}
        />
      {/if}
      {#if $filtersStore2.sort !== undefined}
        <FilterTag
          on:removeFilter={onRemoveSortTag}
          title={translate($filtersStore2.sort)}
        />
      {/if}
      {#if $filtersStore2.macroRatio}
        <FilterTag
          on:removeFilter={onRemoveMacroTag}
          title={translate("BAR")}
        />
      {/if}
      {#each $filtersStore2.tags as filter}
        <FilterTag on:removeFilter={() => onRemoveTag(filter)} title={filter} />
      {/each}
    </div>

    <ButtonComponent isOutline on:click={() => (isExpanded = !isExpanded)}>
      {translate("FILTER")}
    </ButtonComponent>
  </div>

  {#if isCountVisible}
    <p class="flex items-center justify-center text-md">
      {#if type === "INGREDIENTS"}
        <!-- ovo se vise ne koristi, a cela filter komponenta ce da se obrise kad se razbije u delove -->
        {translate("TOTAL_INGREDIENT_COUNT")}:
        <b class="ml-1 text-md">{0}</b>
      {:else if type === "RECIPES"}
        {translate("TOTAL_RECIPE_COUNT")}:
        <b class="ml-1 text-md">{$trainerRecipesCount}</b>
      {:else if type === "EXERCISES"}
        {translate("NUMBER_OF_EXERCISES")}:
        <b class="ml-1 text-md">{$exercisesCount || $trainerExercisesStore.count}</b>
      {:else if type === "WORKOUTS"}
        {translate("NUMBER_OF_WORKOUTS")}:
        <b class="ml-1 text-md">{$trainerWorkoutsCount}</b>
      {:else}
        {translate("TOTAL_NUMBER_OF_CLIENTS")}:
        <b class="ml-1 text-md">{$trainerClientsCount}</b>
      {/if}
      {#if isModifyVisible}
        <Svg
          myClass="ml-2 inline-block"
          name="check-square-offset"
          size={24}
          on:click={onModify}
        />
      {/if}
    </p>
  {/if}

  {#if isExpanded}
    <div
      class="flex flex-col border border-slate-200 dark:border-zinc-600 rounded-md"
      in:slide
      out:slide
    >
      {#if isMacroBarVisible}
        <DualSlider on:values={onMacroBarChange} />
      {/if}

      <div class="flex">
        <div class="flex flex-col basis-1/2">
          {#if type === "EXERCISES" || type === "WORKOUTS"}
            <div class="p-2 flex flex-col gap-2 border-b">
              {#each sortTags as sortTag}
                <Radio
                  class="text-xs"
                  value={sortTag}
                  bind:group={sortGroup}
                  on:change={onSortTagChange}
                >
                  {translate(sortTag)}
                </Radio>
              {/each}
            </div>
          {:else if type === "CLIENTS"}
            <div
              class="p-2 flex flex-col gap-2 border-b border-slate-200 dark:border-zinc-600"
            >
              {#each clientTemplateTags as templateTag}
                <Radio
                  class="text-xs"
                  value={templateTag}
                  bind:group={clientSortGroup}
                  on:change={onClientTemplateTagChange}
                >
                  {translate(templateTag)}
                </Radio>
              {/each}
            </div>
          {/if}

          <div class="p-2 flex flex-col gap-2">
            {#if type === "CLIENTS"}
              {#each clientSortTags as sortTag}
                <Radio
                  class="text-xs"
                  value={sortTag}
                  bind:group={sortGroup}
                  on:change={onSortTagChange}
                >
                  {translate(sortTag)}
                </Radio>
              {/each}
            {:else if type !== "NOTIFICATIONS"}
              {#each templateTags as templateTag}
                <Radio
                  class="text-xs"
                  value={templateTag}
                  bind:group={templateGroup}
                  on:change={onTemplateTagChange}
                >
                  {translate(templateTag)}
                </Radio>
              {/each}
            {/if}
          </div>
        </div>

        <div
          class="p-2 flex flex-col basis-1/2 gap-2 border-l border-slate-200 dark:border-zinc-600"
        >
          {#each tags as tag}
            <div class="flex flex-row">
              <div
                on:click={() => onTagChange(tag)}
                on:keypress={() => onTagChange(tag)}
                tabindex="0"
                role="button"
              >
                <CheckboxComponent value={$filtersStore2.tags.includes(tag)} />
              </div>
              <span class="text-xs ml-1 dark:text-slate-300">{tag}</span>
            </div>
          {/each}
        </div>
      </div>
    </div>
  {/if}
</div>
