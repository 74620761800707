<script lang="ts">
  import { onMount } from "svelte";
  import { Heading, Search } from "flowbite-svelte";
  import { api, getWithJwt, translate } from "lib";
  import Recipe from "../../Diet/Recipe.svelte";
  import Filter from "../../Filter.svelte";
  import {
    filtersStore2,
    trainerRecipes,
    trainerRecipesCount,
    user,
  } from "stores";
  import InfiniteScroll from "../../UI/InfiniteScroll.svelte";
    import { ButtonComponent } from "ui";

  let data: any;
  let fetchedRecipes: Array<any> = [];
  let value = "";
  let timeout: NodeJS.Timeout;
  let page = 0;
  let isLoading = false;
  let scrollElem: HTMLDivElement;

  $: hasMore = $trainerRecipes.length <= $trainerRecipesCount;

  const createUrl = (): string => {
    let template = "";
    let macroRatio = "";
    let foodType = "";
    let name = "";

    if ($filtersStore2.template === 1) {
      template = "&default=1&template=1";
    } else {
      template = "&default=1&template=0";
    }

    if ($filtersStore2.macroRatio) {
      const { carbs, protein, fats } = $filtersStore2.macroRatio;
      macroRatio = `&macroRatio=%5B${carbs},${protein},${fats}%5D`;
    }

    if ($filtersStore2.tags.length) {
      foodType = `&foodType=%5B${$filtersStore2.tags
        .map((filter): string => `%22${filter}%22`)
        .join(",")}%5D`;
    }

    if (value) {
      name = `&name=${value}`;
    }

    return `${api}/recipe?take=15&skip=${
      page * 15
    }${template}${macroRatio}${foodType}${name}`;
  };

  const onFetchData = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    isLoading = true;
    page = 0;

    try {
      const response = await getWithJwt(createUrl());
      $trainerRecipes = response.data;
      $trainerRecipesCount = response.count;
    } catch (error) {
      console.error(error);
    }

    isLoading = false;
  };

  const onLoadMore = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    isLoading = true;
    page += 1;

    try {
      const response = await getWithJwt(createUrl());
      $trainerRecipes = [...$trainerRecipes, ...response.data];
    } catch (error) {
      console.error(error);
    }

    isLoading = false;
  };

  const onInput = (): void => {
    clearTimeout(timeout);
    timeout = setTimeout(async (): Promise<void> => {
      onFetchData();
    }, 1000);
  };

  onMount(onFetchData);

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading customSize="text-center text-xl">{translate("ADD_RECIPES")}</Heading>

  <Search
    size="md"
    placeholder="{translate('SEARCH')} {translate('RECIPES_E').toLowerCase()}"
    bind:value
    on:input={onInput}
  />

  <Filter
    type="RECIPES"
    isCountVisible
    isMacroBarVisible
    on:fetchData={onFetchData}
  />

  <div
    class="h-80 pr-2 flex flex-col gap-4 overflow-y-scroll"
    bind:this={scrollElem}
  >
    {#each $trainerRecipes as recipe}
      <Recipe {recipe} selectMode />
    {/each}
    <InfiniteScroll {hasMore} on:loadMore={onLoadMore} />
  </div>

  <div class="flex justify-center">
    <ButtonComponent on:click={() => data.executeFunction()}
      >{translate("ADD")}</ButtonComponent
    >
  </div>
</div>
