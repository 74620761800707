<script lang="ts">
  import { onMount } from "svelte";
  import { translate } from "lib";
  import { ButtonComponent } from "ui";
  import { dialogData } from "stores";

  let data: any;

  const onClose = (): void => {
    $dialogData.type = "";
    $dialogData.data = {};
  };

  const onCreateCopy = async (): Promise<void> => {};

  onMount((): void => {});

  export { data };
</script>

<div class="p-4 flex-col gap-4">
  <h3 class="text-xxl font-semibold text-center">
    {#if data.type === "ingredient"}
      {translate("TEMPLATE_INGREDIENT")}
    {:else if data.type === "recipe"}
      {translate("TEMPLATE_RECIPE")}
    {/if}
  </h3>

  <div>{translate("TEMPLATE_NOTE")}</div>

  <div class="flex justify-center gap-4">
    <ButtonComponent isOutline on:click={onClose}>{translate("CLOSE")}</ButtonComponent>
    <ButtonComponent on:click={onCreateCopy}>{translate("CREATE_COPY")}</ButtonComponent>
  </div>
</div>
