<script lang="ts">
  import { onMount } from "svelte";
  import { fade, slide } from "svelte/transition";
  import {
    Button,
    Heading,
    Helper,
    Input,
    Label,
    NumberInput,
    Select,
    Spinner,
    Textarea,
  } from "flowbite-svelte";
  import { api, getWithJwt, postWithJwt, translate } from "lib";
  import { dialogData, filtersStore2, showAlert, user } from "stores";
  import {
    trainerExercises,
    trainerPrograms,
    trainerWorkouts,
  } from "../../../stores/trainerStores";
  import SetEditType from "../../../components/Diet/SetEditType.svelte";
  import InfiniteScroll from "../../../components/UI/InfiniteScroll.svelte";
  import Filter from "../../../components/Filter.svelte";
  import { exercisesCount } from "../../../stores/exercisesCount";
  import { putWithJwt } from "../../../lib/requests";
  import {
    muscleGroupRequestMap,
    serverlessRoutes,
  } from "../../../lib/constants";
  import { loadedWorkouts } from "../../../stores/loadedWorkoutsStore";
  import { ButtonComponent, ButtonGroup } from "ui";

  let data: any;

  const defaultSrc = "https://img.youtube.com/vi/null/hqdefault.jpg";
  const dialogType = data.exercise ? translate("EDIT") : translate("CREATE");
  const youtube = { src: "", name: "", description: "" };
  const exercise = { src: "", name: "", description: "" };

  let selectedExerciseId: number | null = null;
  let isLoading = false;
  let isYoutube = false;
  let isExpanded = false;
  let disabled = true;
  let exercisesElem: HTMLDivElement;
  let youtubeId = "";
  let timeout: NodeJS.Timeout;
  let page = 0;

  // const selectedTypes = data.exercise
  //   ? data.exercise.muscleGroups
  //       .split(",")
  //       .map((tag: any) => reverseMuscleGroupRequest.get(tag))
  //   : [];
  const selectedTypes = [];

  const items = [
    { value: "Endurance", name: translate("ENDURANCE") },
    { value: "Strength", name: translate("STRENGTH") },
    { value: "Cardio", name: translate("CARDIO") },
    { value: "Timed Longer Better", name: translate("TIMED_LONGER_BETTER") },
    { value: "Timed Faster Better", name: translate("TIMED_FASTER_BETTER") },
    { value: "Timed Strength", name: translate("TIMED_STRENGTH") },
  ];

  const form = {
    youtube: { value: "", error: "" },
    exercise: { value: "", error: "" },
    name: { value: "", error: "" },
    type: { value: "Endurance", error: "" },
    reps: { value: "10", error: "" },
    sets: { value: 3, error: "" },
    rest: { value: "1 min", error: "" },
    time: { value: "3 min", error: "" },
    distance: { value: "500 m", error: "" },
    muscleGroups: { value: [] as Array<string>, error: "" },
    description: { value: "", error: "" },
  };

  const youtubeRegex =
    /^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed|shorts)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]{7,15})(?:[\?&][a-zA-Z0-9\_-]+=[a-zA-Z0-9\_-]+)*(?:[&\/\#].*)?$/;

  const createUrl = (): string => {
    let template = "";
    let sort = "";
    let name = "";
    let muscleGroup = "";

    if ($filtersStore2.template === 1) {
      template = "&default=1&template=1";
    } else {
      template = "&default=1&template=0";
    }

    if ($filtersStore2.sort !== undefined) {
      if ($filtersStore2.sort === "NAME_ASC") {
        sort = "&orderBy=name&order=ASC";
      } else if ($filtersStore2.sort === "NAME_DESC") {
        sort = "&orderBy=name&order=DESC";
      } else if ($filtersStore2.sort === "DATE_ASC") {
        sort = "&orderBy=id&order=ASC";
      } else {
        sort = "&orderBy=id&order=DESC";
      }
    }

    if (form.exercise.value) {
      name = `&name=${form.exercise.value}`;
    }

    if ($filtersStore2.tags.length) {
      muscleGroup = `&muscleGroup=%5B${$filtersStore2.tags
        .map((filter): string => `%22${muscleGroupRequestMap.get(filter)}%22`)
        .join(",")}%5D`;
    }

    return `${api}/exercise?take=15&skip=${
      page * 15
    }${template}${name}${muscleGroup}${sort}`;
  };

  const onFetchData = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    isLoading = true;
    page = 0;

    try {
      const response = await getWithJwt(createUrl());
      $trainerExercises = response.data;
      $exercisesCount = response.count;
    } catch (error) {
      console.error(error);
    }

    isLoading = false;
  };

  const onLoadMore = async (): Promise<void> => {
    if (!$user) {
      return;
    }

    isLoading = true;
    page += 1;

    try {
      const response = await getWithJwt(createUrl());
      $trainerExercises = [...$trainerExercises, ...response.data];
    } catch (error) {
      console.error(error);
    }

    isLoading = false;
  };

  const onYoutubeScreen = (): void => {
    isYoutube = true;
    form.name.value = youtube.name;
    form.description.value = youtube.description;

    onInput("name");
    onInput("description");
  };

  const onExercisesScreen = (): void => {
    isYoutube = false;
    form.name.value = exercise.name;
    form.description.value = exercise.description;

    onInput("name");
    onInput("description");
  };

  const onExpandExercises = (): void => {
    isExpanded = true;
  };

  const onCollapseExercises = (): void => {
    isExpanded = false;
  };

  const onSelectExercise = async (item: any) => {
    isExpanded = false;

    try {
      const response = await getYoutubeData(item.youtubeId);

      youtubeId = response.id;

      exercise.src = response.snippet.thumbnails.medium.url;
      exercise.name = response.snippet.title;
      exercise.description = response.snippet.description;

      form.name.value = exercise.name;
      form.description.value = exercise.description;

      selectedExerciseId = item.id;

      onInput("name");
      onInput("description");
    } catch (error) {
      console.error(error);
    }
  };

  const updateExerciseFromProgramState = (exercise, updatedExercise) => {
    if ($trainerExercises.find(({ id }) => id === exercise.id)) {
      $trainerExercises = $trainerExercises.filter(
        ({ id }) => id !== exercise.id
      );
      $trainerExercises = [...$trainerExercises, updatedExercise];
      $exercisesCount -= 1;
      return;
    }
    const index =
      $loadedWorkouts[exercise.workoutId].exercises.indexOf(exercise);

    $loadedWorkouts[exercise.workoutId].exercises.splice(
      index,
      1,
      updatedExercise
    );
    $loadedWorkouts = $loadedWorkouts;
  };

  const onTypes = (event: CustomEvent<Array<string>>): void => {
    form.muscleGroups.value = event.detail;
  };

  const getYoutubeData = async (id: any): Promise<any> => {
    try {
      const response = await getWithJwt(`${api}/exercise/youtube-data/${id}`);
      return response.items[0];
    } catch (error) {
      console.error(error);
    }
  };

  const onInput = async (field: keyof typeof form): Promise<void> => {
    if (field === "youtube") {
      const isValid = youtubeRegex.test(form.youtube.value);

      if (isValid) {
        let id: string;

        if (form.youtube.value.includes("shorts")) {
          id = form.youtube.value.split("shorts/")[1];
        } else {
          id = form.youtube.value.split("watch?v=")[1];
        }

        try {
          const response = await getYoutubeData(id);

          youtubeId = response.id;

          youtube.src = response.snippet.thumbnails.medium.url;
          youtube.name = response.snippet.title;
          youtube.description = response.snippet.description;

          form.name.value = youtube.name;
          form.description.value = youtube.description;

          onInput("name");
          onInput("description");

          form.youtube.error = "";
        } catch (error) {
          console.error(error);
        }
      } else {
        form.youtube.error = translate("YOUTUBE_LINK_INVALID");
      }
    }

    if (field === "exercise") {
      clearTimeout(timeout);
      timeout = setTimeout(async (): Promise<void> => {
        onFetchData();
      }, 1000);
    }

    if (field === "name") {
      if (form.name.value.length === 0) {
        form.name.error = translate("FIELD_REQUIRED");
      } else if (form.name.value.length < 2) {
        form.name.error = translate("FIELD_MINIMUM_2");
      } else if (form.name.value.length > 320) {
        form.name.error = translate("FIELD_MAXIMUM_320");
      } else {
        form.name.error = "";
      }
    }

    if (field === "reps") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "sets") {
      if (form[field].value < 0) {
        form[field].error = translate("FIELD_MINIMUM_AMOUNT_0");
      } else if (form[field].value > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_AMOUNT_5000");
      } else {
        form[field].error = "";
      }
    }

    if (field === "rest") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "time") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "distance") {
      if (form[field].value.length > 10) {
        form[field].error = translate("FIELD_MAXIMUM_10");
      } else {
        form[field].error = "";
      }
    }

    if (field === "description") {
      if (form[field].value.length > 5000) {
        form[field].error = translate("FIELD_MAXIMUM_5000");
      } else {
        form[field].error = "";
      }
    }

    const formKeys = Object.keys(form) as Array<keyof typeof form>;

    // mora timeout jer mi treba form.type.value a on se refresuje posle onInput
    setTimeout((): void => {
      disabled = formKeys.some((key): boolean => {
        if (
          key === "reps" ||
          key === "sets" ||
          key === "rest" ||
          key === "time" ||
          key === "distance"
        ) {
          if (
            form.type.value === "Endurance" ||
            form.type.value === "Strength"
          ) {
            if (key === "reps" || key === "sets" || key === "rest") {
              return form[key].error !== "";
            } else {
              return false;
            }
          } else if (form.type.value === "Cardio") {
            if (key === "time" || key === "distance") {
              return form[key].error !== "";
            } else {
              return false;
            }
          } else {
            if (key === "time" || key === "sets" || key === "rest") {
              return form[key].error !== "";
            } else {
              return false;
            }
          }
        } else {
          return form[key].error !== "";
        }
      });
    }, 100);
  };

  const onSubmit = async (): Promise<void> => {
    isLoading = true;

    const name = form.name.value;
    const type = form.type.value;
    const reps = form.reps.value;
    const sets = form.sets.value;
    const rest = form.rest.value;
    const time = form.time.value;
    const distance = form.distance.value;

    const muscleGroups = form.muscleGroups.value.map((tag) =>
      muscleGroupRequestMap.get(tag)
    );

    const description = form.description.value;

    let details = {};

    if (type === "Strength" || type === "Endurance") {
      details = { reps, sets, rest };
    } else if (type === "Cardio") {
      details = { time, distance };
    } else {
      details = { time, sets, rest };
    }

    try {
      if (!data.exercise && !data.workoutId) {
        // create
        const response = await postWithJwt(`${api}/exercise`, {
          exercise: {
            name,
            type,
            youtubeId,
            description,
            details,
            muscleGroups,
          },
          ownerType: "USER",
        });
        $trainerExercises = [response, ...$trainerExercises];
        $exercisesCount += 1;
      } else if (data.exercise && !data.workoutId) {
        // edit in exercises
        const id = data.exercise.id;

        const response = await putWithJwt(`${api}/exercise/${id}`, {
          id,
          name,
          type,
          youtubeId,
          description,
          details,
          muscleGroups,
        });

        updateExerciseFromProgramState(data.exercise, response);
      } else if (!data.exercise && data.workoutId) {
        // import in workout
        if (!selectedExerciseId) {
          return;
        }
        const details = {
          time: form.time.value,
          sets: form.sets.value,
          rest: form.rest.value,
          reps: form.reps.value,
          distance: form.distance.value,
        };
        const response = await postWithJwt(
          `${serverlessRoutes.EXERCISE}/copy`,
          {
            exerciseIds: [selectedExerciseId],
            propertiesToOverride: {
              // name,
              // type,
              // youtubeId,
              // description,
              // details,
              // muscleGroups,
              workoutId: data.workoutId,
              parentExerciseId: selectedExerciseId,
              position: data.position,
              details: JSON.stringify(details),
              default: 0,
              isTemplate: 0,
              trainerId: null,
            },
          }
        );
        if (
          $loadedWorkouts[data.workoutId] &&
          $loadedWorkouts[data.workoutId].exercises
        ) {
          $loadedWorkouts[data.workoutId].exercises = [
            ...response.data,
            ...$loadedWorkouts[data.workoutId].exercises,
          ];
          $loadedWorkouts[data.workoutId].exercises = $loadedWorkouts[
            data.workoutId
          ].exercises.sort((a, b) => a.position - b.position);
        }
        if (data.programId) {
          // inside program
          const _program = $trainerPrograms.find(
            (_program): boolean => _program.id === data.programId
          );

          if (_program?.workouts) {
            const _workout = _program.workouts.find(
              (_workout: any): boolean => _workout.id === data.workoutId
            );

            _workout.exercises.push(response);
            data.executeFunction();
          }
        } else {
          // const _workout = $trainerWorkouts.find(
          //   (_workout): boolean => _workout.id === data.workoutId
          // );
          // _workout.exercises.push(response.data[0]);
          // $trainerWorkouts = $trainerWorkouts;
        }
      } else {
        // edit in workout
        const id = data.exercise.id;
        const response = await putWithJwt(`${api}/exercise/${id}`, {
          id,
          name,
          type,
          youtubeId,
          description,
          details,
          muscleGroups,
        });
        updateExerciseFromProgramState(data.exercise, response);
      }

      $showAlert.color = "black";

      if (data.exercise) {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_EDITED")} ${translate("EXERCISE")}
        `;
      } else {
        $showAlert.message = `
          ${translate("SUCCESSFULLY_CREATED")} ${translate("EXERCISE")}
        `;
      }

      $dialogData.type = "";
      $dialogData.data = {};
    } catch (error) {
      console.error(error);
      isLoading = false;
      $showAlert.color = "red-400";

      if (data.exercise) {
        $showAlert.message = translate("ERROR_EDITING_EXERCISE");
      } else {
        $showAlert.message = translate("ERROR_CREATING_EXERCISE");
      }
    }
  };

  onMount(async (): Promise<void> => {
    if (data.exercise) {
      const { name, type, muscleGroups, description, details } = data.exercise;

      if (name) {
        form.name.value = name;
        onInput("name");
      }

      if (type) {
        form.type.value = type;
        onInput("type");
      }

      if (muscleGroups) {
        form.muscleGroups.value = muscleGroups;
        onInput("muscleGroups");
      }

      if (description) {
        form.description.value = description;
        onInput("description");
      }

      if (details) {
        const detailKeys = Object.keys(data.exercise.details) as [
          "time",
          "sets",
          "rest",
          "reps",
          "distance",
        ];

        detailKeys.forEach((key): void => {
          form[key].value = data.exercise.details[key];
          onInput(key);
        });
      }

      try {
        const response = await getYoutubeData(data.exercise.youtubeId);

        youtubeId = response.id;

        exercise.src = response.snippet.thumbnails.medium.url;
        exercise.name = response.snippet.title;
        exercise.description = response.snippet.description;
      } catch (error) {
        console.error(error);
      }
    }

    await onFetchData();
  });

  export { data };
</script>

<div class="p-4 flex flex-col gap-4">
  <Heading align="center" tag="h6">
    {dialogType}
    {translate("EXERCISE").toLowerCase()}
  </Heading>

  <form class="flex flex-col gap-4" on:submit|preventDefault={onSubmit}>
    <div class="flex justify-center">
      <ButtonGroup
        buttons={[
          { title: "Youtube", onClick: onYoutubeScreen },
          { title: translate("MY_EXERCISES"), onClick: onExercisesScreen },
        ]}
      />
    </div>

    {#if isYoutube}
      <div>
        <Label for="youtube">
          {translate("YOUTUBE_LINK")}:
        </Label>
        <Input
          id="youtube"
          placeholder={translate("YOUTUBE_LINK")}
          bind:value={form.youtube.value}
          on:input={() => onInput("youtube")}
        />
        <Helper color="red">
          {#if form.youtube.error}
            {form.youtube.error}
          {:else}
            ㅤ
          {/if}
        </Helper>
      </div>
    {:else}
      <div>
        <Label for="exercise">{translate("EXERCISE")}:</Label>
        <Input
          id="exercise"
          placeholder={translate("EXERCISE")}
          bind:value={form.exercise.value}
          on:input={() => onInput("exercise")}
          on:click={onExpandExercises}
        />
        {#if isExpanded}
          <div
            class="mt-2 p-2 flex flex-col gap-4 border rounded-md"
            in:slide
            out:slide
          >
            <Filter type="EXERCISES" on:fetchData={onFetchData} />

            <div
              class="h-64 flex flex-col gap-2 overflow-y-scroll"
              bind:this={exercisesElem}
            >
              {#each $trainerExercises as exercise}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div
                  class="flex items-center gap-2 border rounded-md bg-slate-100 dark:bg-zinc-800 dark:border-zinc-500"
                  on:click={() => onSelectExercise(exercise)}
                >
                  <img
                    class="h-12 rounded-l-md"
                    src="https://i.ytimg.com/vi/{exercise.youtubeId}/mqdefault.jpg"
                    alt="Youtube"
                  />
                  <div class="text-xs">{exercise.name}</div>
                </div>
              {/each}

              <InfiniteScroll
                hasMore={$trainerExercises.length <= $exercisesCount}
                on:loadMore={onLoadMore}
              />
            </div>

            <div class="flex justify-center">
              <ButtonComponent isOutline on:click={onCollapseExercises}>
                {translate("CLOSE")}
              </ButtonComponent>
            </div>
          </div>
        {/if}
      </div>
    {/if}

    {#if isYoutube}
      {#if youtube.src}
        <img src={youtube.src} alt="Exercise" />
      {:else}
        <img src={defaultSrc} alt="Exercise" />
      {/if}
    {:else if exercise.src}
      <img src={exercise.src} alt="Exercise" />
    {:else}
      <img src={defaultSrc} alt="Exercise" />
    {/if}

    <div>
      <Label for="name">{translate("NAME")}:</Label>
      <Input
        id="name"
        placeholder={translate("NAME")}
        bind:value={form.name.value}
        on:input={() => onInput("name")}
      />
      <Helper color="red">
        {#if form.name.error}
          {form.name.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div>
      <Label for="type">{translate("TYPE")}:</Label>
      <Select
        id="type"
        placeholder={translate("TYPE")}
        {items}
        bind:value={form.type.value}
      />
    </div>

    <div class="flex-row gap-4">
      {#if form.type.value === "Strength" || form.type.value === "Endurance"}
        <div>
          <Label for="repetition">
            {translate("REPETITION")}:
          </Label>
          <Input
            id="repetition"
            placeholder={translate("REPETITION")}
            bind:value={form.reps.value}
            on:input={() => onInput("reps")}
          />
          <Helper color="red">
            {#if form.reps.error}
              {form.reps.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>

        <div>
          <Label for="series">{translate("SERIES")}:</Label>
          <NumberInput
            id="series"
            placeholder={translate("SERIES")}
            bind:value={form.sets.value}
            on:input={() => onInput("sets")}
          />
          <Helper color="red">
            {#if form.sets.error}
              {form.sets.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>

        <div>
          <Label for="break">{translate("BREAK")}:</Label>
          <Input
            id="break"
            placeholder={translate("BREAK")}
            bind:value={form.rest.value}
            on:input={() => onInput("rest")}
          />
          <Helper color="red">
            {#if form.rest.error}
              {form.rest.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>
      {:else if form.type.value === "Cardio"}
        <div>
          <Label for="time">{translate("TIME")}:</Label>
          <Input
            id="time"
            placeholder={translate("TIME")}
            bind:value={form.time.value}
            on:input={() => onInput("time")}
          />
          <Helper color="red">
            {#if form.time.error}
              {form.time.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>

        <div>
          <Label for="distance">{translate("DISTANCE")}:</Label>
          <Input
            id="distance"
            placeholder={translate("DISTANCE")}
            bind:value={form.distance.value}
            on:input={() => onInput("distance")}
          />
          <Helper color="red">
            {#if form.distance.error}
              {form.distance.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>
      {:else if form.type.value === "Timed Longer Better" || form.type.value === "Timed Faster Better" || form.type.value === "Timed Strength"}
        <div>
          <Label for="time">
            {translate("TIME")}:
          </Label>
          <Input
            id="time"
            placeholder={translate("TIME")}
            bind:value={form.time.value}
            on:input={() => onInput("time")}
          />
          <Helper color="red">
            {#if form.time.error}
              {form.time.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>

        <div>
          <Label for="series">{translate("SERIES")}:</Label>
          <NumberInput
            id="series"
            placeholder={translate("SERIES")}
            bind:value={form.sets.value}
            on:input={() => onInput("sets")}
          />
          <Helper color="red">
            {#if form.sets.error}
              {form.sets.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>

        <div>
          <Label for="break">{translate("BREAK")}:</Label>
          <Input
            id="break"
            placeholder={translate("BREAK")}
            bind:value={form.rest.value}
            on:input={() => onInput("rest")}
          />
          <Helper color="red">
            {#if form.rest.error}
              {form.rest.error}
            {:else}
              ㅤ
            {/if}
          </Helper>
        </div>
      {/if}
    </div>

    <div>
      <Label class="text-xs">Muscle group type:</Label>
      <SetEditType
        types={[
          "Trbuh",
          "Biceps",
          "Listovi",
          "Grudi",
          "Podlaktica",
          "Zadnjica",
          "Zadnja loža",
          "Donja leđa",
          "Prednja loža",
          "Ramena",
          "Triceps",
          "Gornja leđa",
        ]}
        {selectedTypes}
        on:selectedTypes={onTypes}
      />
    </div>

    <div>
      <Label for="description">
        {translate("DESCRIPTION")}:
      </Label>
      <Textarea
        id="description"
        placeholder={translate("DESCRIPTION")}
        rows="4"
        bind:value={form.description.value}
        on:input={() => onInput("description")}
      />
      <Helper color="red">
        {#if form.description.error}
          {form.description.error}
        {:else}
          ㅤ
        {/if}
      </Helper>
    </div>

    <div class="h-10 flex justify-center">
      {#if isLoading}
        <Spinner size="10" color="green" />
      {:else}
        <ButtonComponent type="submit" {disabled}>{dialogType}</ButtonComponent>
      {/if}
    </div>
  </form>
</div>
