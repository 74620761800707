<script lang="ts">
  import { onMount } from "svelte";
  import { currentClient, dialogData, showAlert, user } from "stores";
  import { getWithJwt, postWithJwt, serverlessRoutes, translate } from "lib";
  import ButtonComponent from "../../../components/UI/ButtonComponent.svelte";
  import Svg from "../../../components/UI/Svg.svelte";
  import { generateStats } from "../../../lib/generateStats";
  import { stats } from "../../../stores/finishWorkoutStats";

  export let data: any;

  let indexInStatsArr: number[] = [];
  let history = [];

  stats.subscribe((value) => {
    if (value.length === 0) return;
    // if (value.length === 0 || indexInStatsArr.length > 0) return;
    if (data.exercise.modelType === "superset") {
      data.exercise.supersetExercises.forEach((ex) => {
        const i = value.findIndex((stat) => stat.exerciseId === ex.id);
        indexInStatsArr.push(i);
      });
    } else {
      indexInStatsArr = [
        value.findIndex((stat) => stat.exerciseId === data.exercise.id),
      ];
    }
  });

  const addSet = (index) => {
    if (indexInStatsArr.length === 0) return;
    $stats[index].stats = [
      ...$stats[index].stats,
      $stats[index].stats[$stats[index].stats.length - 1],
    ];
  };

  const fetchHistory = async () => {
    if (!data.exercise.parentExerciseId) return;
    const url = `${serverlessRoutes.EXERCISE}/history/${$currentClient?.id || $user.id}/${data.exercise.parentExerciseId}`;
    try {
      const res = await getWithJwt(url);
      if (res.data.length > 0) {
        history = res.data[0];
      }
    } catch (err) {}
  };

  onMount(() => {
    fetchHistory();
    generateStats(data.exercise.workoutId);
  });
</script>

<div class="flex-col text-center items-center">
  {#if indexInStatsArr.length > 0}
    {#each indexInStatsArr as indexInStats}
      {#if $stats.length > 0 && indexInStats !== null && indexInStats !== -1}
        <h3 class="text-md mt-4 mb-4">
          {translate("ENTER_RESULTS_FOR")}:
          <span class="font-semibold">{$stats[indexInStats].name}</span>
        </h3>

        <!-- {#each $stats[indexInStats].stats as stat} -->
        <div class="w-full mb-4">
          <div class="flex flex-row justify-around">
            <div class="flex flex-col">
              <div class="font-medium mb-2 flex flex-row">
                Serija
                {#if $stats[indexInStats].stats.length > 0}
                  <div
                    on:click={() => addSet(indexInStats)}
                    class="font-medium ml-1 mt-1 cursor-pointer"
                  >
                    <Svg name="plus" size={16} />
                  </div>
                {/if}
              </div>
              {#each $stats[indexInStats].stats as set, index}
                <div class="font-medium mt-1">{index + 1}</div>
              {/each}
            </div>

            <div class="flex flex-col">
              <div class="font-medium mb-2">Prethodno</div>
              {#if history.length > 0}
                {#each history as exerciseHistory}
                  <div class="font-medium mt-1">
                    {exerciseHistory.weight} kg x {exerciseHistory.reps}
                  </div>
                {/each}
              {/if}
            </div>

            <div class="flex flex-col">
              <div class="font-medium mb-2">{translate("WEIGHT")}</div>
              {#each $stats[indexInStats].stats as set, index}
                <input
                  bind:value={set.weight}
                  type="number"
                  class="w-12 h-6 border-0 rounded-md bg-slate-100 dark:bg-zinc-950 font-medium mt-1"
                />
              {/each}
            </div>

            <div>
              <div class="font-medium">{translate("REPS")}</div>
              <div class="flex flex-col gap-1 mt-3 items-center">
                {#each $stats[indexInStats].stats as set, index}
                  <input
                    bind:value={set.reps}
                    type="number"
                    class="w-12 h-6 border-0 rounded-md bg-slate-100 dark:bg-zinc-950 font-medium"
                  />
                {/each}
              </div>
            </div>
          </div>
        </div>
        <!-- {/each} -->
      {/if}
    {/each}
  {/if}
  <ButtonComponent on:click={() => ($dialogData = {})}
    >{translate("SUBMIT")}</ButtonComponent
  >
  <div class="mt-4"></div>
</div>

<style>
  ::placeholder {
    color: silver;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: silver;
  }
</style>
