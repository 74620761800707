// ovo moze da bude problematicno jer stores importuju iz lib, a lib iz stores
// najbolje je da se ova funkcija ubaci direktno u store, lib ne bi trebalo
// da importuje nista iz stores
import { loadedMealsStore } from "stores";

const calculateMacros = (): void => {
  loadedMealsStore.update((store) => {
    store.forEach((meal): void => {
      let recipeCarbs = 0;
      let recipeFats = 0;
      let recipeProtein = 0;
      let recipeCalories = 0;
      let ingredientCarbs = 0;
      let ingredientFats = 0;
      let ingredientProtein = 0;
      let ingredientCalories = 0;

      if (meal.recipes) {
        meal.recipes.forEach((recipe) => {
          const recipeCarbs = recipe.ingredients.reduce((carbs, recipe) => carbs += recipe.carbs, 0);
          const recipeFats = recipe.ingredients.reduce((fats, recipe) => fats += recipe.fats, 0);
          const recipeProtein = recipe.ingredients.reduce((protein, recipe) => protein += recipe.protein, 0);
          const recipeCalories = recipe.ingredients.reduce((calories, recipe) => calories += recipe.calories, 0);

          recipe.carbs = recipeCarbs;
          recipe.fats = recipeFats;
          recipe.protein = recipeProtein;
          recipe.calories = recipeCalories;
        });

        recipeCarbs = meal.recipes.reduce((carbs, recipe) => carbs += recipe.carbs, 0);
        recipeFats = meal.recipes.reduce((fats, recipe) => fats += recipe.fats, 0);
        recipeProtein = meal.recipes.reduce((protein, recipe) => protein += recipe.protein, 0);
        recipeCalories = meal.recipes.reduce((calories, recipe) => calories += recipe.calories, 0);
      }

      if (meal.ingredients) {
        ingredientCarbs = meal.ingredients.reduce((carbs, ingredient) => carbs += ingredient.carbs, 0);
        ingredientFats = meal.ingredients.reduce((fats, ingredient) => fats += ingredient.fats, 0);
        ingredientProtein = meal.ingredients.reduce((protein, ingredient) => protein += ingredient.protein, 0);
        ingredientCalories = meal.ingredients.reduce((calories, ingredient) => calories += ingredient.calories, 0);
      }

      const mealCarbs = recipeCarbs + ingredientCarbs;
      const mealFats = recipeFats + ingredientFats;
      const mealProtein = recipeProtein + ingredientProtein;
      const mealCalories = recipeCalories + ingredientCalories;

      meal.carbs = mealCarbs;
      meal.fats = mealFats;
      meal.protein = mealProtein;
      meal.calories = mealCalories;
    });

    return store;
  });
};

const calculateRecipeMacros = (recipe: any): void => {
  if (!recipe.ingredients) {
    return;
  }

  const {
    protein,
    carbs,
    fats,
    calories
  } = recipe.ingredients.reduce((macros: any, ingredient: any) => {
    macros.protein += ingredient.protein;
    macros.carbs += ingredient.carbs;
    macros.fats += ingredient.fats;
    macros.calories += ingredient.calories;

    return macros;
  }, {
    protein: 0,
    carbs: 0,
    fats: 0,
    calories: 0
  });

  recipe.protein = protein;
  recipe.carbs = carbs;
  recipe.fats = fats;
  recipe.calories = calories;
};

export {calculateMacros, calculateRecipeMacros};
