import {writable} from "svelte/store";

const countersStore = writable({
  clientPayments: 0,
  clientGoals: 0,
  clientMealPlans: 0,

  trainerClientPrograms: 0,
});

export {countersStore};
