<script lang="ts">
  import { dialogData, selectedIds } from "stores";

  const isCloseableClass = $dialogData.isCloseable ?
    "bg-black/50" :
    "backdrop-blur";

  const onClose = (): void => {
    const condition = $dialogData.isCloseable != null ?
      $dialogData.isCloseable :
      true;

    if (condition) {
      $selectedIds = [];

      $dialogData.type = "";
      $dialogData.data = {};
      $dialogData.isCloseable = true;
    }
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="fixed h-full w-full top-0 left-0 z-[2] {isCloseableClass}"
  on:click="{onClose}"
></div>
