<script lang="ts">
  import { Heading, Input, Label } from "flowbite-svelte";
  import { onMount } from "svelte";
  import { api } from "../../../lib/constants";
  import { postFormDataWithJwt } from "../../../lib/requests";
  import { translate } from "../../../lib/translate";
  import { dialogData } from "../../../stores/dialogDataStore";
  import { showAlert } from "../../../stores/showAlertStore";
  import { user } from "../../../stores/userStore";
  import DatePicker from "../../UI/DatePicker.svelte";
  import { ButtonComponent, InputField } from "ui";

  export let data: any;

  let name: string;
  let description: string;
  let date: string;

  onMount(() => {
    if (data.name) {
      name = data.name;
    }
    if (data.description) {
      description = data.description;
    }
  });
  const submit = async () => {
    const url = `${api}/diet_journal/meal`;
    const meal = {
      name,
      description,
      entityType: 0,
      mealPlanId: data.mealPlanId,
      showMacros: true,
      finishedAt: date
        ? new Date(date).toISOString()
        : new Date().toISOString(),
    };
    const formData = new FormData();
    formData.append("clientId", `${$user.id}`);
    formData.append("mealPlanId", data.mealPlanId);
    formData.append("meal", JSON.stringify(meal));
    try {
      const res = await postFormDataWithJwt(url, formData);
      data.fetchData();
      $dialogData = {};
      $showAlert.message = `${translate("SUCCESSFULLY_CREATED")} ${translate(
        "MEAL"
      ).toLowerCase()}.`;
    } catch (err) {}
  };
</script>

<div class="flex-col">
  <div class="text-center mt-3 mb-3">
    <Heading tag="h3" customSize="text-lg font-normal"
      >{data.executeFunction ? translate("EDIT") : translate("CREATE")}
      {translate("MEAL").toLowerCase()}</Heading
    >
  </div>
  <div class="p-4">
    <Label for="name" class="mb-2">{translate("TITLE")}:</Label>
    <Input
      type="text"
      id="name"
      placeholder={translate("DINNER")}
      bind:value={name}
      required
    />
  </div>
  <div class="pl-4 pr-4">
    <Label for="description" class="mb-2">{translate("DESCRIPTION")}:</Label>
    <Input type="text" id="description" bind:value={description} />
  </div>
  <div class="pt-4 pl-4 pr-4">
    <InputField
      label={translate("DATE")}
      type="date"
      error={""}
      bind:value={date}
    />
    <!-- <DatePicker bind:date title="DATE" /> -->
  </div>
  <div class="flex justify-center mb-6">
    <ButtonComponent
      on:click={() =>
        data.executeFunction
          ? data.executeFunction({ name, description })
          : submit()}
      >{data.executeFunction ? translate("EDIT") : translate("CREATE")}</ButtonComponent
    >
  </div>
</div>

<style>
</style>
