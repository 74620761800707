import {get, writable} from "svelte/store";
import {filtersStore2, user} from "stores";
import {getWithJwt, serverlessRoutes} from "lib";
import {muscleGroupRequestMap} from "../lib/constants";

interface TrainerExercisesStore {
  data: Array<any>;
  count: number;
  hasMore: boolean;
  isFetching: boolean;
  isFetchingMore: boolean;
  page: number;
  searchValue: string;
  searchTimeout: NodeJS.Timeout | undefined;
}

const trainerExercisesStoreCreate = () => {
  const {set, subscribe, update} = writable<TrainerExercisesStore>({
    data: [],
    count: 0,
    hasMore: false,
    isFetching: false,
    isFetchingMore: false,
    page: 0,
    searchValue: "",
    searchTimeout: undefined
  });

  const createUrl = (): string => {
    const {page, searchValue} = get({subscribe});
    const filters = get(filtersStore2);

    let template = "";
    let sort = "";
    let name = "";
    let muscleGroup = "";

    if (filters.template === 1) {
      template = "&default=1&template=1";
    } else {
      template = "&default=1&template=0";
    }

    if (filters.sort !== undefined) {
      if (filters.sort === "NAME_ASC") {
        sort = "&orderBy=name&order=ASC";
      } else if (filters.sort === "NAME_DESC") {
        sort = "&orderBy=name&order=DESC";
      } else if (filters.sort === "DATE_ASC") {
        sort = "&orderBy=id&order=ASC";
      } else {
        sort = "&orderBy=id&order=DESC";
      }
    }

    if (searchValue) {
      name = `&name=${searchValue}`;
    }

    if (filters.tags.length) {
      muscleGroup = `&muscleGroup=%5B${filters.tags
        .map((filter): string => `%22${muscleGroupRequestMap.get(filter)}%22`)
        .join(",")}%5D`;
    }

    return `${serverlessRoutes.EXERCISE}/list?take=10&skip=${
      page * 10
    }${template}${name}${muscleGroup}${sort}`;
  };

  const fetchData = async (): Promise<void> => {
    if (!get(user)) {
      return;
    }

    update((store) => {
      store.isFetching = true;
      store.page = 0;
      return store;
    });

    try {
      const response = await getWithJwt(createUrl());
      const {data, count} = response.data;
      const exercises = data;

      update((store) => {
        store.data = data;
        store.count = count;
        store.hasMore = store.data.length < store.count;

        return store;
      });

      localStorage.setItem(
        "trainerExercisesCache",
        JSON.stringify({exercises, count})
      );
    } catch (error) {
      console.error(error);
    } finally {
      update((store) => {
        store.isFetching = false;
        return store;
      });
    }
  };

  const fetchMoreData = async (): Promise<void> => {
    if (!get(user)) {
      return;
    }

    update((store) => {
      store.isFetchingMore = true;
      store.page += 1;
      return store;
    });

    try {
      const response = await getWithJwt(createUrl());
      const {data} = response.data;

      update((store) => {
        store.data = [...store.data, ...data];
        store.hasMore = store.data.length < store.count;
        return store;
      });
    } catch (error) {
      console.error(error);
    } finally {
       update((store) => {
        store.isFetchingMore = false;
        return store;
      });
    }
  };

  const search = (): void => {
    update((store) => {
      clearTimeout(store.searchTimeout);
      store.searchTimeout = setTimeout(fetchData, 1000);
      return store;
    });
  };

  return {set, subscribe, update, fetchData, fetchMoreData, search};
};

const trainerExercisesStore = trainerExercisesStoreCreate();

export {trainerExercisesStore};
