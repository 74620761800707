<script lang="ts">
  import { onMount } from "svelte";
  import {
    calculateMacros,
    calculateRecipeMacros,
    patchWithJwt,
    postWithJwt,
    serverlessRoutes,
    translate,
  } from "lib";

  import {
    dialogData,
    loadedMealsStore,
    showAlert,
    trainerIngredientsStore,
    trainerRecipes,
  } from "stores";

  import {
    ButtonComponent,
    InputField,
    SelectField,
    TextareaField,
    ToggleField,
  } from "ui";
  import SetEditType from "../../Diet/SetEditIngredientType.svelte";
  import { currentMealPlan } from "../../../stores/trainerStores";

  let form = {
    name: {
      value: "",
      error: "",
    },
    foodType: {
      value: [],
      error: "",
    },
    measurementUnit: {
      value: "",
      error: "",
    },
    amountOnUnit: {
      value: 1,
      error: "",
    },
    carbs: {
      value: 0,
      error: "",
    },
    protein: {
      value: 0,
      error: "",
    },
    fats: {
      value: 0,
      error: "",
    },
    calculateCaloriesAutomatically: {
      value: false,
      error: "",
    },
    calories: {
      value: 0,
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
  };

  let disabled = true;

  const validateField = (fieldName: keyof typeof form): void => {
    if (fieldName === "name") {
      const fieldValue = form[fieldName].value;

      if (fieldValue.length === 0) {
        form[fieldName].error = "FIELD_REQUIRED";
      } else if (fieldValue.length < 2) {
        form[fieldName].error = "FIELD_MINIMUM_2";
      } else if (fieldValue.length > 320) {
        form[fieldName].error = "FIELD_MAXIMUM_320";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "amountOnUnit") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 1) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_1";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "carbs") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "protein") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "fats") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 1000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_1000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "calories") {
      const fieldValue = form[fieldName].value;

      if (fieldValue < 0) {
        form[fieldName].error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (fieldValue > 17000) {
        form[fieldName].error = "FIELD_MAXIMUM_AMOUNT_17000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (fieldName === "description") {
      const fieldValue = form[fieldName].value;

      if (fieldValue.length > 5000) {
        form[fieldName].error = "FIELD_MAXIMUM_5000";
      } else {
        form[fieldName].error = "";
      }
    }

    if (form.calculateCaloriesAutomatically.value) {
      const carbs = form.carbs.value || 0;
      const protein = form.protein.value || 0;
      const fats = form.fats.value || 0;

      form.calories.value = carbs * 4 + protein * 4 + fats * 9;

      if (form.calories.value < 0) {
        form.calories.error = "FIELD_MINIMUM_AMOUNT_0";
      } else if (form.calories.value > 17000) {
        form.calories.error = "FIELD_MAXIMUM_AMOUNT_17000";
      } else {
        form.calories.error = "";
      }
    }

    disabled = Object.values(form).some((field) => field.error !== "");
  };

  let items = [
    {
      value: "g",
      name: translate("G"),
    },
    {
      value: "glass",
      name: translate("GLASS").toLowerCase(),
    },
    {
      value: "handfull",
      name: translate("HANDFULL"),
    },
    {
      value: "ml",
      name: translate("ML"),
    },
    {
      value: "oneof",
      name: translate("ONEOF"),
    },
    {
      value: "pinch",
      name: translate("PINCH"),
    },
    {
      value: "tablespoon",
      name: translate("TABLESPOON"),
    },
    {
      value: "teaspoon",
      name: translate("TEASPOON"),
    },
  ];

  let defaultMeasurementAmounts = new Map([
    ["g", 100],
    ["glass", 1],
    ["handfull", 1],
    ["ml", 100],
    ["oneof", 1],
    ["pinch", 1],
    ["tablespoon", 1],
    ["teaspoon", 1],
  ]);

  const isFormButtonDisabled = () => {
    disabled = Object.values(form).some((field) => field.error !== "");
  };

  const onMealPlanTypes = (event: CustomEvent<any>): void => {
    form.foodType.value = event.detail;
  };

  const onAutoCalories = (): void => {
    const carbs = form.carbs.value || 0;
    const protein = form.protein.value || 0;
    const fats = form.fats.value || 0;

    form.calories.value = carbs * 4 + protein * 4 + fats * 9;

    if (form.calories.value < 0) {
      form.calories.error = "FIELD_MINIMUM_AMOUNT_0";
    } else if (form.calories.value > 17000) {
      form.calories.error = "FIELD_MAXIMUM_AMOUNT_17000";
    } else {
      form.calories.error = "";
    }
  };

  const onMeasurementUnitChange = (): void => {
    form.amountOnUnit.value = defaultMeasurementAmounts.get(
      form.measurementUnit.value
    );

    if (form.amountOnUnit.value < 1) {
      form.amountOnUnit.error = "Najmanje 1";
    } else if (form.amountOnUnit.value > 1000) {
      form.amountOnUnit.error = "Najvise 1000";
    } else {
      form.amountOnUnit.error = "";
    }

    isFormButtonDisabled();
  };

  const onSubmit = async (): Promise<void> => {
    let response;
    const { recipeId } = data;
    const ingredient: any = {
      name: form.name.value,
      amount: form.amountOnUnit.value || 0,
      carbs: form.carbs.value || 0,
      protein: form.protein.value || 0,
      fats: form.fats.value || 0,
      calories: form.calories.value || 0,
      foodType: form.foodType.value,
      description: form.description.value,
      measurementUnit: form.measurementUnit.value.toUpperCase(),
      isDefault: 0,
      recipeId: null,
    };

    if (data.ingredient) {
      // Edit ingredient
      ingredient.id = data.ingredient.id;
      ingredient.isDefault = data.ingredient.isDefault;
      ingredient.recipeId = data.ingredient.recipeId;

      response = await patchWithJwt(serverlessRoutes.INGREDIENT, {
        ingredient,
      });
    } else if (recipeId) {
      // Add ingredient to recipe
      ingredient.recipeId = recipeId;
      response = await postWithJwt(serverlessRoutes.INGREDIENT, { ingredient });
    } else {
      // Add ingredient
      ingredient.isDefault = 1;
      response = await postWithJwt(serverlessRoutes.INGREDIENT, { ingredient });
    }

    if (response) {
      if (data.ingredient) {
        // Edit ingredient

        if (data.mealId) {
          const meal = $loadedMealsStore.find(
            (meal: any): boolean => meal.id === data.mealId
          );

          if (recipeId) {
            const mealRecipe = meal.recipes.find(
              (recipe: any): boolean => recipe.id === recipeId
            );
            const index = mealRecipe.ingredients.indexOf(ingredient);

            mealRecipe.ingredients = mealRecipe.ingredients.with(
              index,
              response.data.ingredient
            );
            $loadedMealsStore = $loadedMealsStore;
          } else {
            // direktno u meal
            const mealIngredient = meal.ingredients.find(
              (ingredient: any): boolean =>
                ingredient.id === response.data.ingredient.id
            );
            const index = meal.ingredients.indexOf(mealIngredient);

            meal.ingredients = meal.ingredients.with(
              index,
              response.data.ingredient
            );
            $loadedMealsStore = $loadedMealsStore;
          }
        } else if (recipeId) {
          const recipe = $trainerRecipes.find(
            (recipe) => recipe.id === recipeId
          );

          if (recipe) {
            // Edit ingredient in default recipe
            const ingredient = recipe.ingredients.find(
              (ingredient) => ingredient.id === data.ingredient.id
            );
            const index = recipe.ingredients.indexOf(ingredient);

            recipe.ingredients = recipe.ingredients.with(
              index,
              response.data.ingredient
            );
            calculateRecipeMacros(recipe);
            $trainerRecipes = $trainerRecipes;
          } else {
            // Edit ingredient in recipe in meal
            $loadedMealsStore.forEach((meal: any): void => {
              if (!meal.recipes) {
                return;
              }

              const recipe = meal.recipes.find(
                (recipe: any): boolean => recipe.id === recipeId
              );

              if (!recipe) {
                return;
              }

              const index = recipe.ingredients.findIndex(
                (ingredient: any): boolean =>
                  ingredient.id === data.ingredient.id
              );

              if (!index) {
                return;
              }

              recipe.ingredients = recipe.ingredients.with(
                index,
                response.data.ingredient
              );
            });

            $loadedMealsStore = $loadedMealsStore;
          }
        } else {
          // console.log(response);
          trainerIngredientsStore.replace(
            data.ingredient.id,
            response.data.ingredient
          );
        }
      } else if (recipeId) {
        // Add ingredient to recipe

        if (data.mealId) {
          // Add ingredient to recipe inside a meal
          const meal = $loadedMealsStore.find(
            (meal: any): boolean => meal.id === data.mealId
          );

          if (meal.recipes) {
            const mealRecipe = meal.recipes.find(
              (recipe: any): boolean => recipe.id === recipeId
            );

            mealRecipe.ingredients = [
              ...mealRecipe.ingredients,
              response.data.ingredient,
            ];
            $loadedMealsStore = $loadedMealsStore;
          }
        } else {
          // Add ingredient to default recipe
          const recipe = $trainerRecipes.find(({ id }) => id === recipeId);
          recipe.ingredients = [
            ...recipe.ingredients,
            response.data.ingredient,
          ];
          calculateRecipeMacros(recipe);
          $trainerRecipes = $trainerRecipes;
        }
      } else {
        // Add ingredient
        trainerIngredientsStore.add([response.data.ingredient], true);
      }

      calculateMacros();

      $showAlert.message = `
        ${data.ingredient ? translate("SUCCESSFULLY_EDITED") : translate("SUCCESSFULLY_CREATED")}
        ${translate("INGREDIENT").toLowerCase()}
      `;

      $dialogData.type = "";
      $dialogData.data = {};
    }
  };

  onMount((): void => {
    if (data.ingredient) {
      form.name.value = data.ingredient.name;
      form.foodType.value = data.ingredient.foodType;
      form.measurementUnit.value =
        data.ingredient.measurementUnit.toLowerCase();
      form.amountOnUnit.value = data.ingredient.amount;
      form.carbs.value = data.ingredient.carbs;
      form.protein.value = data.ingredient.protein;
      form.fats.value = data.ingredient.fats;
      form.calories.value = data.ingredient.calories;
      form.description.value = data.ingredient.description;
    } else {
      onMeasurementUnitChange();
    }
  });

  export let data: any;
</script>

<div class="p-4 flex flex-col gap-4">
  <div class="text-xxl text-center font-semibold">
    {data.ingredient ? translate("EDIT") : translate("CREATE")}
    {translate("INGREDIENT_U").toLowerCase()}
  </div>

  <!-- <form class="flex flex-col gap-4" on:submit|preventDefault="{onSubmit}"> -->
  <InputField
    label={translate("NAME")}
    error={translate(form.name.error)}
    bind:value={form.name.value}
    on:input={() => validateField("name")}
  />

  <div>
    <div class="text-xs">{translate("INGREDIENT_TYPE")}:</div>
    <SetEditType
      selected={data.ingredient ? data.ingredient.foodType : []}
      on:mealPlanTypes={onMealPlanTypes}
    />
  </div>

  <div class="flex gap-4">
    <div class="basis-2/5">
      <SelectField
        label={translate("MEASUREMENT_UNIT")}
        {items}
        bind:value={form.measurementUnit.value}
        on:change={onMeasurementUnitChange}
      />
    </div>

    <div class="basis-3/5">
      <InputField
        type="number"
        label={`${translate("AMOUNT_PER_UNIT")} / ${form.measurementUnit.value ? translate(form.measurementUnit.value.toUpperCase()).toLowerCase() : ""}`}
        error={translate(form.amountOnUnit.error)}
        bind:value={form.amountOnUnit.value}
        on:input={() => validateField("amountOnUnit")}
      />
    </div>
  </div>

  <div class="flex gap-4">
    <InputField
      type="number"
      label={translate("CARBS")}
      error={translate(form.carbs.error)}
      bind:value={form.carbs.value}
      on:input={() => validateField("carbs")}
    />

    <InputField
      type="number"
      label={translate("PROTEIN")}
      error={translate(form.protein.error)}
      bind:value={form.protein.value}
      on:input={() => validateField("protein")}
    />

    <InputField
      type="number"
      label={translate("FATS")}
      error={translate(form.fats.error)}
      bind:value={form.fats.value}
      on:input={() => validateField("fats")}
    />
  </div>

  <ToggleField
    label={translate("CALCULATE_CALORIES_AUTOMATICALLY")}
    bind:checked={form.calculateCaloriesAutomatically.value}
    on:change={() => {
      onAutoCalories();
      validateField("calories");
    }}
  />

  <InputField
    type="number"
    label={translate("CALORIES")}
    error={translate(form.calories.error)}
    disabled={form.calculateCaloriesAutomatically.value}
    bind:value={form.calories.value}
    on:input={() => validateField("calories")}
  />

  <TextareaField
    label={translate("DESCRIPTION")}
    error={translate(form.description.error)}
    bind:value={form.description.value}
    on:input={() => validateField("description")}
  />

  <div class="text-xxs text-justify">
    <span class="text-primary-500">{translate("NOTE")}:</span>
    {translate("CREATE_EDIT_INGREDIENT_NOTE")}
  </div>

  <div class="flex justify-center">
    <ButtonComponent {disabled} on:click={onSubmit}>
      {data.ingredient ? translate("EDIT") : translate("CREATE")}
    </ButtonComponent>
  </div>
  <!-- </form> -->
</div>
